import React from 'react'
import styled from 'styled-components'
import {NavLink as RouterNavLink} from 'react-router-dom'

import {formatMessage} from '../../i18n'

const StyledNav = styled.nav`
  padding: 0;
  position: sticky;
  background-color: #f3f5f6;
  top: 80px;
  z-index: 2;
  padding-top: 8px;
`

const Wrap = styled.div`
  display: flex;
  max-width: 868px;
  min-width: 768px;
  width: 100%;
  margin: 0 auto;
`

export const activeClassName = 'active'

const NavLink = styled(RouterNavLink)`
  color: #808287;
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  height: 34px;
  padding-top: 8px;
  flex-grow: 1;
  text-align: center;
  transition: color 0.25s;
  position: relative;

  &::after {
    background-color: #45dbcc;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    opacity: 0;
    transition: opacity 0.25s;
  }

  &:hover {
    color: #45dbcc;
  }

  &.${activeClassName} {
    color: #45dbcc;

    &::after {
      opacity: 1;
    }
  }
`

const Nav = ({account}) => {
  const to = path => `/accounts/${account.id}/${path}`
  return (
    <StyledNav>
      <Wrap>
        <NavLink data-cy="nav-transactions" to={to('transactions')}>
          {formatMessage('transactions')}
        </NavLink>
        <NavLink data-cy="nav-management" to={to('management')}>
          {formatMessage('accountManagement')}
        </NavLink>
        <NavLink data-cy="nav-cards" to={to('cards')}>
          {formatMessage('cardsManagement')}
        </NavLink>
        {account.isMine && (
          <NavLink data-cy="nav-guests" to={to('guests')}>
            {formatMessage('guestsManagement')}
          </NavLink>
        )}
        <NavLink data-cy="nav-bank-statements" to={to('bank-statements')}>
          {formatMessage('bankStatements')}
        </NavLink>
      </Wrap>
    </StyledNav>
  )
}

export default Nav

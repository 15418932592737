import {hoistStatics} from 'recompose'
import {connect} from 'react-redux'

import * as requestMoneyForm from '../../actions/cards/requestMoneyForm'
import {userSelector} from '../../selectors'

const container = connect(
  state => ({
    ...requestMoneyForm.selector(state),
    user: userSelector(state)
  }),
  requestMoneyForm.actions
)

export default hoistStatics(container)

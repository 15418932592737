import {useState, useEffect} from 'react'
import {compact} from 'lodash'
import {useAppDispatch, useAppSelector} from '../../reduxHooks'

import {emailSelector} from '../../selectors'
import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import * as validations from '../../utils/validations'
import {usePrevious} from '../../utils/hooks'

export const useContactUsForm = () => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [email, setEmail] = useState('')
  const [text, setText] = useState('')

  const [success, setSuccess] = useState(false)

  // prefill email
  const userEmail = useAppSelector(state => emailSelector(state))
  const prevUserEmail = usePrevious(userEmail)
  useEffect(() => {
    if (prevUserEmail !== userEmail) {
      setEmail(userEmail)
    }
  }, [prevUserEmail, userEmail])

  const wrapFieldSetter = <T>(fieldSetter: (val: T) => void) => (value: T) => {
    setError(null)
    setSuccess(false)
    fieldSetter(value)
  }

  const resetForm = () => {
    setLoading(false)
    setError(null)
    setEmail(userEmail)
    setText('')
  }

  const submit = async () => {
    if (loading) return

    const validationErrors = compact([
      email ? validations.email(email) : null,
      !text && 'ERR_TEXT_IS_REQUIRED'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    try {
      await apiRequest('/client/contact-us', {
        method: 'POST',
        body: {email, text}
      })
      resetForm()
      // wait a little before setting "success" to "true", because "resetForm" calls "setText('')"
      // and this triggers "onChangeText" on IOS which sets "success" to "false" due to "wrapFieldSetter"
      await new Promise(r => setTimeout(r, 100))
      setSuccess(true)
    } catch (err) {
      setLoading(false)
      dispatch(handleApiError(err))
    }
  }

  return {
    loading,
    error,
    fields: {email, text},
    fieldSetters: {
      email: wrapFieldSetter(setEmail),
      text: wrapFieldSetter(setText)
    },
    submit,
    success,
    resetForm
  }
}

interface ReissueCardFormState {
  fields: {
    reason: string
  }
  isModalOpen: boolean
  success: boolean
  loading: boolean
  error: any // TODO: fix type
}

export const getInitialReissueCardForm = (): ReissueCardFormState => ({
  fields: {
    reason: 'none'
  },
  isModalOpen: false,
  success: false,
  loading: false,
  error: null
})

interface ReprintPinFormState {
  isModalOpen: boolean
  success: boolean
  loading: boolean
  error: any // TODO: fix type
}

export const getInitialReprintPinForm = (): ReprintPinFormState => ({
  isModalOpen: false,
  success: false,
  loading: false,
  error: null
})

interface RequestMoneyFromState {
  fields: {
    amount: string
    code: string
  }
  openedModalType: string
  codeResendRequest: any // TODO: fix type
  loading: boolean
  error: any // TODO: fix type
}

export const getInitialRequestMoneyForm = (): RequestMoneyFromState => ({
  fields: {
    amount: '',
    code: ''
  },
  openedModalType: '',
  codeResendRequest: null,
  loading: false,
  error: null
})

interface ContactUsFormState {
  fields: {
    email: string
    text: string
  }
  loading: boolean
  error: any // TODO: fix type
  showModal: boolean
}

export const getInitialContactUsFormState = (): ContactUsFormState => ({
  fields: {
    email: '',
    text: ''
  },
  loading: false,
  error: null,
  showModal: false
})

interface EditContactDetailFormState {
  fields: {
    contactValue: string
    codeForCurrent: string
    codeForNew: string
  }
  data: {
    validatedContactValue: string
  }
  loading: boolean
  error: any // TODO: fix type
  editingContactType: any // TODO: fix type
  editingFlowStep: any // TODO: fix type
  codeForCurrentResend: any // TODO: fix type
  codeForNewResend: any // TODO: fix type
}

export const getInitialEditContactDetailFormState = (): EditContactDetailFormState => ({
  fields: {
    contactValue: '',
    codeForCurrent: '',
    codeForNew: ''
  },
  data: {
    validatedContactValue: ''
  },
  loading: false,
  error: null,
  editingContactType: null,
  editingFlowStep: null,
  codeForCurrentResend: null,
  codeForNewResend: null
})

interface Saving {
  accountId: string
  name: string
  avatarIndex: string
  avatarContent: any // TODO: fix type
  currentAmount: number
  targetAmount: number
}

export interface RootState {
  auth: {
    loginStatus?: any // TODO: fix type
  }
  pinLock: {
    status: 'locked'
  }
  user: any // TODO: fix type
  clients: Record<string, any> // TODO: fix type
  accounts: Record<string, any> // TODO: fix type
  transactionsByAccount: Record<string, any> // TODO: fix type
  cards: Record<string, any> // TODO: fix type
  invites: Record<string, any> // TODO: fix type
  savings: Record<string, Saving>
  tasks: {current: Record<string, any>; past: any[]} // TODO: fix type
  forms: {
    reissueCardForm: ReissueCardFormState
    reprintPinForm: ReprintPinFormState
    requestMoneyForm: RequestMoneyFromState
    contactUsForm: ContactUsFormState
    editContactDetailForm: EditContactDetailFormState
  }
  isParent?: any // TODO: fix type
  visibleGeneralError: null | any // TODO: fix type
  unsupportedAppVersionErrorVisible: boolean
  userDataLoading: boolean
  resetWholeApp: boolean
  savingTransactions: Record<string, any> // TODO: fix type
  animation?: any
  features?: any
  invitationFeatures?: any
  featureColorSchemes?: any
  quizes?: any
}

const getInitialState = (): RootState => ({
  auth: {
    loginStatus: undefined
  },
  pinLock: {
    status: 'locked'
  },
  user: undefined,
  clients: {},
  accounts: {},
  transactionsByAccount: {},
  cards: {},
  invites: {},
  savings: {},
  tasks: {current: {}, past: []},
  forms: {
    reissueCardForm: getInitialReissueCardForm(),
    reprintPinForm: getInitialReprintPinForm(),
    requestMoneyForm: getInitialRequestMoneyForm(),
    contactUsForm: getInitialContactUsFormState(),
    editContactDetailForm: getInitialEditContactDetailFormState()
  },
  isParent: undefined,
  visibleGeneralError: null,
  unsupportedAppVersionErrorVisible: false,
  userDataLoading: false,
  resetWholeApp: false,
  savingTransactions: {}
})

export default getInitialState

import {compact} from 'lodash'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {SET_VALUE, SET_FORM_FIELD_VALUE} from '../commonActions'
import {getInitialRequestMoneyForm} from '../../state'
import {userSelector} from '../../selectors'
import {getNumber} from '../../utils/utils'

const PATH = ['forms', 'requestMoneyForm']

export const selector = state => state.forms.requestMoneyForm

const setField = (fieldName, fieldValue) =>
  SET_FORM_FIELD_VALUE(PATH, fieldName, fieldValue)

const openModal = modalType => dispatch => {
  dispatch(SET_VALUE([...PATH, 'error'], null))
  dispatch(SET_VALUE([...PATH, 'loading'], false))
  dispatch(SET_VALUE([...PATH, 'openedModalType'], modalType))
}

const openRequestMoneyModal = () => (dispatch, getState) => {
  dispatch(setField('iban', ''))
  dispatch(openModal('EnterIban'))
}

const closeModal = () => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialRequestMoneyForm()))
}

const submitIbanAndSendCode = accountId => async (dispatch, getState) => {
  const user = userSelector(getState())
  const {
    fields: {amount},
    openedModalType,
    loading
  } = selector(getState())

  if (!user || openedModalType !== 'EnterIban' || loading) return

  const amountNumber = getNumber(amount)

  const validationErrors = compact([
    !amountNumber.isValid && 'ERR_INVALID_DATA',
    amountNumber.isEmpty && 'ERR_REQUIRED_IS_MISSING',
    amountNumber.isValid && amountNumber.number <= 0 && 'ERR_INVALID_DATA'
  ])
  if (validationErrors.length) {
    dispatch(SET_VALUE([...PATH, 'error'], validationErrors[0]))
    return
  }

  dispatch(SET_VALUE([...PATH, 'loading'], true))

  try {
    await apiRequest('/verification-codes/send-to-user', {
      method: 'POST',
      body: {contactType: user.contactType, reason: 'REQUEST_MONEY'}
    })
    dispatch(SET_FORM_FIELD_VALUE(PATH, 'code', ''))
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    dispatch(openModal('EnterCode'))
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_INVALID_IBAN_FORMAT', 'ERR_RATELIMIT_EXCEEDED'],
        errorCode => dispatch(SET_VALUE([...PATH, 'error'], errorCode))
      ])
    )
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

const resendCode = () => async (dispatch, getState) => {
  const user = userSelector(getState())

  if (!user) return

  try {
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    await apiRequest('/verification-codes/send-to-user', {
      method: 'POST',
      body: {contactType: user.contactType, reason: 'REQUEST_MONEY'}
    })
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], {success: true}))
    dispatch(SET_VALUE([...PATH, 'error'], null))
  } catch (error) {
    await dispatch(
      handleApiError(error, [
        ['ERR_RATELIMIT_EXCEEDED'],
        errorCode => {
          dispatch(
            SET_VALUE([...PATH, 'codeResendRequest'], {
              success: false,
              error: errorCode
            })
          )
        }
      ])
    )
  }
}

const submitCodeAndRequestMoney = accountId => async (dispatch, getState) => {
  const {
    fields: {amount, code},
    openedModalType,
    loading
  } = selector(getState())

  if (openedModalType !== 'EnterCode' || loading) return

  dispatch(SET_VALUE([...PATH, 'loading'], true))

  const amountNumber = getNumber(amount)

  try {
    await apiRequest(`/accounts/${accountId}/request-money`, {
      method: 'POST',
      body: {amount: amountNumber.number, code}
    })
    dispatch(openModal('RequestSent'))
  } catch (error) {
    dispatch(SET_VALUE([...PATH, 'codeResendRequest'], null))
    await dispatch(
      handleApiError(error, [
        ['ERR_EXPIRED', 'ERR_INVALID_DATA', 'ERR_CODE_ENTER_LIMIT_EXCEEDED'],
        errorCode => dispatch(SET_VALUE([...PATH, 'error'], errorCode))
      ])
    )
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

export const actions = {
  setField,
  openRequestMoneyModal,
  closeModal,
  submitIbanAndSendCode,
  resendCode,
  submitCodeAndRequestMoney
}

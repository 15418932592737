import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import ReportTransactionBlock from './ReportTransactionBlock'
import {Link, Text} from '../../ui'
import {formatMessage} from '../../../i18n'
import {isParentSelector} from '../../../selectors'

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0 32px 0 40px;

  & + & {
    border-top: 1px solid #e3e5e6;
  }
`

const FullLine = Line.extend`
  padding: 16px 32px 16px 40px;
  margin: 0;
  border-top: 1px solid #e3e5e6;
`

const TransactionDetail = props => {
  const {transaction, isOpen} = props
  const isNegative = transaction.type === 'DEBIT'
  const isParent = useSelector(isParentSelector)
  return (
    <div>
      {/* Show seller for unopened transaction only if showSellerLabel is true*/}
      {transaction.seller && (isOpen || transaction.showSellerLabel) && (
        <Line>
          <Text type="secondary">
            {transaction.showSellerLabel ? formatMessage('seller') : ' '}
          </Text>
          <Text data-cy="transaction-seller" small>
            {transaction.seller}
          </Text>
        </Line>
      )}
      {isOpen && (
        <>
          {transaction.detail && (
            <Line>
              <Text type="secondary">{formatMessage('detail')}</Text>
              <Text data-cy="transaction-detail" small>
                {transaction.detail}
              </Text>
            </Line>
          )}
          {transaction.originalAmount != null && (
            <>
              <Line>
                <Text type="secondary">{formatMessage('originalAmount')}</Text>
                <Text
                  data-cy="transaction-original-amount"
                  small
                  color={isNegative ? 'red' : 'green'}
                >
                  {(transaction.originalAmount * (isNegative ? -1 : 1)).toFixed(
                    2
                  )}{' '}
                  {transaction.originalCurrency}
                </Text>
              </Line>
              <Line>
                <Text type="secondary">{formatMessage('conversionRate')}</Text>
                <Text data-cy="transaction-conversion-rate" small>
                  {transaction.conversionRate}
                </Text>
              </Line>
            </>
          )}
          {isParent && transaction.type !== 'SAVING' && (
            <FullLine>
              <ReportTransactionBlock
                transaction={transaction}
                render={open => (
                  <Link
                    data-cy="open-report-transaction-modal"
                    color="red"
                    onClick={e => {
                      e.stopPropagation()
                      open()
                    }}
                  >
                    {formatMessage('reportTransaction')}
                  </Link>
                )}
              />
            </FullLine>
          )}
        </>
      )}
    </div>
  )
}

// withRouter is needed to get route params
export default TransactionDetail

// NOTE used only for REACT_APP_API_URL
// everything else comes from server and saves into window.config

// native equivalent of env vars is stored in Expo.Constants.Manifest
// this provides a single endpoint for both web and native

let env

export const initEnvWeb = () => {
  // force flow type despite not knowing anything about env
  env = process.env
  // eslint-disable-next-line no-restricted-globals
  if (!env.REACT_APP_API_URL) env.REACT_APP_API_URL = window.location.origin
}

export const initEnvNative = importedEnv => {
  env = importedEnv
}

export const getEnv = () => env

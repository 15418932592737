import * as Sentry from '@sentry/browser'

import {apiRequest, setApiRequest} from '../apiRequest'
import getInitialState from '../../state'
import {SET_VALUE} from '../commonActions'
import storage from '../../utils/storage'

// This method is used on Device token expiration/invalidation
// or any connected request with access token related to this device token
export const resetAppStateAfterLogout = () => async dispatch => {
  await storage.clearStorage()

  setApiRequest() // closure access token
  Sentry.configureScope(scope => {
    scope.setUser({
      id: null,
      username: null
    })
  })
  // reset redux state
  dispatch(SET_VALUE(null, getInitialState()))
}

// This method is used after PIN code expiration
export const resetAppAfterPinExpired = () => dispatch => {
  setApiRequest() // closure access token
  dispatch(SET_VALUE(null, getInitialState())) // reset redux state
}

export const logout = () => async dispatch => {
  try {
    await apiRequest('/auth/logout', {method: 'POST', body: {}})
    await storage.removeItem('pin-created')
    setApiRequest() // closure access token
  } catch (e) {
    // nothing
  }
  await dispatch(resetAppStateAfterLogout())
}

import {compose, hoistStatics, withProps} from 'recompose'
import {connect} from 'react-redux'
import * as cardManagementActions from '../../actions/cards/cardManagementActions'
import handleNotFound from '../../handleNotFound'
import cardContainer from './cardContainer'
import {formatMessage} from '../../i18n'

const _cardManagementContainer = compose(
  cardContainer,
  handleNotFound(props => props.card.openable === false)
)
export const cardManagementContainer = hoistStatics(_cardManagementContainer)

export const cardManagementControlContainer = compose(
  withProps(props => ({
    loading: props.card.settingsLoading === props.fieldName,
    error:
      (props.card.settingsError &&
        props.card.settingsError.fieldName === props.fieldName &&
        formatMessage(
          props.card.settingsError.errorText,
          props.card.settingsError.errorData
        )) ||
      null
  })),
  connect(null, cardManagementActions.actions)
)

import {connect} from 'react-redux'
import {compose, hoistStatics, withProps} from 'recompose'
import {cardSelector, accountSelector} from '../../selectors'
import {withParamsProp} from '../utils'
import handleNotFound from '../../handleNotFound'

const container = compose(
  withParamsProp(),
  withProps(props => {
    // if cardId is not in params from router or navigation
    if (props.route && props.route.params.cardId)
      return {params: {...props, cardId: props.route.params.cardId}}
    if (props.params && props.params.cardId)
      return {params: {...props, cardId: props.params.cardId}}
    if (props.card) return {params: {...props, cardId: props.card.id}}
    return {params: {...props}}
  }),
  connect((state, props) => ({
    card: cardSelector(state, props.params.cardId)
  })),
  handleNotFound(({card}) => !card),
  connect((state, {card}) => ({
    account: accountSelector(state, card.accountId)
  })),
  handleNotFound(({account}) => !account)
)

export default hoistStatics(container)

import React, {Fragment} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import {InputField, Modal, Button, Alert, EnterCodeModalOld} from '../ui'
import requestMoneyContainer from '../../containers/cards/requestMoneyContainer'

const Action = styled.div`
  margin-top: 24px;
`

const EnterIbanModal = props => (
  <Modal
    isOpen={props.openedModalType === 'EnterIban'}
    onRequestClose={props.closeModal}
    title={formatMessage('requestMoneyModalTitle')}
    data-cy="enter-iban-modal"
  >
    <form
      onSubmit={e => {
        e.preventDefault()
        props.submitIbanAndSendCode(props.account.id)
      }}
    >
      <InputField
        required
        data-cy="amount"
        label={formatMessage('amount')}
        value={props.fields.amount}
        onChangeText={text => props.setField('amount', text)}
      />
      {props.error && (
        <Alert data-cy={props.error} type="error">
          {formatMessage(props.error)}
        </Alert>
      )}
      <Action>
        <Button block data-cy="submit-request-money" disabled={props.loading}>
          {formatMessage('submitRequestMoney')}
        </Button>
      </Action>
    </form>
  </Modal>
)

const RequestSentModal = props => (
  <Modal
    isOpen={props.openedModalType === 'RequestSent'}
    onRequestClose={props.closeModal}
    title={formatMessage('requestSentModalTitle')}
    data-cy="request-sent-modal"
  >
    <Alert type="success">{formatMessage('requestAccepted')}</Alert>
    <Button onClick={props.closeModal} block data-cy="request-sent-dismiss">
      {formatMessage('close')}
    </Button>
  </Modal>
)

const RequestMoney = props => (
  <Fragment>
    <props.Link openModal={props.openRequestMoneyModal} />
    <EnterIbanModal {...props} />
    <EnterCodeModalOld
      title={formatMessage('requestMoneyConfirmation')}
      contactType={props.user.contactType}
      isOpen={props.openedModalType === 'EnterCode'}
      submit={() => props.submitCodeAndRequestMoney(props.account.id)}
      {...props}
    />
    <RequestSentModal {...props} />
  </Fragment>
)

export default requestMoneyContainer(RequestMoney)

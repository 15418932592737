import {useEffect, useState, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {uniqueId, get, orderBy, isEqual} from 'lodash'
import * as d from 'date-fns'

import {apiRequest} from '../../apiRequest'
import handleApiError from '../../handleApiError'
import {
  accountSelector,
  accountTransactionsDataSelector
} from '../../../selectors'
import {usePrevious} from '../../../utils/hooks'
import {pullUserData} from '../../pullData'
import {getNumber} from '../../../utils/utils'

export const getDefaultFilterFields = () => ({
  credit: true,
  debit: true,
  saving: true,
  amountFrom: undefined,
  amountTo: undefined,
  dateFrom: undefined,
  dateTo: undefined
})

export const pullTransactions = (
  accountId,
  filterFields = getDefaultFilterFields(),
  alsoRefreshUser = false,
  allowSavingTransactions = false
) => async (dispatch, getState) => {
  const PATH = ['transactionsByAccount', accountId]
  const getTransactionsData = () => get(getState(), PATH)
  const setTransactionsData = value =>
    dispatch({type: 'SET_VALUE', path: PATH, value})

  const requestId = uniqueId()

  setTransactionsData(_draft => {
    const draft = _draft || {}
    draft.filterFields = filterFields
    draft.request = {
      requestId,
      isDefaultFilter: isEqual(filterFields, getDefaultFilterFields()),
      loading: true
    }
    draft.result = undefined
    if (draft !== _draft) return draft
    else return undefined
  })

  try {
    // HACK:
    // https://stackoverflow.com/a/11964609
    // This seems to work but the actual root of the problem is probably
    // somewhere else
    const parseDate = date =>
      date &&
      new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()

    const transactionsPromise = await apiRequest(
      `/accounts/${accountId}/transactions`,
      {
        queryParams: {
          dateFrom: parseDate(filterFields.dateFrom),
          dateTo: parseDate(filterFields.dateTo),
          amountFrom: filterFields.amountFrom,
          amountTo: filterFields.amountTo
        }
      }
    )
    const [{transactions, endReached}] = await Promise.all([
      transactionsPromise,
      alsoRefreshUser && dispatch(pullUserData())
    ])

    if (requestId === getTransactionsData().request.requestId) {
      const transactionList = orderBy(transactions, ['date'], ['desc']).filter(
        transaction => {
          if (transaction.type === 'CREDIT' && !filterFields.credit)
            return false
          if (transaction.type === 'DEBIT' && !filterFields.debit) return false
          if (
            transaction.type === 'SAVING' &&
            (!allowSavingTransactions || !filterFields.saving)
          )
            return false
          return true
        }
      )

      setTransactionsData(draft => {
        draft.request.loading = false
        draft.request.success = true
        draft.result = {
          endReached,
          filteredList: transactionList
        }
      })
    }
  } catch (err) {
    if (requestId === getTransactionsData().request.requestId) {
      await dispatch(
        handleApiError(err, [
          ['GENERAL_ERROR', 'NETWORK_ERROR'],
          () =>
            setTransactionsData(draft => {
              draft.request.loading = false
              draft.request.error = 'errorLoadingTransactions'
            })
        ])
      )
    }
  }
}

export const useTransactionFilter = props => {
  const [credit, setCredit] = useState(true)
  const [debit, setDebit] = useState(true)
  const [saving, setSaving] = useState(true)
  const [amountFromString, setAmountFromString] = useState('')
  const [amountToString, setAmountToString] = useState('')
  const [dateFrom, setDateFrom] = useState()
  const [dateTo, setDateTo] = useState()

  const setAllFields = filterFields => {
    setCredit(filterFields.credit)
    setDebit(filterFields.debit)
    setSaving(filterFields.saving)
    setAmountFromString(
      filterFields.amountFrom ? filterFields.amountFrom.toString() : ''
    )
    setAmountToString(
      filterFields.amountTo ? filterFields.amountTo.toString() : ''
    )
    setDateFrom(filterFields.dateFrom)
    setDateTo(filterFields.dateTo)
  }

  const prevIsOpen = usePrevious(props.isOpen)
  useEffect(() => {
    if (!prevIsOpen && props.isOpen) setAllFields(props.filterFields)
  }, [prevIsOpen, props.filterFields, props.isOpen])

  const fieldSetters = useMemo(
    () => ({
      credit: setCredit,
      debit: setDebit,
      saving: setSaving,
      amountFromString: val =>
        getNumber(val).isValid && setAmountFromString(val),
      amountToString: val => getNumber(val).isValid && setAmountToString(val),
      dateFrom: val => setDateFrom(val ? d.startOfDay(val) : undefined),
      dateTo: val => setDateTo(val ? d.endOfDay(val) : undefined)
    }),
    []
  )

  const resetFilter = () => setAllFields(getDefaultFilterFields())

  const submit = async () => {
    const amountFromNumber = getNumber(amountFromString)
    const amountToNumber = getNumber(amountToString)
    const newFilterFields = {
      credit,
      debit,
      saving,
      amountFrom: amountFromNumber.isEmpty
        ? undefined
        : amountFromNumber.number,
      amountTo: amountToNumber.isEmpty ? undefined : amountToNumber.number,
      dateFrom,
      dateTo
    }

    await props.submitFilter(newFilterFields)
  }

  return {
    fields: {
      credit,
      debit,
      saving,
      amountFromString,
      amountToString,
      dateFrom,
      dateTo
    },
    fieldSetters,
    resetFilter,
    submit
  }
}

export const useTransactions = (accountId, allowSavingTransactions = false) => {
  const dispatch = useDispatch()
  const account = useSelector(state => accountSelector(state, accountId))

  const transactionsData = useSelector(state =>
    accountTransactionsDataSelector(state, accountId)
  )

  const transactionsDataNotFound = !transactionsData
  useEffect(() => {
    if (account && transactionsDataNotFound) {
      dispatch(
        pullTransactions(
          accountId,
          undefined,
          undefined,
          allowSavingTransactions
        )
      )
    }
  }, [
    account,
    accountId,
    allowSavingTransactions,
    dispatch,
    transactionsDataNotFound
  ])

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const openFilter = () => setIsFilterOpen(true)

  const transactionFilterProps = useMemo(
    () => ({
      isOpen: isFilterOpen,
      close: () => setIsFilterOpen(false),
      filterFields: transactionsData && transactionsData.filterFields,
      submitFilter: filterFields => {
        account &&
          dispatch(
            pullTransactions(
              accountId,
              filterFields,
              true,
              allowSavingTransactions
            )
          )
        setIsFilterOpen(false)
      }
    }),
    [
      account,
      accountId,
      allowSavingTransactions,
      dispatch,
      isFilterOpen,
      transactionsData
    ]
  )

  const refresh = () =>
    dispatch(
      pullTransactions(
        accountId,
        getDefaultFilterFields(),
        true,
        allowSavingTransactions
      )
    )

  return {
    transactionsData: !account ? null : transactionsData,
    transactionFilterProps,
    openFilter,
    refresh
  }
}

import React, {Fragment} from 'react'

import {useForgotPasswordFlow} from '../../../../actions/auth/forgotPasswordActions'
import {formatMessage} from '../../../../i18n'
import {Link, CodeModal} from '../../../ui'
import UsernameModal from './UsernameModal'
import NewPasswordModal from './NewPasswordModal'

const ForgotPasswordBlock = ({testID}) => {
  const {
    open,
    usernameModalProps,
    codeModalProps,
    newPasswordModalProps
  } = useForgotPasswordFlow()
  return (
    <Fragment>
      <Link data-cy={testID} small onClick={open} type="button">
        {formatMessage('forgotPassword')}
      </Link>
      <UsernameModal {...usernameModalProps} />
      <CodeModal
        small
        title={formatMessage('forgotPasswordTitle')}
        {...codeModalProps}
      />
      <NewPasswordModal {...newPasswordModalProps} />
    </Fragment>
  )
}

export default ForgotPasswordBlock

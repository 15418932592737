import React, {useState} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../../i18n'
import {Text, Modal, Paragraph, Link} from '../../ui'

const VSHintModal = ({isOpen, setIsOpen}) => (
  <Modal
    data-cy="vs-hint-modal"
    isOpen={isOpen}
    onRequestClose={() => setIsOpen(false)}
    title={formatMessage('VSHintTitle')}
  >
    <Paragraph>
      <Text>{formatMessage('VSHintDescription')}</Text>
    </Paragraph>
    <Paragraph>
      <Text>
        -{' '}
        {formatMessage('VSHintDescriptionBullet1', {
          userType: 'other'
        })}
        <Link
          href="https://smartie.sk/start/"
          data-cy="request-card-redirect"
          type="button"
          color="gray"
          small
        >
          www.smartie.sk/start
        </Link>
        ,
      </Text>
    </Paragraph>
    <Paragraph>
      <Text>
        -{' '}
        {formatMessage('VSHintDescriptionBullet2', {
          userType: 'other'
        })}
      </Text>
    </Paragraph>
    <Paragraph>
      <Text>{formatMessage('VSHintDescriptionBullet3')}</Text>
    </Paragraph>
  </Modal>
)

const Help = styled.div`
  display: flex;
  height: 96px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 48px;
`

const SpacedText = styled(Text)`
  margin-bottom: 8px;
`

const HelpSection = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Help>
      <SpacedText>
        {formatMessage('signUpUsingDataFromYourContract', {
          activationType: 'other'
        })}
      </SpacedText>
      <Link
        data-cy="open-vs-hint-modal"
        type="button"
        color="gray"
        small
        onClick={() => setIsOpen(true)}
      >
        {formatMessage('whereCanIFindTheData')}
      </Link>
      <VSHintModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </Help>
  )
}

export default HelpSection

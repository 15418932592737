import React from 'react'
import {formatMessage} from '../../i18n'
import {Text, Modal, Paragraph} from '../ui'

const AccountTopupModal = ({variableSymbol, ...restProps}) => (
  <Modal {...restProps} title={formatMessage('topupAccountTitle')}>
    <Paragraph>
      <Text type="secondary">
        {formatMessage('topupAccountDescription', {variableSymbol})}
      </Text>
    </Paragraph>
    <Paragraph>
      <Text>
        {formatMessage('iban')}: <strong>{window.config.IBAN}</strong>
      </Text>
    </Paragraph>
    <Paragraph>
      <Text data-cy="account-topup-vs">
        {formatMessage('variableSymbol')}: <strong>{variableSymbol}</strong>
      </Text>
    </Paragraph>
  </Modal>
)

export default AccountTopupModal

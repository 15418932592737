import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {
  accountClientSelector,
  accountCardsListSelector
} from '../../../selectors'
import CardTab from '../../cards/CardTab'
import {withUserAndCardData} from '../../../withData'
import {Group, LoadingPlaceholder} from '../../ui'
import {formatMessage} from '../../../i18n'

const Layout = styled.div`
  flex: 1;
`

const AccountCardsManagement = ({match}) => {
  const accountId = match.params.accountId || ''
  const accountClient = useSelector(state =>
    accountClientSelector(state, accountId)
  )

  const cards = useSelector(state => accountCardsListSelector(state, accountId))

  if (!accountClient) return null

  return (
    <Layout>
      {cards.length > 0 && (
        <Fragment>
          <Group data-cy="my-cards">
            {cards.map(card => (
              <CardTab
                key={card.id}
                card={card}
                holder={accountClient.name}
                url={`/accounts/${card.accountId}/cards/${card.id}`}
              />
            ))}
          </Group>
        </Fragment>
      )}
    </Layout>
  )
}

export default withUserAndCardData({
  loadingComponent: () => (
    <LoadingPlaceholder title={formatMessage('loadingCards')} />
  )
})(AccountCardsManagement)

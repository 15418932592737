import React from 'react'
import styled from 'styled-components'

import Text from './Text'
import Loader from './Loader'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(47, 47, 47, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .25s;
  opacity: ${props => (props.show ? 1 : 0)}
  pointer-events: ${props => (props.show ? 'all' : 'none')}
  color: white;
`

const StyledText = styled(Text)`
  color: white;
`

class LoadingOverlay extends React.PureComponent {
  static defaultProps = {
    delayTime: 500
  }

  state = {
    show: this.props.isOpen
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      setTimeout(() => this.setState({show: false}), this.props.delayTime)
    } else if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({show: true})
    }
  }

  render() {
    return (
      <Overlay show={this.state.show} delayTime={this.props.delayTime}>
        <div>
          <Loader />
          {this.props.title && <StyledText>{this.props.title}</StyledText>}
        </div>
      </Overlay>
    )
  }
}

export default LoadingOverlay

import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import IBAN from 'iban'
import {MAX_SAVING, NATIVE_APP_PIN_LENGTH} from '../constants'

// NOTE: similar logic is in server/validations.js, PLEASE KEEP IN SYNC
// here on frontend, each function should return null for valid input and error for invalid input

export const email = email => {
  // copied from
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Basic_validation
  // eslint-disable-next-line max-len
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  return regex.test(email) ? null : 'ERR_INVALID_EMAIL_FORMAT'
}

export const phone = phone => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'SK')
  if (parsedPhone && parsedPhone.isValid()) return null
  return 'ERR_INVALID_PHONE_FORMAT'
}

//8+ characters, contains lowercase, uppercase letter and a digit.
export const password = password => {
  if (
    !/^.{8,}$/.test(password) ||
    /.*\s.*/.test(password) ||
    !/.*[a-z].*/.test(password) ||
    !/.*[A-Z].*/.test(password) ||
    !/.*\d.*/.test(password)
  )
    return 'ERR_INVALID_PASSWORD_FORMAT'
  return null
}

export const pinCode = pin => {
  // check min length
  if (pin.length < NATIVE_APP_PIN_LENGTH) return false

  // check if all chars are the same
  if (pin.split(pin[0]).length - 1 === pin.length) return false

  let isAscending = true
  let isDescending = true

  for (let i = 1; i < pin.length; i++) {
    const prevCharCode = pin.charCodeAt(i - 1)
    const currentCharCode = pin.charCodeAt(i)

    // check if is not ascending
    if (prevCharCode + 1 !== currentCharCode) {
      isAscending = false
    }

    // check if is not descending
    if (prevCharCode - 1 !== currentCharCode) {
      isDescending = false
    }

    if (!isAscending && !isDescending) return true
  }

  return false
}

// 1-10 numbers
export const variableSymbol = vs => {
  const regex = /^\d{1,10}$/
  return regex.test(vs) ? null : 'ERR_INVALID_VS_FORMAT'
}

export const iban = iban => {
  return IBAN.isValid(iban) ? null : 'ERR_INVALID_IBAN_FORMAT'
}

export const nationality = nationality => {
  const regex = /^[A-Z]{2}$/
  return regex.test(nationality) ? null : 'ERR_INVALID_NATIONALITY_FORMAT'
}

// savings

export const maxSaving = targetAmount => {
  return parseFloat(targetAmount, 10) > MAX_SAVING
    ? 'ERR_SAVING_OVER_MAX'
    : null
}

export const nameNotEmpty = name => {
  return !name ? 'ERR_NAME_EMPTY' : null
}
export const valueNotNegative = value => {
  return parseFloat(value, 10) < 0 ? 'ERR_VALUE_NOT_NEGATIVE' : null
}

export const valueNotANumber = value => {
  return !value || isNaN(value) ? 'ERR_VALUE_NOT_A_NUMBER' : null
}
export const savingAmountNotEmpty = targetAmount => {
  return !targetAmount ? 'ERR_SAVING_AMOUNT_EMPTY' : null
}
export const savingCannotBeZero = amount => {
  return parseFloat(amount, 10) === 0 ? 'ERR_SAVING_AMOUNT_NOT_ZERO' : null
}

export const savingCannotOverflow = (amount, saving) => {
  return parseFloat(amount, 10) > saving.targetAmount - saving.currentAmount
    ? 'ERR_SAVING_OVERFLOW'
    : null
}

export const savingFundsMoreThanBalance = (amount, saving, balance) => {
  return parseFloat(amount, 10) > balance - saving.currentAmount
    ? 'ERR_SAVING_FUNDS_OVER_BALANCE'
    : null
}

export const currencyNumberCorrectFormat = amount => {
  const regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/
  return regex.test(amount) ? null : 'ERR_CURRENCY_NUMBER_FORMAT'
}

import styled, {keyframes} from 'styled-components'

const loading = keyframes`
 0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export default styled.div`
  &,
  &::after {
    border-radius: 50%;
    width: 12em;
    height: 12em;
  }

  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid
    ${props => (props.onLightBackground ? '#45DBCC' : '#45DBCC')};
  transform: translateZ(0);
  animation: ${loading} 1.1s infinite linear;
`

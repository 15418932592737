import React from 'react'
import {Edit} from 'react-feather'

import {
  useCorrespondenceAddress,
  useEditCorrespondenceAddressFlow
} from '../../actions/profile/correspondenceAddressActions'
import {formatMessage} from '../../i18n'
import {Modal, Alert, GroupItem, Link, CodeModal} from '../ui'

const EditCorrespondenceAddressModal = props => (
  <Modal
    isOpen={props.isOpen}
    onRequestClose={props.close}
    title={formatMessage('editContactInfo')}
    data-cy="edit-correspondence-address-modal"
  >
    <Alert type="info">{formatMessage('editContactInfoDescription')}</Alert>
  </Modal>
)

const EditCorrespondenceAddressBlock = props => {
  const {
    open,
    editAddressModalProps,
    codeModalProps
  } = useEditCorrespondenceAddressFlow()
  return (
    <>
      {props.render(open)}
      <EditCorrespondenceAddressModal {...editAddressModalProps} />
      <CodeModal
        title={formatMessage('editCorrAddressConfirmation')}
        {...codeModalProps}
      />
    </>
  )
}

const CorrespondenceAddress = props => {
  const {notFound, editable, address} = useCorrespondenceAddress(props.client)

  if (notFound) return null
  return (
    <GroupItem
      data-cy="correspondence-address"
      label={props.label || formatMessage('correspondenceAddress')}
      value={address}
      action={
        editable && (
          <EditCorrespondenceAddressBlock
            render={open => (
              <Link onClick={open} data-cy="edit-correspondence-address">
                <Edit color="#5D7083" />
              </Link>
            )}
          />
        )
      }
    />
  )
}

export default CorrespondenceAddress

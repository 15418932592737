import * as React from 'react'
import styled, {css} from 'styled-components'

import Text from './Text'
import Button from './Button'

import {formatMessage} from '../../i18n'

const Layout = styled.div`
  background-color: #fff;
  position: relative;
  width: ${props => (props.isPreview ? 'calc((100% - 32px) / 2)' : '307px')};
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(120, 137, 153, 0.16);
  transition: box-shadow 0.25s;

  ${props =>
    props.isPreview &&
    css`
      &:hover {
        box-shadow: 0 6px 10px rgba(120, 137, 153, 0.24);

        &::before {
          background-image: linear-gradient(#45dbcc, #45dbcc);
        }
      }
    `} &:nth-child(odd) + & {
    margin-left: 32px;
  }

  &:nth-child(n + 3) {
    margin-top: 40px;
  }

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: 4px 4px 0 0;
    background-image: ${props =>
      props.isPreview
        ? 'linear-gradient(#D8E1E6, #D8E1E6)'
        : 'linear-gradient(#45DBCC, #45DBCC)'};
  }
`

const Content = styled.header`
  padding: 1rem;
`

const Toolbar = styled.div`
  padding: 16px;
  padding-top: 0;
`

const Title = styled(Text).attrs({type: 'title'})`
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 500;
`

const AccountCard = ({account, action, isPreview, onClick}) => {
  return (
    <Layout isPreview={isPreview}>
      <Content>
        <Text type="secondary" small={false}>
          {isPreview
            ? account.inviteName || account.clientName
            : formatMessage('availableBalance')}
        </Text>
        <Title data-cy="account-card-balance">
          {account.balance.toFixed(2)} {account.currency}
        </Title>
      </Content>
      {onClick && (
        <Toolbar>
          <Button block visual="ghost" onClick={onClick}>
            {formatMessage('accountDetail')}
          </Button>
        </Toolbar>
      )}
    </Layout>
  )
}

export default AccountCard

import {useState, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {compact, mapValues} from 'lodash'

import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import * as validations from '../../utils/validations'
import {hashAuthData, saveCredentials} from '../../utils/auth'
import storage from '../../utils/storage'

export const useRegistrationForm = ({
  continueFlow,
  initialParams,
  platform = 'mobile'
}) => {
  const dispatch = useDispatch()

  const [flowStep, setFlowStep] = useState(0) // currently used only on web

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [variableSymbol, setVariableSymbol] = useState(
    initialParams.variableSymbol || ''
  )
  const [usernameType, setUsernameType] = useState('email')
  const [username, setUsername] = useState(initialParams.username || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [userId, setUserId] = useState(initialParams.userId || null)
  const [codeModalOpen, setCodeModalOpen] = useState(false)
  const code = initialParams.code

  const fieldSetters = useMemo(() => {
    const wrapper = (fieldSetter, fieldName) => newValue => {
      setError(null)
      if (fieldName === 'usernameType')
        setUsername(initialParams.username || '')
      fieldSetter(newValue)
    }
    return mapValues(
      {
        variableSymbol: setVariableSymbol,
        usernameType: setUsernameType,
        username: setUsername,
        password: setPassword,
        confirmPassword: setConfirmPassword
      },
      wrapper
    )
  }, [initialParams.username])

  const validateVSAndContinue = () => {
    const error = validations.variableSymbol(variableSymbol)
    if (error) setError(error)
    else setFlowStep(flowStep + 1)
  }

  const validateUsernameAndContinue = () => {
    const error =
      usernameType === 'email'
        ? validations.email(username)
        : validations.phone(username)
    if (error) setError(error)
    else setFlowStep(flowStep + 1)
  }

  const submitButtonDisabled =
    loading ||
    !variableSymbol ||
    !username ||
    !password ||
    !confirmPassword ||
    codeModalOpen

  const submitRegistration = async () => {
    if (loading) return

    const validationErrors = compact([
      validations.variableSymbol(variableSymbol),
      usernameType === 'email'
        ? validations.email(username)
        : validations.phone(username),
      validations.password(password),
      password !== confirmPassword && 'ERR_PASSWORDS_DONT_MATCH',
      (!password || !username || !variableSymbol) && 'ERR_REQUIRED_IS_MISSING'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }

    setLoading(true)
    setError(null)
    try {
      const {id} = await apiRequest('/auth/register', {
        method: 'POST',
        body: {
          vs: variableSymbol,
          usernameType,
          username,
          password: hashAuthData(password)
        }
      })
      setUserId(id)
      setCodeModalOpen(true)
    } catch (e) {
      await dispatch(
        handleApiError(e, [
          [
            'ERR_INVALID_VS',
            'ERR_DUPLICATE_RECORD',
            'ERR_INVALID_EMAIL',
            'ERR_INVALID_PHONE',
            'ERR_RATELIMIT_EXCEEDED'
          ],
          errorCode => setError(errorCode)
        ])
      )
    } finally {
      setLoading(false)
    }
  }

  const codeModalProps = useMemo(
    () => ({
      code,
      isOpen: codeModalOpen,
      contactType: usernameType,
      close: () => setCodeModalOpen(false),
      resendCodeApiRequest: () =>
        apiRequest('/verification-codes/send', {
          method: 'POST',
          body: {
            contactType: usernameType,
            contactValue: username,
            reason: 'REGISTRATION',
            userId
          }
        }),
      validateCodeAndContinue: async (
        code,
        errorCallback,
        verificationToken = null
      ) => {
        try {
          const {username, usernameType, token} = await apiRequest(
            '/auth/validate-registration',
            {
              method: 'POST',
              body: {userId, code, verificationToken}
            }
          )

          await storage.setItem('username', username)
          await saveCredentials(token, username)
          dispatch({type: 'LOGIN'})
          if (continueFlow) continueFlow({usernameType})
        } catch (err) {
          await dispatch(
            handleApiError(err, [
              [
                'ERR_EXPIRED',
                'ERR_USER_ALREADY_ACTIVATED',
                'ERR_INVALID_DATA',
                'ERR_CODE_ENTER_LIMIT_EXCEEDED'
              ],
              errorCode => errorCallback(errorCode)
            ])
          )
        }
      }
    }),
    [
      code,
      codeModalOpen,
      continueFlow,
      dispatch,
      userId,
      username,
      usernameType
    ]
  )

  return {
    flowStep,
    loading,
    error,
    fields: {variableSymbol, usernameType, username, password, confirmPassword},
    fieldSetters,
    validateVSAndContinue,
    validateUsernameAndContinue,
    submitButtonDisabled,
    submitRegistration,
    codeModalProps
  }
}

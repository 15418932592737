export const theme = {
  alert: {
    colors: {
      success: {
        background: '#D6F8F7',
        color: '#004155'
      },
      error: {
        background: '#F0004B',
        color: '#F4F6F9'
      },
      info: {
        background: '#D6F8F7',
        color: '#004155'
      }
    }
  },
  text: {
    colors: {
      green: '#45DBCC',
      red: '#F0004B',
      default: '#004155',
      gray: '#808287'
    }
  },
  app: {
    colors: {
      background: {dark: '#f3f5f6', light: '#fff'}
    },
    sizes: {
      maxWidth: '868px',
      minWidth: '768px'
    }
  },
  button: {
    colors: {
      primary: {normal: '#45DBCC', hover: '#45DBCC', color: '#004155'},
      secondary: {normal: '#45DBCC', hover: '#45DBCC', color: '#004155'},
      ghost: {
        normal: '#fff',
        hover: '#fff',
        color: '#004155',
        border: '#45DBCC'
      },
      disabled: {
        normal: 'rgba(69, 219, 204, 0.5)',
        hover: 'rgba(69, 219, 204, 0.5)',
        color: 'rgba(0, 47, 94, 0.4)'
      }
    },
    sizes: {
      medium: {
        height: '37px',
        padding: '8px 24px',
        fontSize: '16px',
        borderRadius: '110px'
      }
    }
  },
  switch: {
    colors: {
      on: {
        background: '#45DBCC',
        knob: '#F4F6F9'
      },
      off: {
        background: '#808287',
        knob: '#F4F6F9'
      },
      disabled: {
        background: '#808287',
        knob: '#D8E1E6'
      }
    },
    sizes: {
      width: '48px',
      height: '24px'
    }
  },
  inputfield: {
    colors: {
      input: {
        border: {
          normal: '#D8E1E6',
          error: '#F0004B'
        },
        background: '#fff',
        color: '#004155',
        placeholder: 'rgba(93, 112, 131, 0.64)'
      },
      error: {
        background: '#F0004B',
        color: '#fff'
      }
    }
  },
  groupItem: {
    colors: {
      red: '#F0004B',
      default: '#004155'
    }
  }
}

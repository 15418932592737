import React from 'react'
import styled from 'styled-components'
import {ChevronDown} from 'react-feather'
import {formatMessage} from '../../i18n'

import Text from './Text'

const SelectWrapper = styled.div`
  position: relative;
`

const SelectLabel = styled(Text).attrs({type: 'secondary'})`
  margin-left: 8px;
`

const SelectIcon = styled(ChevronDown)`
  position: absolute;
  bottom: 12px;
  right: 16px;
  pointer-events: none;
`

const StyledSelect = styled.select`
  display: block;
  border: 1px solid #d8e1e6;
  width: 100%;
  background-color: #fff;
  padding: 0 16px;
  height: 50px;
  border-radius: 0;
  font-size: 16px;
  outline: none;
  appearance: none;
  margin: 8px 0 ${props => (props.withoutSpacing ? '0' : '24px')} 0;
  font-family: 'Sofia Pro', sans-serif;

  color: ${props =>
    props.theme.inputfield.colors.input[
      props.placeholderSelected ? 'placeholder' : 'color'
    ]};

  [disabled] {
    color: ${props => props.theme.inputfield.colors.input.placeholder};
  }
  & option {
    color: ${props => props.theme.inputfield.colors.input.color};
  }
`

const Select = ({
  label,
  placeholder,
  value,
  options,
  withoutSpacing,
  required,
  ...restProps
}) => (
  <SelectWrapper>
    {label && (
      <SelectLabel>
        {label}{' '}
        {required && (
          <Text type="secondary">{` (${formatMessage('mandatory')})`}</Text>
        )}
      </SelectLabel>
    )}
    <SelectIcon />
    <StyledSelect
      placeholderSelected={placeholder && value === ''}
      value={value}
      withoutSpacing={withoutSpacing}
      {...restProps}
    >
      {placeholder && (
        <option key="__placeholder__" value="" disabled hidden>
          {placeholder}
        </option>
      )}
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ))}
    </StyledSelect>
  </SelectWrapper>
)

export default Select

import React from 'react'
import {formatMessage} from '../../../i18n'
import styled from 'styled-components'

import {Link, Button, InputField, Alert} from '../../ui'
import About from '../About'
import Layout from '../Layout'
import ForgotPasswordBlock from './forgotPassword/ForgotPasswordBlock'
import {useLoginForm} from '../../../actions/auth/loginForm'

const Form = styled.form`
  margin-top: 70px;
`

const ActionLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  margin-top: 32px;
  color: #808287;

  &:hover {
    color: #cccdcf;
  }
`

const Login = () => {
  const {loading, error, fields, fieldSetters, submit} = useLoginForm('web')
  return (
    <Layout screen="login">
      <About screen="login" />
      <Form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        <InputField
          data-cy="username"
          label={formatMessage('emailOrPhone')}
          value={fields.username}
          onChangeText={fieldSetters.username}
        />
        <InputField
          data-cy="pwd"
          label={formatMessage('password')}
          type="password"
          value={fields.password}
          onChangeText={fieldSetters.password}
          action={<ForgotPasswordBlock testID="open-forgot-password" />}
        />
        <Button data-cy="submit" block disabled={loading}>
          {formatMessage('signInLong')}
        </Button>
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        <ActionLink data-cy="no-existing-account" to="/auth/registration">
          {formatMessage('dontHaveAnAccountYet')}
        </ActionLink>
      </Form>
    </Layout>
  )
}

export default Login

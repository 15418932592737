import * as Sentry from '@sentry/browser'

import {isWeb} from '../utils/utils'
import storage from '../utils/storage'
import {loginStatusSelector} from '../selectors'
import {apiRequest, loadWebAccessToken} from './apiRequest'
import handleApiError from './handleApiError'
import {SET_VALUE} from './commonActions'
import {setLocale, getCurrentLocale, changeLocaleIfProvided} from '../i18n'

export const pullConfig = () => async dispatch => {
  try {
    window.config = await apiRequest('/frontend-config')
    return true
  } catch (e) {
    await dispatch(handleApiError(e))
    return false
  }
}

export const pullStorageData = () => async (dispatch, getState) => {
  await storage.pullData()
  await changeLocaleIfProvided()
  const locale = storage.getItem('locale')
  if (locale) {
    if (locale !== getCurrentLocale()) setLocale(locale)
  } else {
    await storage.setItem('locale', getCurrentLocale())
  }

  if (isWeb()) {
    const isLoggedIn = await loadWebAccessToken()
    dispatch(SET_VALUE(['auth', 'loginStatus'], isLoggedIn))
  } else {
    dispatch(
      SET_VALUE(['auth', 'loginStatus'], !!storage.getItem('deviceToken'))
    )
  }
}

// option to omit card settings - skips 2 DP calls per card == may be much quicker
export const pullUserData = params => async (dispatch, getState) => {
  const state = getState()
  // no-op if already loading
  // TODO: there may be cases where we want to load newer (or with cards) data
  // even if there is request in flight
  if (state.userDataLoading) return
  try {
    dispatch(SET_VALUE(['userDataLoading'], true))
    const userData = await apiRequest('/client', {
      queryParams: {
        ...(params && params.omitCardSettings ? {omitCardSettings: '1'} : {})
      }
    })
    dispatch(SET_VALUE(['userDataLoading'], false))
    // in case user logged out in the meantime, do nothing
    if (!loginStatusSelector(state)) return
    Sentry.configureScope(scope => {
      scope.setUser({
        id: userData.user.id,
        username: userData.user[userData.user.contactType]
      })
    })

    // Check whether login data were changed
    const usernameChanged =
      userData.messages &&
      userData.messages.some(message => message === 'usernameChanged')
    const usernameChangedWarning = usernameChanged
      ? {visibleGeneralError: 'WARN_USERNAME_CHANGED'}
      : {}

    if (usernameChanged) {
      await storage.setItem('username', userData.user.email)
    }

    dispatch(
      SET_VALUE(null, {...state, ...userData, ...usernameChangedWarning})
    )
  } catch (e) {
    await dispatch(handleApiError(e))
    dispatch(SET_VALUE(['userDataLoading'], false))
  }
}

import React, {Fragment} from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import {Modal, Button, Alert, Group, GroupItem, Text, CardLogo} from '../ui'
import reprintPinContainer from '../../containers/cards/reprintPinContainer'
import CorrespondenceAddress from '../profile/CorrespondenceAddress'

const TypeLabel = styled(Text).attrs({type: 'secondary'})`
  position: absolute;
  right: 24px;
  bottom: 16px;
`

const ReprintPinModal = props => (
  <Modal
    isOpen={props.isModalOpen}
    onRequestClose={props.closeModal}
    title={formatMessage('reprintPinModalTitle')}
    data-cy="reprint-pin-modal"
  >
    <Group>
      <GroupItem
        data-card-id={props.card.id}
        reverseContent
        value={formatMessage('cardPanMasked', {
          pan: props.card.pan.slice(props.card.pan.length - 4)
        })}
        label={props.card.cardExpiration}
        beforeContent={
          <CardLogo
            cardProduct={props.card.cardProduct}
            cardOrganization={props.card.cardOrganization}
          />
        }
      >
        {props.card.cardOrganization === 'skpay' && (
          <TypeLabel>
            {formatMessage('cardProductDescription', {
              cardProduct: props.card.cardProduct
            })}
          </TypeLabel>
        )}
      </GroupItem>
    </Group>
    <Group spaced>
      <CorrespondenceAddress label={formatMessage('reprintPinAddressLabel')} />
    </Group>
    <Alert type="info">{formatMessage('reprintPinModalInfo')}</Alert>
    {props.error && (
      <Alert data-cy={props.error} type="error">
        {formatMessage(props.error)}
      </Alert>
    )}
    {props.success ? (
      <>
        <Alert type="success">{formatMessage('requestAccepted')}</Alert>
        <Button
          block
          data-cy="request-sent-dismiss"
          disabled={props.loading}
          onClick={props.closeModal}
        >
          {formatMessage('close')}
        </Button>
      </>
    ) : (
      <Button
        block
        data-cy="submit-reprint-pin"
        disabled={props.loading}
        onClick={() => props.submit(props.card.id)}
      >
        {formatMessage('reprintPinSubmitButton')}
      </Button>
    )}
  </Modal>
)

const ReprintPin = props => (
  <Fragment>
    <props.Link openModal={props.openModal} />
    <ReprintPinModal {...props} />
  </Fragment>
)

export default reprintPinContainer(ReprintPin)

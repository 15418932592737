import React, {Fragment} from 'react'
import {Route, Switch, Redirect, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {compose, lifecycle} from 'recompose'
import styled, {injectGlobal, ThemeProvider} from 'styled-components'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'

import requireLoginStatusContainer from '../containers/requireLoginStatusContainer'
import userContainer from '../containers/userContainer'
import {allCardsListSelector, accountListSelector} from '../selectors'
import {
  withConfig,
  withStorageData,
  withUserDataOnly,
  withUserAndCardData
} from '../withData'
import {formatMessage} from '../i18n'

// auth
import Login from './auth/login/Login'
import Registration from './auth/registration/Registration'
// header, footer
import Header from './header/Header'
import Footer from './Footer'
// profile
import Profile from './profile/Profile'
// accounts
import Account from './accounts/Account'
import Accounts from './accounts/Accounts'
import AccountManagement from './accounts/AccountManagement'
import BankStatements from './accounts/bankStatements/BankStatements'
import GuestsManagement from './accounts/guests/GuestsManagement'
import AccountCardsManagement from './accounts/cards/AccountCardsManagement'
import Transactions from './accounts/transactions/Transactions'
// cards
import CardsManagement from './cards/CardsManagement'
import CardManagement from './cards/CardManagement'
// other
import GeneralError from './GeneralError'
import {theme} from './ui/theme'
import {LoadingOverlay, LoadingPlaceholder} from './ui'

/*eslint no-unused-expressions: ["error", { "allowTaggedTemplates": true }]*/
injectGlobal`
  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia_pro_regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia_pro_medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;

}

  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia_pro_bold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;

}

  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia_pro_light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;

}

  *, *::after, *::before {
    box-sizing: border-box;
  }
  html {
    height: 100%;
  }

  body {
    background-color: #fff;
    background-repeat: no-repeat;
    height: 100%;
  }

  #root,
  #root > div {
    height: 100%;
  }
`

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const Content = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`

const Auth = () => (
  <StyledApp isAuth>
    <Content>
      <Switch>
        <Route exact path="/auth/registration" component={Registration} />
        <Route exact path="/auth/login" component={Login} />
        <Redirect to="/auth/login" />
      </Switch>
    </Content>
  </StyledApp>
)

const _LoggedApp = ({singleAccount, firstAccountId, singleCard}) => (
  <StyledApp>
    <Header
      singleAccount={singleAccount}
      singleCard={singleCard}
      firstAccountId={firstAccountId}
    />
    <Content>
      <Switch>
        <Route exact path="/404" render={() => 'Not found'} />
        {singleAccount ? (
          <Redirect
            exact
            from="/accounts"
            to={`/accounts/${firstAccountId}/transactions`}
          />
        ) : (
          <Route exact path="/accounts" component={Accounts} />
        )}
        <Redirect
          exact
          from="/accounts/:accountId"
          to="/accounts/:accountId/transactions"
        />
        <Route
          path="/accounts/:accountId"
          render={props => (
            <Account {...props}>
              <Switch>
                <Route
                  exact
                  path="/accounts/:accountId/transactions"
                  component={Transactions}
                />
                <Route
                  exact
                  path="/accounts/:accountId/management"
                  component={AccountManagement}
                />
                <Route
                  exact
                  path="/accounts/:accountId/cards"
                  component={AccountCardsManagement}
                />
                <Route
                  exact
                  path="/accounts/:accountId/cards/:cardId"
                  component={CardManagement}
                />
                <Route
                  exact
                  path="/accounts/:accountId/bank-statements"
                  component={BankStatements}
                />
                <Route
                  exact
                  path="/accounts/:accountId/guests"
                  component={GuestsManagement}
                />
              </Switch>
            </Account>
          )}
        />
        <Route
          path="/cards"
          render={props => (
            <CardsManagement {...props}>
              <Route exact path="/cards/:cardId" component={CardManagement} />
            </CardsManagement>
          )}
        />
        <Route path="/profile" component={Profile} />
        <Redirect to="/accounts" />
      </Switch>
    </Content>
    <Footer />
  </StyledApp>
)

const LoadingUserLoggedInApp = () => (
  <StyledApp>
    <Header loadingUser />
    <Content>
      <LoadingPlaceholder title={formatMessage('loadingData')} />
    </Content>
    <Footer />
  </StyledApp>
)

const enhanceLoggedApp = compose(
  // withRouter is needed to force rerender when route changes
  withRouter,
  withUserDataOnly({loadingComponent: () => <LoadingUserLoggedInApp />}),
  // start loading card settings immediately, but in background
  withUserAndCardData(),
  connect((state, props) => {
    const accounts = accountListSelector(state)
    const cards = allCardsListSelector(state)

    const singleAccount = accounts.length === 1
    const firstAccountId = singleAccount ? accounts[0].id : ''
    const singleCard = cards.length === 1

    return {singleAccount, firstAccountId, singleCard}
  })
)

const LoggedApp = enhanceLoggedApp(_LoggedApp)

const App = ({loginStatus}) => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GeneralError />
        {loginStatus ? <LoggedApp /> : <Auth />}
      </Fragment>
    </ThemeProvider>
  )
}

const enhanceApp = compose(
  // withRouter is needed to force rerender when route changes
  withRouter,
  withConfig({
    loadingComponent: () => <LoadingOverlay isOpen />,
    onConfigPulled: () => {
      if (window.config.sentry) Sentry.init({dsn: window.config.sentry})
      ReactGA.initialize(window.config.gaTrackingId, {debug: false})
      window.appVersion = window.config.webAppVersion
    }
  }),
  withStorageData({loadingComponent: () => <LoadingOverlay isOpen />}),
  requireLoginStatusContainer,
  userContainer,
  lifecycle({
    componentDidMount() {
      // FIXME just a quick hack
      if (window.config.titleSuffix) {
        document.title = `${document.title} ${window.config.titleSuffix}`
      }
      // GA
      // we don't have user yet, so we don't know about userId
      ReactGA.pageview(this.props.location.pathname)
    },
    componentDidUpdate(prevProps) {
      // GA
      if (prevProps.location.pathname !== this.props.location.pathname) {
        ReactGA.set({userId: this.props.user && this.props.user.id})
        ReactGA.pageview(this.props.location.pathname)
      }
    }
  })
)

export default enhanceApp(App)

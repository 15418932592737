import React from 'react'
import styled from 'styled-components'

import {Link, Text} from './ui'
import {formatMessage} from '../i18n'

const StyledFooter = styled.footer`
  flex-shrink: 0;
  padding: 48px 0;
  text-align: center;
  background-color: #f3f5f6;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  & > * {
    padding: 0 1rem;

    & + * {
      border-left: 1px solid #808287;
    }
  }
`

const Footer = () => (
  <StyledFooter>
    <Text small="true" type="secondary">
      {`${formatMessage('footer')} | ${formatMessage('version')} ${
        window.appVersion || '---'
      }`}
    </Text>
    <Links>
      <Link
        small="true"
        color="gray"
        href={window.config.sppsUrls.businessConditions}
      >
        {formatMessage('businessConditions')}
      </Link>
      <Link small="true" color="gray" href={window.config.sppsUrls.fees}>
        {formatMessage('fees')}
      </Link>
      <Link
        small="true"
        color="gray"
        href={window.config.sppsUrls.dataProtection}
      >
        {formatMessage('dataProtection')}
      </Link>
    </Links>
  </StyledFooter>
)

export default Footer

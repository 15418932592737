import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {SET_VALUE} from '../commonActions'
import {getInitialReprintPinForm} from '../../state'

const PATH = ['forms', 'reprintPinForm']

export const selector = state => state.forms.reprintPinForm

const openModal = () => (dispatch, getState) => {
  dispatch(SET_VALUE(PATH, getInitialReprintPinForm()))
  dispatch(SET_VALUE([...PATH, 'isModalOpen'], true))
}

const closeModal = () => (dispatch, getState) => {
  if (selector(getState()).loading) return
  dispatch(SET_VALUE(PATH, getInitialReprintPinForm()))
}

const submit = cardId => async (dispatch, getState) => {
  const {loading} = selector(getState())
  const card = getState().cards[cardId]

  if (loading) return

  dispatch(SET_VALUE([...PATH, 'loading'], true))
  try {
    await apiRequest(`/cards/${cardId}/reissue`, {
      method: 'POST',
      body: {accountId: card.accountId, type: 'pin'}
    })
    dispatch(SET_VALUE([...PATH, 'success'], true))
  } catch (e) {
    await dispatch(handleApiError(e))
  } finally {
    dispatch(SET_VALUE([...PATH, 'loading'], false))
  }
}

export const actions = {openModal, closeModal, submit}

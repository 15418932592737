import React from 'react'
import styled from 'styled-components'

import {
  useChangePasswordFlow,
  useChangePasswordForm
} from '../../actions/profile/changePasswordForm'
import {formatMessage} from '../../i18n'
import {Button, Modal, InputField, Alert, CodeModal} from '../ui'

const ChangePasswordModal = props => {
  const {loading, error, fields, fieldSetters, submit} = useChangePasswordForm(
    props
  )

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('changePassword')}
      data-cy="enter-password-modal"
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submit()
        }}
      >
        <InputField
          required
          data-cy="old-password"
          type="password"
          label={formatMessage('oldPassword')}
          value={fields.oldPassword}
          onChangeText={fieldSetters.oldPassword}
        />
        <InputField
          required
          data-cy="new-password"
          type="password"
          label={formatMessage('newPassword')}
          value={fields.newPassword}
          onChangeText={fieldSetters.newPassword}
        />
        <InputField
          required
          data-cy="new-password-again"
          type="password"
          label={formatMessage('newPasswordRepeat')}
          value={fields.newPasswordRepeat}
          onChangeText={fieldSetters.newPasswordRepeat}
        />
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
        <Button block data-cy="submit" disabled={loading} visual="secondary">
          {formatMessage('continue')}
        </Button>
      </form>
    </Modal>
  )
}

const ChangePasswordContainer = styled.div`
  margin-top: 48px;
`

const ChangePassword = () => {
  const {
    open,
    changePasswordModalProps,
    codeModalProps
  } = useChangePasswordFlow()
  return (
    <ChangePasswordContainer>
      <Button data-cy="change-password" block onClick={open} visual="ghost">
        {formatMessage('changePassword')}
      </Button>
      <ChangePasswordModal {...changePasswordModalProps} />
      <CodeModal
        title={formatMessage('changePasswordConfirmation')}
        {...codeModalProps}
      />
    </ChangePasswordContainer>
  )
}

export default ChangePassword

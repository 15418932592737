import React from 'react'
import {branch, renderComponent} from 'recompose'

let NotFoundComponent

export const initHandleNotFound = Component => {
  NotFoundComponent = Component
}

const handleNotFound = isNotFound =>
  branch(
    isNotFound,
    renderComponent(() => <NotFoundComponent />)
  )

export const handleShortNotFound = isNotFound =>
  branch(
    isNotFound,
    renderComponent(() => null)
  )

export default handleNotFound

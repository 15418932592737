import React from 'react'
import styled from 'styled-components'
import {Link as RouterLink} from 'react-router-dom'

const StyledLink = styled.span`
  color: ${props =>
    props.color === 'gray'
      ? '#808287'
      : props.color === 'red'
      ? '#F0004B'
      : '#004155'};
  font-family: 'Sofia Pro', sans-serif;
  font-size: ${props => (props.small ? '14px' : '16px')};
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: none;

  &:hover {
    color: ${props =>
      props.color === 'gray'
        ? '#808287'
        : props.color === 'red'
        ? '#F0004B'
        : '#004155'};
  }
`

const StyledButton = StyledLink.withComponent('button')
const StyledRouterLink = StyledLink.withComponent(RouterLink)
const StyledOutsideLink = StyledLink.withComponent('a')

const Link = ({to, onClick, href, sameWindow, ...props}) => {
  if (href) {
    return (
      <StyledOutsideLink
        href={href}
        rel="noopener noreferrer"
        target={sameWindow ? '_self' : '_blank'}
        {...props}
      />
    )
  }
  if (to) {
    return <StyledRouterLink to={to} {...props} />
  } else {
    return <StyledButton onClick={onClick} {...props} />
  }
}

export default Link

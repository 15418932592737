import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {Trash2} from 'react-feather'
import {Redirect} from 'react-router-dom'

import {
  inviteListsGroupedSelector,
  accountSelector,
  accountCardsListSelector
} from '../../../selectors'
import {useInvite} from '../../../actions/accounts/guests/guestManagementActions'
import {Group, GroupItem, Text, Link, Button} from '../../ui'
import {formatMessage} from '../../../i18n'
import InviteBlock from './InviteBlock'

const Container = styled.div`
  flex: 1;
`

const SectionTitle = styled(Text).attrs({type: 'secondary', small: false})`
  margin-bottom: 16px;
  margin-left: 8px;
  display: block;

  * + & {
    margin-top: 32px;
  }
`

const AcceptedInvite = props => {
  const {invite, deleteInvite, inviteDeleteLoading} = useInvite(props.inviteId)
  return (
    <GroupItem
      data-cy="invite-accepted"
      label={invite.nameByParent}
      value={invite.email || invite.phone}
      action={
        <Link
          data-cy="invite-delete"
          disabled={inviteDeleteLoading}
          onClick={deleteInvite}
        >
          <Trash2 color="#5D7083" />
        </Link>
      }
    />
  )
}

const WaitingInvite = props => {
  const {
    invite,
    deleteInvite,
    inviteDeleteLoading,
    resendInvite,
    inviteResendRequest
  } = useInvite(props.inviteId)
  return (
    <GroupItem
      data-cy="invite-waiting"
      value={invite.email || invite.phone}
      label={formatMessage('invitationSentOn', {
        invitationDate: new Date(invite.date)
      })}
      reverseContent
      action={
        <Link
          data-cy="invite-delete"
          disabled={inviteDeleteLoading}
          onClick={deleteInvite}
        >
          <Trash2 color="#5D7083" />
        </Link>
      }
    >
      <Link
        data-cy="invite-resend"
        disabled={inviteResendRequest.loading}
        onClick={resendInvite}
      >
        <Text type="secondary">{formatMessage('sendInvitationAgain')}</Text>
      </Link>
      {inviteResendRequest.success && (
        <div>
          <Text small color="green">
            {' '}
            {formatMessage('inviteResentSuccessfully')}
          </Text>
        </div>
      )}
      {inviteResendRequest.error && (
        <div>
          <Text small color="red">
            {' '}
            {formatMessage(inviteResendRequest.error)}
          </Text>
        </div>
      )}
    </GroupItem>
  )
}

const NoGuests = styled(Text)`
  display: block;
  margin-top: 40px;
  text-align: center;
`

const InviteAction = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`

const GuestsManagement = ({match}) => {
  const accountId = match.params.accountId || ''
  const account = useSelector(state => accountSelector(state, accountId))
  const accountCards = useSelector(state =>
    accountCardsListSelector(state, accountId)
  )
  const hasOpenableCards = accountCards.some(card => card.openable)

  const invites = useSelector(state =>
    inviteListsGroupedSelector(state, accountId)
  )
  const isInvited = invites.accepted.length || invites.waiting.length

  const noInvites =
    invites.accepted.length === 0 && invites.waiting.length === 0

  if (!account) return null
  if (!account.isMine) return <Redirect to="/404" />

  return (
    <Fragment>
      <Container>
        {invites.accepted.length > 0 && (
          <Fragment>
            <Group>
              {invites.accepted.map(invite => (
                <AcceptedInvite key={invite.id} inviteId={invite.id} />
              ))}
            </Group>
          </Fragment>
        )}

        {invites.waiting.length > 0 && (
          <Fragment>
            <SectionTitle>{formatMessage('invitesWaiting')}</SectionTitle>
            <Group>
              {invites.waiting.map(invite => (
                <WaitingInvite key={invite.id} inviteId={invite.id} />
              ))}
            </Group>
          </Fragment>
        )}

        {noInvites && <NoGuests>{formatMessage('noGuests')}</NoGuests>}

        {!hasOpenableCards && (
          <NoGuests type="secondary">
            {formatMessage('activateCardBeforeInviting')}
          </NoGuests>
        )}

        {!isInvited && (
          <InviteBlock
            accountId={accountId}
            render={open => (
              <InviteAction>
                <Button
                  block={!noInvites}
                  visual="secondary"
                  onClick={open}
                  data-cy="invite-open-modal"
                  disabled={!hasOpenableCards}
                >
                  {formatMessage('inviteChild')}
                </Button>
              </InviteAction>
            )}
          />
        )}
      </Container>
    </Fragment>
  )
}

export default GuestsManagement

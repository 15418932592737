import {useState, useMemo, useEffect} from 'react'
import {compact} from 'lodash'
import {useSelector, useDispatch} from 'react-redux'

import {accountSelector, emailSelector} from '../../../selectors'
import {apiRequest} from '../../apiRequest'
import handleApiError from '../../handleApiError'
import * as validations from '../../../utils/validations'
import {usePrevious} from '../../../utils/hooks'

export const useReportTransactionForm = ({
  isOpen,
  transaction,
  continueFlow
}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [email, setEmail] = useState('')
  const [text, setText] = useState('')

  const defaultEmail = useSelector(state => emailSelector(state))

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setLoading(false)
      setError(null)
      setEmail(defaultEmail)
      setText('')
    }
  }, [prevIsOpen, isOpen, defaultEmail])

  const wrapFieldSetter = fieldSetter => value => {
    setError(null)
    fieldSetter(value)
  }

  const submit = async () => {
    if (loading) return

    const validationErrors = compact([
      validations.email(email),
      !text && 'ERR_TEXT_IS_REQUIRED'
    ])
    if (validationErrors.length) {
      setError(validationErrors[0])
      return
    }
    setLoading(true)
    try {
      const account = dispatch((_, getState) =>
        accountSelector(getState(), transaction.accountId)
      )
      await apiRequest(
        `/accounts/${account.id}/transactions/${transaction.id}/report`,
        {
          method: 'POST',
          body: {
            email,
            text,
            vs: account.variableSymbol,
            transaction
          }
        }
      )
      continueFlow()
    } catch (err) {
      setLoading(false)
      await dispatch(handleApiError(err))
    }
  }

  return {
    loading,
    error,
    fields: {email, text},
    fieldSetters: {
      email: wrapFieldSetter(setEmail),
      text: wrapFieldSetter(setText)
    },
    submit
  }
}

export const useReportTransactionFlow = transaction => {
  const [reportTransactionModalOpen, setReportTransactionModalOpen] = useState(
    false
  )

  const open = () => setReportTransactionModalOpen(true)

  const reportTransactionModalProps = useMemo(
    () => ({
      isOpen: reportTransactionModalOpen,
      close: () => setReportTransactionModalOpen(false),
      transaction,
      continueFlow: () => setReportTransactionModalOpen(false)
    }),
    [reportTransactionModalOpen, transaction]
  )

  return {open, reportTransactionModalProps}
}

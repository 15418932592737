import styled from 'styled-components'

const Group = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${props => (props.spaced ? '24px' : 0)} 0;
  box-shadow: 0 -1px 2px rgba(120, 137, 153, 0.1),
    0 4px 6px rgba(120, 137, 153, 0.14);
  /* border-top: 1px solid rgba(120, 137, 153, 0.1); */
  border-radius: 4px;
`

export default Group

import React from 'react'
import styled from 'styled-components'

import {
  useInviteFlow,
  useInviteForm
} from '../../../actions/accounts/guests/inviteForm'
import {Modal, InputField, Button, Alert, Text} from '../../ui'
import {formatMessage} from '../../../i18n'

const StyledText = styled(Text)`
  display: block;
  margin-bottom: ${props => (props.bold ? '16px' : 0)};
`

const Notice = styled(Alert)`
  margin-bottom: 24px;
`

const InviteModal = props => {
  const {loading, error, fields, fieldSetters, submitInvite} = useInviteForm(
    props
  )
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.close}
      title={formatMessage('inviteChild')}
    >
      <form
        onSubmit={e => {
          e.preventDefault()
          submitInvite()
        }}
      >
        <InputField
          data-cy="phone"
          label={formatMessage('mobilePhoneNumber')}
          placeholder={formatMessage('placeholderContactValue', {
            contactType: 'phone'
          })}
          value={fields.username}
          onChangeText={fieldSetters.username}
        />
        <Text type="secondary">
          {formatMessage('inviteFormDescriptionMobile')}
        </Text>
        <Notice>
          <StyledText bold>{formatMessage('inviteFormNoticeTitle')}</StyledText>
          <StyledText type="secondary">
            {formatMessage('inviteFormNoticeFirst')}
          </StyledText>
          <StyledText type="secondary">
            {formatMessage('inviteFormNoticeSecond')}
          </StyledText>
        </Notice>
        <Button
          visual="secondary"
          data-cy="invite-send"
          block
          disabled={loading}
        >
          {formatMessage('inviteChild')}
        </Button>
        {error && (
          <Alert data-cy={error} type="error">
            {formatMessage(error)}
          </Alert>
        )}
      </form>
    </Modal>
  )
}

const InviteBlock = props => {
  const {open, inviteModalProps} = useInviteFlow(props.accountId)

  return (
    <>
      {props.render(open)}
      <InviteModal {...inviteModalProps} />
    </>
  )
}

export default InviteBlock

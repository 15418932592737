import {connect} from 'react-redux'
import {compose, hoistStatics} from 'recompose'
import {allCardsAndAccountsListSelector} from '../../selectors'

const container = compose(
  connect((state, props) => ({
    myCardsAndAccounts: allCardsAndAccountsListSelector(state)
  }))
)

export default hoistStatics(container)

import React from 'react'
import styled from 'styled-components'

import {formatMessage} from '../../i18n'
import Modal from './Modal'
import Link from './Link'
import Button from './Button'
import InputField from './InputField'
import Text from './Text'
import Alert from './Alert'
import Paragraph from './Paragraph'

const Action = styled.div`
  margin-top: 24px;
`

const EnterCodeModalOld = props => (
  <Modal
    isOpen={props.isOpen}
    onRequestClose={props.closeModal}
    title={props.title}
    small={!!props.small}
    data-cy="enter-code-modal"
  >
    <Paragraph>
      <Text>
        {formatMessage('pleaseEnterVerificationCode', {
          contactType: props.contactType
        })}
      </Text>
    </Paragraph>

    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        props.submit()
      }}
    >
      <InputField
        data-cy="verification-code"
        label={formatMessage('enterVerificationCode')}
        placeholder={formatMessage('verificationCodeFromContact', {
          contactType: props.contactType
        })}
        value={props.fields.code}
        onChangeText={text => props.setField('code', text)}
        action={
          <Link data-cy="resend-code" onClick={props.resendCode} type="button">
            {formatMessage('resendCode')}
          </Link>
        }
      />
      {props.codeResendRequest &&
        (props.codeResendRequest.success ? (
          <Alert type="success" data-cy="code-resent-successfully">
            {formatMessage('codeResentSuccessfully')}
          </Alert>
        ) : (
          <Alert type="error" data-cy="code-resend-error">
            {formatMessage(props.codeResendRequest.error)}
          </Alert>
        ))}
      {props.error && (
        <Alert data-cy={props.error} type="error">
          {formatMessage(props.error)}
        </Alert>
      )}
      <Action>
        <Button
          block
          data-cy="submit-code"
          disabled={props.loading}
          visual="secondary"
        >
          {formatMessage('submit')}
        </Button>
      </Action>
    </form>
  </Modal>
)

export default EnterCodeModalOld

import {
  sortBy,
  values,
  filter,
  map,
  orderBy,
  uniq,
  groupBy,
  defaults
} from 'lodash'
import {FEATURE_CATEGORIES} from './constants'
import {RootState} from './state'

// ##### AUTH #####

export const loginStatusSelector = (state: RootState) => state.auth.loginStatus

// ##### PIN LOCK #####

export const pinLockStatusSelector = (state: RootState) => state.pinLock.status

// ##### CLIENT #####

export const userSelector = (state: RootState) => state.user

export const clientsSelector = (state: RootState) => state.clients

export const isParentSelector = (state: RootState) => state.isParent

// this can be undefined if user dont have any remoteClientId
export const clientSelector = (state: RootState) => {
  if (!state.user) return undefined
  if (!state.user.clientId) return undefined
  return state.clients[state.user.clientId]
}

export const usernameSelector = (state: RootState) => {
  const user = userSelector(state)
  const client = clientSelector(state)
  if (client) {
    return client.name || client.email || client.phone
  }
  if (user) {
    if (user.email) return user.email
    if (user.phone) return user.phone
  }
  return ''
}

export const emailSelector = (state: RootState) => {
  const user = userSelector(state)
  const client = clientSelector(state)

  if (user && user.email) {
    return user.email
  } else if (client && client.email) {
    return client.email
  }
  return ''
}

// ##### ACCOUNTS #####

export const accountsSelector = (state: RootState) => state.accounts

export const accountListSelector = (state: RootState) => {
  const accounts = accountsSelector(state)
  return sortBy(values(accounts), 'id')
}

export const accountSelector = (state: RootState, accountId) => {
  const accounts = accountsSelector(state)
  return accounts[accountId]
}

export const accountClientSelector = (state: RootState, accountId) => {
  const account = accountSelector(state, accountId)
  const clients = clientsSelector(state)
  return account ? clients[account.clientId] : undefined
}

export const accountFromCardSelector = (state: RootState, card) => {
  return accountsSelector(state)[card.accountId]
}

export const usersAccountsSelector = (state: RootState) => {
  const accounts = accountsSelector(state)

  return Object.keys(accounts).reduce<any[]>((allUsersAccounts, accountKey) => {
    if (accounts[accountKey].isMine)
      allUsersAccounts.push(accounts[accountKey as any] as any)
    return allUsersAccounts
  }, [])
}

export const nicknameOrDefaultSelector = (
  state: RootState,
  accountId,
  defaultNickname
) => {
  const accounts = accountsSelector(state)
  return accounts[accountId].inviteName || defaultNickname
}

export const childrensAccountSelector = (state: RootState) => {
  if (state.isParent) return null
  return Object.values(accountsSelector(state))[0] // children own only 1 account
}

// ##### TRANSACTIONS #####

export const accountTransactionsDataSelector = (state: RootState, accountId) =>
  state.transactionsByAccount[accountId]

// ##### SAVINGS #####

export const savingsSelector = (state: RootState) => state.savings

export const accountSavingSelector = (state: RootState, accountId: string) => {
  const savings = savingsSelector(state)
  const accountSaving = savings
    ? filter(savings, saving => saving.accountId === accountId)
    : []
  return accountSaving.length ? accountSaving[0] : null
}

// ##### TASKS #####
export const currentTasksSelector = (state: RootState) => state.tasks.current

export const sortedCurrentTasksSelector = (state: RootState) => {
  const tasks = currentTasksSelector(state)
  return orderBy(Object.values(tasks), task => parseInt(task.id, 10), 'desc')
}

export const pastTasksSelector = (state: RootState) => state.tasks.past

export const sortedPastTasksSelector = (state: RootState) => {
  const tasks = pastTasksSelector(state)
  return orderBy(Object.values(tasks), task => parseInt(task.id, 10), 'desc')
}

// ##### CARDS #####

const cardsSelector = (state: RootState) => state.cards

export const allCardsListSelector = (state: RootState) => {
  const cards = cardsSelector(state)
  return values(cards)
}

export const areUserAndCardSettingsNotLoaded = (state: RootState) => {
  const user = userSelector(state)
  const cards = allCardsListSelector(state)

  if (!user) return true
  const openableCard = cards.find(c => c.openable)
  // when card is openable it should have settings - if they're missing,
  // we probably need to do a full client request to get them
  return !!openableCard && !openableCard.settings
}

export const accountCardsListSelector = (state: RootState, accountId) => {
  const cards = cardsSelector(state)
  return filter(cards, card => card.accountId === accountId)
}

export const allCardsAndAccountsListSelector = (state: RootState) => {
  const cardsList = allCardsListSelector(state)
  const accounts = accountsSelector(state)
  return sortBy(
    map(cardsList, card => ({card, account: accounts[card.accountId]})),
    'card.accountId'
  )
}

export const cardSelector = (state: RootState, cardId) => {
  const cards = cardsSelector(state)
  return cards[cardId]
}

// ##### GUESTS #####

export const invitesSelector = (state: RootState) => state.invites

export const inviteListsByAccount = (state: RootState, accountId) => {
  const invites = invitesSelector(state)
  const accountInvites = sortBy(
    filter(invites, invite => invite.accountId === accountId),
    'date'
  )

  return accountInvites
}

export const inviteListsGroupedSelector = (state: RootState, accountId) => {
  const invites = invitesSelector(state)
  const accountInvites = sortBy(
    filter(invites, invite => invite.accountId === accountId),
    'date'
  )
  const groupedInvites = groupBy(accountInvites, invite =>
    invite.accepted ? 'accepted' : 'waiting'
  )

  return defaults(groupedInvites, {accepted: [], waiting: []})
}

export const inviteSelector = (state: RootState, inviteId) => {
  const invites = invitesSelector(state)
  return invites ? invites[inviteId] : null
}

// ##### BANK STATEMENTS #####

export const bankStatementsSelector = (state: RootState, accountId) => {
  const account = accountSelector(state, accountId)
  return account ? account.bankStatements : {}
}

export const bankStatementListByYearSelector = (
  state: RootState,
  accountId,
  year
) => {
  const bankStatements = bankStatementsSelector(state, accountId)
  const filteredBankStatementList = filter(
    bankStatements,
    bankStatement => bankStatement.year === year
  )
  return orderBy(filteredBankStatementList, ['month'], ['desc'])
}

export const bankStatementsYearsSelector = (state: RootState, accountId) => {
  const bankStatements = bankStatementsSelector(state, accountId)
  const years = map(bankStatements, ({year}) => year)
  const uniqYears = uniq(years)
  return orderBy(uniqYears, [], ['desc'])
}

// ##### SAVINGS #####
export const savingTransactionsArraySelector = (state: RootState) =>
  Object.values(state.savingTransactions).sort(
    (tx1, tx2) => tx2.date - tx1.date
  )

export const savingsAmountSelector = (state: RootState) => {
  const savings = [...Object.values(state.savings).values()]
  return savings.length ? savings[0].currentAmount : 0
}

// ##### ANIMATIONS #####
export const lottieAnimationSelector = (state: RootState) => state.animation

// ##### THEME STORE FEATURES #####
export const featuresSelector = (state: RootState) => state.features || []

export const invitationActiveFeaturesSelector = (state: RootState) =>
  state.invitationFeatures && state.invitationFeatures.active

export const invitationPurchasedFeaturesSelector = (state: RootState) =>
  state.invitationFeatures && state.invitationFeatures.purchased

export const colorSchemesSelector = (state: RootState) =>
  state.featureColorSchemes

export const purchasedProfileAvatarsSelector = (state: RootState) =>
  featuresSelector(state).filter(
    feature =>
      feature.purchased &&
      feature.category === FEATURE_CATEGORIES.PROFILE_AVATAR
  )

export const purchasedSavingsAvatarsSelector = (state: RootState) =>
  featuresSelector(state).filter(
    feature =>
      feature.purchased &&
      feature.category === FEATURE_CATEGORIES.SAVINGS_AVATAR
  )

export const purchasedAvatarFramesSelector = (state: RootState) =>
  featuresSelector(state).filter(
    feature =>
      feature.purchased && feature.category === FEATURE_CATEGORIES.AVATAR_BORDER
  )

export const avatarSelector = (state: RootState) => {
  if (state.isParent) return null
  const account = childrensAccountSelector(state)
  return {
    index: account.avatarIndex,
    content: account.avatarContent
  }
}

export const avatarFrameIdSelector = (state: RootState) => {
  if (state.isParent) return null
  const invitationActiveFeatures = invitationActiveFeaturesSelector(state)
  if (!invitationActiveFeatures) return null
  return invitationActiveFeatures.avatarBorder
}

export const smartiePointsSelector = (state: RootState) => {
  if (state.isParent) return null
  const account = childrensAccountSelector(state)
  return account.smartiePoints
}

// ##### QUIZ DATA #####
export const quizesDataSelector = (state: RootState) => {
  const themeFeaturesMap = featuresSelector(state)
    .filter(f =>
      [
        FEATURE_CATEGORIES.THEME_COLOR,
        FEATURE_CATEGORIES.THEME_PATTERN,
        FEATURE_CATEGORIES.THEME_BACKGROUND
      ].includes(f.category)
    )
    .reduce((acc, feature) => {
      acc[feature.id] = true
      return acc
    }, {})
  const invitationPurchasedFeatures = invitationPurchasedFeaturesSelector(state)
  const unlockedThemes = invitationPurchasedFeatures.filter(
    ({featureId, themeColorId}) =>
      themeFeaturesMap[featureId] &&
      (featureId !== 'DEFAULT_COLOR' || themeColorId != null)
  ).length
  return {...state.quizes, unlockedThemes}
}

export const unlockedLevelSelector = (state: RootState) => {
  const quizData = quizesDataSelector(state)
  return quizData ? quizData.unlockedLevel : 1
}

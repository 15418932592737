// EVERYTHING DEPRECATED

export const SET_VALUE = (path, value) => ({
  type: 'SET_VALUE',
  path,
  value
})

export const SET_FORM_FIELD_VALUE = (path, fieldName, fieldValue) => ({
  type: 'SET_FORM_FIELD_VALUE',
  path,
  fieldName,
  fieldValue
})

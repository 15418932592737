import React from 'react'
import styled from 'styled-components'

import {useRegistrationForm} from '../../../actions/auth/registrationForm'
import {formatMessage} from '../../../i18n'

import {
  Button,
  InputField,
  Alert,
  ToggleButton,
  Text,
  Link,
  CodeModal
} from '../../ui'
import HelpSection from './HelpSection'
import About from '../About'
import Layout from '../Layout'

const AgreementDiv = styled.div`
  margin-bottom: 24px;
`

const ActionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  > * + * {
    margin-top: 4px;
  }
`

const Registration = () => {
  const {
    flowStep,
    error,
    fields,
    fieldSetters,
    validateUsernameAndContinue,
    validateVSAndContinue,
    submitButtonDisabled,
    submitRegistration,
    codeModalProps
  } = useRegistrationForm({initialParams: {}, platform: 'web'})
  return (
    <Layout screen="register">
      <About screen="register" />
      <div>
        <HelpSection />
        <form
          onSubmit={e => {
            e.preventDefault()
            submitRegistration()
          }}
        >
          <InputField
            required
            data-cy="vs"
            label={formatMessage('registrationVSLabel')}
            placeholder={formatMessage('tenDigitContractNumber')}
            value={fields.variableSymbol}
            onChangeText={fieldSetters.variableSymbol}
          />
          {flowStep === 0 && (
            <Button
              data-cy="continue"
              block
              disabled={!fields.variableSymbol}
              onClick={() => validateVSAndContinue()}
            >
              {formatMessage('continue')}
            </Button>
          )}
          {flowStep >= 1 && (
            <>
              <ToggleButton
                items={[
                  {
                    title: formatMessage('parent'),
                    name: 'email',
                    'data-cy': 'select-email'
                  },
                  {
                    title: formatMessage('child'),
                    name: 'phone',
                    'data-cy': 'select-phone'
                  }
                ]}
                onClick={fieldSetters.usernameType}
                activeName={fields.usernameType}
              />
              {fields.usernameType === 'email' ? (
                <InputField
                  required
                  data-cy="email"
                  label={formatMessage('email')}
                  placeholder={formatMessage('placeholderContactValue', {
                    contactType: 'email'
                  })}
                  value={fields.username}
                  onChangeText={fieldSetters.username}
                />
              ) : (
                <InputField
                  required
                  data-cy="phone"
                  label={formatMessage('mobilePhoneNumberOfChild')}
                  placeholder={formatMessage('placeholderContactValue', {
                    contactType: 'phone'
                  })}
                  value={fields.username}
                  onChangeText={fieldSetters.username}
                />
              )}
            </>
          )}
          {flowStep === 1 && (
            <Button
              data-cy="continue"
              block
              disabled={!fields.username}
              onClick={() => validateUsernameAndContinue()}
            >
              {formatMessage('continue')}
            </Button>
          )}
          {flowStep >= 2 && (
            <React.Fragment>
              <InputField
                required
                data-cy="pwd"
                label={formatMessage('createPassword')}
                type="password"
                placeholder={formatMessage('minimalLength8Symbols')}
                value={fields.password}
                onChangeText={fieldSetters.password}
                withoutSpacing
              />
              <InputField
                required
                data-cy="pwd2"
                type="password"
                placeholder={formatMessage('repeatPassword')}
                value={fields.confirmPassword}
                onChangeText={fieldSetters.confirmPassword}
              />
            </React.Fragment>
          )}
          {flowStep === 2 && (
            <>
              <AgreementDiv>
                <Text>
                  {formatMessage('iAgreeToProcessingOfPersonalData')}
                  <Link href={window.config.sppsUrls.dataProtection}>
                    {formatMessage('iAgreeToProcessingOfPersonalDataLinkText')}
                  </Link>
                </Text>
              </AgreementDiv>
              <Button data-cy="submit" block disabled={submitButtonDisabled}>
                {formatMessage('signUp')}
              </Button>
            </>
          )}
          {error && (
            <Alert data-cy={error} type="error">
              {formatMessage(error)}
            </Alert>
          )}
          {flowStep === 0 && (
            <ActionTextWrapper>
              <Link
                href="https://smartie.sk/start"
                type="button"
                color="gray"
                small
              >
                {formatMessage('dontHaveCardYet')}{' '}
                {formatMessage('fillOnlineApplication')}
              </Link>
            </ActionTextWrapper>
          )}
        </form>
        <CodeModal
          small
          title={formatMessage('contactInfoValidation')}
          description={formatMessage(
            'pleaseEnterRegistrationVerificationCode',
            {contactType: fields.usernameType}
          )}
          {...codeModalProps}
        />
      </div>
    </Layout>
  )
}

export default Registration

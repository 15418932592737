import React from 'react'
import styled from 'styled-components'

import Text from './Text'
import Loader from './Loader'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-top: 3em;
`

export default ({title}) => (
  <Container>
    <Loader onLightBackground />
    {title && <StyledText>{title}</StyledText>}
  </Container>
)

import {useState, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {userSelector, clientSelector} from '../../selectors'

export const useEditCorrespondenceAddressFlow = () => {
  const user = useSelector(state => userSelector(state))
  const [openedModal, setOpenedModal] = useState(null)

  const open = () => {
    if (openedModal) return
    setOpenedModal('EditAddress')
  }

  const editAddressModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'EditAddress',
      close: () => setOpenedModal(null)
    }),
    [openedModal]
  )

  const codeModalProps = useMemo(
    () => ({
      isOpen: openedModal === 'Code',
      close: () => setOpenedModal(null),
      contactType: user.contactType
    }),
    [openedModal, user.contactType]
  )

  return {open, editAddressModalProps, codeModalProps}
}

export const useCorrespondenceAddress = client => {
  const userClient = useSelector(state => clientSelector(state))

  const isCurrentUser = userClient && (!client || client.id === userClient.id)

  if (isCurrentUser) {
    return {editable: true, address: userClient.correspondenceAddress.joined}
  } else if (client) {
    return {editable: true, address: client.correspondenceAddress.joined}
  } else {
    return {notFound: true}
  }
}

import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'

import {Text, Group, GroupItem} from '../ui'
import {formatMessage} from '../../i18n'
import profileContainer from '../../containers/profile/profileContainer'
import ContactDetail from './ContactDetail'
import ChangePassword from './ChangePassword'
import CorrespondenceAddress from './CorrespondenceAddress'
import ContactUs from './ContactUs'
import {isParentSelector, userSelector} from '../../selectors'

const Layout = styled.div`
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  margin: 40px auto;
`

const Header = styled.div`
  background-color: #f3f5f6;
`

const HeaderTitle = styled(Text).attrs({type: 'title'})`
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  margin: 104px auto 40px;
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const Sidebar = Group.extend`
  width: 305px;
  margin-right: 80px;
  padding: 24px;
`

const Content = styled.div`
  flex: 1;
`

const Row = styled.div`
  display: flex;
`

const ContactArea = Group.extend`
  padding: 24px;
  margin-top: 48px;
`

const SidebarText = styled(Text)`
  display: block;
  text-align: center;
  margin-top: 8px;
  line-height: 24px;
`

const PreviewImage = styled.img`
  width: 225px;
  margin-bottom: 32px;
`

const StoresRow = styled(Row)`
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;
`

const StoreImage = styled.img`
  height: 40px;
`

const Profile = ({client, username}) => {
  const isParent = useSelector(isParentSelector)
  const user = useSelector(userSelector)
  return (
    <Fragment>
      <Header>
        <HeaderTitle data-cy="client-name">{username}</HeaderTitle>
      </Header>
      <Layout>
        <Container>
          <Sidebar>
            <PreviewImage src="/images/phones.png" alt="" />
            <SidebarText bold>{formatMessage('mobileApp')}</SidebarText>
            <SidebarText>{formatMessage('mobileAppPromoText')}</SidebarText>
            <StoresRow>
              <a
                href={window.config.mobileAppUrls.appstore}
                rel="noopener noreferrer"
                target="_blank"
              >
                <StoreImage
                  src="/appstore-badge.svg"
                  alt={formatMessage('appStore')}
                />
              </a>
              <a
                href={window.config.mobileAppUrls.googleplay}
                rel="noopener noreferrer"
                target="_blank"
              >
                <StoreImage
                  src="/googleplay-badge.png"
                  alt={formatMessage('playStore')}
                />
              </a>
            </StoresRow>
          </Sidebar>
          <Content>
            <Group>
              {isParent ? (
                <>
                  <ContactDetail
                    contactType="email"
                    contactValue={client.email}
                  />
                  <ContactDetail
                    contactType="phone"
                    contactValue={client.phone}
                  />
                </>
              ) : (
                <ContactDetail contactType="phone" contactValue={user.phone} />
              )}
              {client && client.permanentAddress && (
                <GroupItem
                  data-cy="permanent-address"
                  label={formatMessage('permanentAddress')}
                  value={client.permanentAddress.joined}
                />
              )}
              <CorrespondenceAddress />
            </Group>
            <ChangePassword />
            {isParent && (
              <ContactArea>
                <ContactUs />
              </ContactArea>
            )}
          </Content>
        </Container>
      </Layout>
    </Fragment>
  )
}

export default profileContainer(Profile)

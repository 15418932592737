export default {
  // general
  dateValue:
    '{date, date} {showTime, select, false{} other{at {date, time, short}}}',
  // backend errors
  ERR_LOCKED_OUT:
    'You have entered 3 invalid attempts. The login will be available after 5 minutes.',
  ERR_INVALID_DATA: 'Invalid data. Please check and try again.',
  ERR_INVALID_VS:
    "Variable symbol doesn't exist. Processing of new card may take up to 1 business day.",
  ERR_INVALID_VS_FORMAT: 'Invalid format of variable symbol',
  ERR_INVALID_USERNAME: 'Invalid format of user name.',
  ERR_DUPLICATE_RECORD: 'User already exists',
  ERR_INVALID_EMAIL: "Email doesn't match the variable symbol",
  ERR_INVALID_PHONE: "Mobile number doesn't match the variable symbol",
  ERR_INVALID_PASSWORD: 'Invalid password',
  ERR_INVALID_PASSWORD_FORMAT:
    'Password must contain at least 8 characters with no whitespaces and must include a digit, a lowercase and an uppercase character',
  ERR_EXPIRED: 'Activation code expired.',
  ERR_USER_ALREADY_ACTIVATED: 'This user is already activated.',
  ERR_CANT_INVITE_MYSELF: "You can't invite yourself.",
  ERR_INVITE_ALREADY_EXIST: 'Invitation for this contact already exists',
  ERR_RATELIMIT_EXCEEDED: 'SMS limit exceeded. Try again later.',
  ERR_CODE_ENTER_LIMIT_EXCEEDED:
    'Code retry limit exceeded. Request for a new code.',
  ERR_UNAUTHORIZED: 'You are not authorised.',
  // frontend errors
  ERR_INVALID_EMAIL_FORMAT: 'Invalid email format',
  ERR_INVALID_PHONE_FORMAT: 'Invalid mobile phone number format',
  ERR_REQUIRED_IS_MISSING: 'Mandatory fields missing',
  ERR_PASSWORDS_DONT_MATCH: "Entered passwords don't match",
  ERR_TEXT_IS_REQUIRED: 'Mandatory text',
  ERR_INVALID_IBAN_FORMAT: 'Invalid IBAN',
  ERR_INVALID_NATIONALITY_FORMAT: 'Invalid format of nationality',
  ERR_INVALID_BIRTH_NUMBER_FORMAT: 'Invalid ID',
  ERR_SAVING_OVER_MAX: "Saving can't be more than 1000 €",
  ERR_NAME_EMPTY: 'Name cannot be empty',
  ERR_VALUE_NOT_NEGATIVE: 'Value cannot be negative',
  ERR_VALUE_NOT_A_NUMBER: 'Value must be a number',
  ERR_SAVING_AMOUNT_EMPTY: 'Amount cannot be empty',
  ERR_SAVING_AMOUNT_NOT_ZERO: 'Amount cannot be zero',
  ERR_SAVING_OVERFLOW: 'Saving cannot overflow',
  ERR_SAVING_FUNDS_OVER_BALANCE: "Amount is bigger than the account's balance",
  ERR_CURRENCY_NUMBER_FORMAT: 'Number format is not correct',
  ERR_INVALID_PIN_CODE: 'Invalid PIN. Please try again.',
  ERR_TOO_MANY_PIN_ATTEMPTS: 'Too many PIN code attempts. Please log in again.',
  SERVER_ERROR: 'Server error',
  // operation types
  OP_TYPE_ATM: 'ATM',
  OP_TYPE_POS: 'POS',
  OP_TYPE_CASHLESS: 'POS',
  OP_TYPE_VOICE: 'MO/TO',
  OP_TYPE_ECOMMERCE: 'Ecommerce',
  OP_TYPE_DEFAULT: 'Other',
  // pin lock
  pinLockTitleCreate: 'Create a PIN',
  pinLockSubtitleCreate: 'Choose a PIN to unlock the app',
  pinLockTitleValidationFailed: 'Entered PIN is easy to guess',
  pinLockTitleConfirm: 'Repeat your PIN',
  pinLockTitleConfirmFailed: "Entered PINs don't match ",
  pinLockTitleEnter: 'Enter your PIN',
  pinLockTitleChange: 'Enter old PIN',
  scanYourFinger: 'Place your finger on the scanner.',
  scanFailedTryAgain: 'Scanning failed. Please try again.',
  scanFailedLockout: 'Too many attempts. Use your PIN.',
  youWillBeLogout: 'Press OK to log out.',
  allowBiometricsAlert:
    'Want to enable {biometricsType, select, face {face scan} fingerprint {fingerprint}} login?',
  biometryAttemptExpired:
    'Biometrics login is not available right now. Use your PIN.',
  youCanChangeThisInProfile:
    'You can change this setting later in your profile.',
  // other
  footer:
    '365.bank, a. s., with registered office at Dvořákovo nábrežie 4, 811 02 Bratislava | All rights reserved',
  accounts: 'Accounts',
  yourAccounts: 'Your accounts',
  noAccounts: 'No accounts in your profile.',
  noCards: 'No cards in your profile.',
  cardNeedsActivation: 'Card is not activated',
  cardBeingProduced: 'Card is being produced',
  EUR: '{amount, number, EUR}',
  amountFormat: '{amount, number}',
  topUpAccount: 'Account top-up',
  accountDetail: 'Account detail',
  transactions: 'Transactions',
  recentTransactions: 'Recent transactions',
  filterTransactions: 'Filtered transactions',
  transactionsFilter: 'Transaction filter',
  tasksFilter: 'Task filter',
  noTransactionsInSelectedFilter: 'No transactions in selected filter',
  loadingTransactions: 'Loading',
  errorLoadingTransactions: 'Loading error',
  transactionDetail: 'Transaction detail',
  transactionsType: 'Transaction type',
  transactionType: 'Transaction type',
  from: 'From',
  to: 'To',
  filter: 'Filter',
  changeFilter: 'Edit filter',
  date: 'Date',
  showResults: 'View results',
  resetFilter: 'Reset filter',
  notAllTransactionsShownSpecifyFilter:
    'Not all transactions are displayed. Please review the criteria.',
  reserved: 'Reserved',
  realized: 'Settled',
  seller: 'Merchant',
  detail: 'Detail',
  originalAmount: 'Original amount',
  conversionRate: 'Exchange rate',
  contactInformation: 'Contact details',
  changePassword: 'Change password',
  privacy: 'Personal',
  changeAppPin: 'Change the app’s PIN',
  signInViaBiometrics:
    '{biometricsType, select, fingerprint {Fingerprint} face {Face scan}} login',
  aboutApp: 'About the app',
  rateApp: 'Review us at {os, select, ios {App Store} android {Google Play}}',
  version: 'Version',
  reportTransaction: 'Report the transaction',
  reportOfTransaction: 'Claim the transaction',
  reportOfTransactionDesc:
    "If you didn't make this transaction, please report it using this form.",
  yourEmail: 'Your e-mail',
  optional: 'Optional',
  errorDesc: 'Error description',
  documents: 'Documents',
  businessConditions: 'Business terms and conditions',
  fees: 'Pricelist',
  dataProtection: 'Data protection',
  termsOfUseAndPrivacyPolicy: 'Terms of use and privacy policy',
  launchTutorial: 'Launch tutorial',
  appLanguage: 'App language',
  changeAppLanguage: 'Change app language',
  appSettings: 'App settings',
  signOut: 'Log out',
  signOutMobile: 'Log out',
  deactivate: 'Deactivate',
  deactivateAlertHeader: 'Deactivate',
  deactivateAlertDescription:
    'Do you really wish to deactivate your account on this device?',
  myProfile: 'My profile',
  profile: 'Profile',
  accountManagement: 'Account settings',
  cardsManagement: 'Card detail',
  bankStatements: 'Monthly statements',
  noBankStatements: 'No statements found',
  yearLabel: 'Year',
  selectYearLabel: 'Year {year}',
  bankStatementLabel: `{month, select,
    0 {January}
    1 {February}
    2 {March}
    3 {April}
    4 {May}
    5 {June}
    6 {July}
    7 {August}
    8 {September}
    9 {October}
    10 {November}
    11 {December}
  }/{year}`,
  guestsManagement: 'Invitations',
  accountSettings: 'Account settings',
  invitesWaiting: 'Pending invites',
  noGuests: 'No friends invited yet.',
  inviteChild: 'Invite child',
  sendInvite: 'Send invite',
  smartieWebLink: 'on our web',
  invitePhoneLabel: "Child's phone number",
  inviteFormCardIssueNotice:
    'Before you add another child to SMARTIE, you need to apply for the issuance of his/her own card ',
  inviteFormCardIssueNewCardNotice:
    'New card will appear automatically in My children section.',
  inviteFormDescriptionMobile:
    'We will send an invitation to SMARTIE by text message to your child at this phone number. He/she must confirm it.',
  inviteFormNoticeTitle: 'Your child will have the right:',
  inviteFormNoticeFirst: 'to block the card',
  inviteFormNoticeSecond: 'to view all transactions in his/her account',
  inviteFormNoticeThird: 'to view the tasks assigned',
  cardPanMasked: '•••• {pan}',
  cardLimitMaxValue: '{label} (Max.value: {maxValue})',
  max: 'Max {maxValue} €',
  cardExpiration: 'VALID THRU',
  addToMyCards: 'Add to my cards',
  setLimits: 'Limit settings',
  limits: 'Limits',
  atmWithdrawal: 'Withdrawal from ATM',
  atmWithdrawalDayLimit: 'ATM limit',
  allowATMWithdrawal: 'Permit withdrawal from ATM',
  dayLimit: 'Monthly limit',
  cashlessPayments: 'Non-cash payments',
  cashlessPaymentsDayLimit: 'Non-cash limit',
  allowCashlessPayments: 'Permit non-cash payments',
  contactlessPayments: 'Contactless payments',
  allowContactlessPayments: 'Permit contactless payments',
  internetPayments: 'ECOM payments',
  internetPaymentDayLimit: 'ECOM limit',
  allowInternetPayments: 'Enable ECOM payments',
  reprintPin: 'PIN reprint',
  reprintPinDescription:
    'If you forget your PIN, you can request to reprint it. The PIN will be sent to you by post in 5 business days.',
  reprintPinModalTitle: 'PIN reprint',
  reprintPinModalInfo: 'Send PIN by post within 5 working days..',
  reprintPinAddressLabel: 'Send PIN to address:',
  reprintPinSubmitButton: 'PIN reprint',
  reissueCard: 'Card reissue',
  reissueCardDescription:
    'Your card will be blocked on issuing a replacement card.',
  reissueCardModalTitle: 'New card issue',
  reissueCardModalAlert:
    'After confirming the request, the original card will no longer be valid.',
  issueNewCard: 'Issue new card',
  reissueCardAddressLabel: 'Send card to address:',
  reissueCardReasonLabel: 'Reason for card issue',
  chooseReason: 'Select reason',
  choose: 'Select',
  lossOrTheft: 'Loss or theft',
  damage: 'Damage',
  lostOrStolenCardInfo:
    'Select this option, if you lost your card, it was stolen or you cannot find it. New card and PIN will be sent to you by post in 5 business days.',
  damagedCardInfo:
    'Select this option, if your card is broken or chipped. New card and PIN will be sent to you by post in 5 business days.',
  requestMoney: 'Transfer of money to account',
  requestMoneyDescription:
    'You can request funds transfer to your bank account here. Transfers are supported to SEPA countries only!',
  requestMoneyModalTitle: 'Money transfer application',
  requestMoneyConfirmation: 'Confirmation of request for funds transfer',
  submitRequestMoney: 'Submit',
  yourIban: 'Your IBAN',
  placeholderIban: 'SK09 1287 1234 1245 9812 7654',
  requestMoneyModalDescription:
    'Did you send more money than you needed? Insert the amount you want to return and we will return it to your 365 account. Your money will be on your account in 3 days.',
  accountInformation: 'Client information',
  addresses: 'Addresses',
  myCard: 'My card',
  myCards: 'My cards',
  otherCards: 'Other cards',
  invitationSentOn:
    'Invitation sent on {invitationDate, date} at {invitationDate, time, short}',
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  permanentAddress: 'Permanent residence address',
  correspondenceAddress: 'Correspondence address',
  availableBalance: 'Available balance',
  iban: 'IBAN of the Pool account',
  variableSymbol: 'Variable symbol',
  variableSymbolWithAbbreviation: 'Variable symbol (VS)',
  variableSymbolAbbreviation: 'VS',
  topupAccountDescription:
    "You can top up your child's card by wire transfer to his/her account. Be sure to provide the correct variable symbol.",
  topupAccountRegularDescription:
    'If you plan to send him/her pocket money regularly, you can set up a standing order from your account.',
  topupAccountTitle: 'Card top-up',
  sendInvitationAgain: 'Send again',
  saveChanges: 'Save',
  contactInfoValidation: 'Contact detail validation',
  verificationCode: 'Verification code',
  submit: 'Submit',
  delete: 'Delete',
  cancel: 'Cancel',
  copy: 'Copy',
  signUp: 'Activate SMARTIE',
  signUpAction: 'Activate',
  signUpHeader: 'Activate SMARTIE',
  signIn: 'Log in',
  signInLong: 'Do you already have a SMARTIE account? Log in.',
  emailOrPhone: 'Email (parent) or mobile phone number (child)',
  yourPhoneNumber: 'Your phone number',
  minimalLength8Symbols: 'Minimum length of 8 characters',
  password: 'Password',
  createPassword: 'Create password',
  forgotPassword: 'Reset your password',
  forgotPinCode: 'Forgot PIN?',
  dontHaveAnAccountYet: "Don't have an account yet?",
  dontHaveCardYet: "Don't have an SMARTIE card yet?",
  fillOnlineApplication: 'Just fill-in the online application',
  variableSymbolOfAccount: 'Variable SMARTIE card symbol',
  tenDigitContractNumber: '10-digit number',
  email: 'Email in 365 bank',
  phone: 'Mobile phone',
  mobilePhoneNumberOfChild: 'Mobile phone number of child',
  placeholderContactValue:
    '{contactType, select, email {meno@mail.sk} phone {+421 903 123 456}}',
  enterPasswordForCheck: 'Re-enter the password to verify',
  repeatPassword: 'Repeat password',
  iAgreeToProcessingOfPersonalData: 'I agree ',
  iAgreeToProcessingOfPersonalDataLinkText:
    'with the method of personal data processing and protection at 365.bank.',
  resendCode: 'Resend code',
  codeResentSuccessfully: 'Code successfully resent',
  codeResentSuccessfullyWithHelp:
    'Code successfully resent. If you have any problems, contact us.',
  contactCustomerService: 'Contact us',
  inviteResentSuccessfully: 'Invite successfully resent',
  signUpUsingDataFromYourContract: `Activate SMARTIE using your {activationType, select,
      email {e-mail in 365 bank}
      phone {phone number}
      other {e-mail in 365 bank (parent) or phone number (child)}
    } and a variable card symbol.`,
  whereCanIFindTheData: 'Where can I find this information?',
  registrationTitle: 'Sign up for SMARTIE app',
  loginTitle: 'Log in to SMARTIE app',
  aboutAppTitle1: 'Welcome to SMARTIE app',
  aboutAppText1:
    'Manage your SMARTIE prepaid card easily from your cell phone.',
  aboutAppTitle2: 'Control your spending',
  aboutAppText2:
    'Your funds are secured due to online change of limits and possibility to block the card.',
  aboutAppTitle3: 'Transactions overview',
  aboutAppText3: 'View your transactions for better control of your spending.',
  aboutAppTitle11: 'Vitaj vo svete,',
  aboutAppTitle12: 'kde vreckové',
  aboutAppTitle13: 'už neštrngá',
  aboutAppText11: 'Poďme spolu zažiť\n',
  aboutAppText12: 'veľké veci!',
  aboutAppTitle21: 'Tvoj poklad je',
  aboutAppTitle22: 'vždy v bezpečí',
  aboutAppText21: 'PIN a kartu si ustráž,\n',
  aboutAppText22:
    'ale nič nieje stratené, ani keď ich\nstratíš. Kartu si hneď zablokuj, aby\nju nikto nemohol zneužiť',
  aboutAppTitle31: 'Odteraz môžeš',
  aboutAppTitle32: 'rátať s nami',
  aboutAppText31: 'Odkiaľ sa berú a kam miznú?\nMaj ',
  aboutAppText32: 'prehľad o svojich peniazoch\n',
  aboutAppText33: 'kedykoľvek priamo v mobile.',
  backToMainPage: 'Back to main page',
  mainPageUrl: 'https://smartie.sk/',
  mobilePhoneNumber: 'Mobile phone number',
  VSHintTitle: 'Where can I find activation data for SMARTIE?',
  VSHintDescription: 'You will need 2 basic information:',
  VSHintDescriptionBullet1: `{userType, select,
      parent {a variable symbol of a card which you can find on the back side of the SMARTIE card. If you own more than 1 card, you can use any of them. If you want a new card, please visit }
      child {a variable symbol of a card which you can find on the back side of the SMARTIE card or in SMS invitation.}
      other {a variable symbol of a card which you can find on the back side of the SMARTIE card. If you own more than 1 card, you can use any of them. If you want a new card, please visit }
    }`,
  VSHintDescriptionBullet2: `{userType, select,
      parent {Your e-mail in 365 bank.}
      child {Your phone number.}
      other {Your e-mail in 365 bank (parent) or your phone number (child).}
    }`,
  VSHintDescriptionBullet3: `Also, don't forget to create your password.`,
  registrationVSLabel: 'Variable symbol (VS - 10-digit number from your card)',
  account: 'Account',
  card: 'Card',
  cards: 'Cards',
  period: 'Period',
  filetype: '{extension, select, pdf {PDF} zip {ZIP}}',
  limitMustBeANumber: 'Limit must be a number',
  limitMustBeAWholeNumber: 'Limit must be a whole number',
  limitMustBeUpTo: 'Limit must be up to {maxFieldValue}',
  limitMustBePositive: 'Limit must be a positive number',
  mobileApp: 'SMARTIE in your cell phone',
  mobileAppPromoText:
    'Carry the tool to manage your cards always in your pocket.',
  contactUs: 'Contact us',
  contactUsDescription:
    'Do you have any comments, questions or ideas for improvement? Send them in, we want to know :)',
  contactUsSuccess: 'Your message was sent',
  yourMessage: 'Your message for us',
  appStore: 'App Store',
  playStore: 'Play Store',
  editCorrAddressConfirmation: 'Confirm the correspondence address',
  editContactInfo: 'Edit contact info',
  editContactInfoDescription:
    'You can edit your contact information only in the mobile application 365.bank.',
  unknownErrorTitle: 'Unknown error',
  unknownErrorDescription: 'Unknown error. Please try again later.',
  networkErrorTitle: 'Network error',
  networkErrorDescription:
    'Network error. Please check your internet connection.',
  staleDataErrorTitle: 'Application data out of date',
  staleDataErrorDescription:
    'There was an error while editing out-of-date data.',
  usernameChangedWarningTitle: 'Login e-mail was updated',
  usernameChangedWarningDescription:
    'Your login e-mail for SMARTIE app was updated to {email}. If this activity looks suspicious, please let us know on our e-mail hello@365.bank or phone 0850 365 365.',
  reloadPage: 'Reload page',
  credit: 'Credit',
  debit: 'Debit',
  creditShort: 'Credit',
  debitShort: 'Debit',
  forgotPasswordTitle: 'Reset your password',
  forgotPasswordDescription:
    'Please enter your registered contact detail - email or mobile number, to reset your password.',
  contactUsedForRegistration: 'Contact detail',
  continue: 'Continue',
  pleaseEnterVerificationCode:
    'Please enter the verification code sent to your {contactType, select, email {email} phone {mobile number}}.',
  pleaseEnterRegistrationVerificationCode:
    '{contactType, select, email {Please complete your registration by tapping on link or enter verification code sent to your e-mail.} phone {Please complete your registration by tapping on link or enter verification code sent to your mobile number.}}',
  enterVerificationCode: 'Enter verification code',
  verificationCodeFromContact:
    'Verification code from {contactType, select, email {email} phone {SMS}}',
  identitySuccessfullyConfirmed:
    'Your identity successfully verified. You can create new password now.',
  yourPassword: 'Your password',
  signInWithNewPassword: 'Log in with new password',
  changePasswordConfirmation: 'Password change confirmation',
  oldPassword: 'Old password',
  newPassword: 'New password',
  newPasswordRepeat: 'Re-enter new password',
  noMyCard:
    'You have no active cards. To activate your card please top up the card.',
  activateCardBeforeInviting:
    'Invitations are not possible for inactive cards. To activate your card please top up the card.',
  confirmDialogLimit: 'Confirm change of limit?',
  confirmDialogBlocked:
    'Are you sure you want to {value, select, true {block} false {unblock}} your card?',
  confirmDialogDeleteInvite: 'Confirm delete of invitation?',
  confirmDialogDeleteInviteDescription:
    'This will also delete created savings, gained app features, quiz results and SMARTIE points.',
  unsupportedAppVersionTitle: 'Unsupported version of the app',
  unsupportedAppVersionDescription: 'Please update your app to newest version',
  amount: 'Amount',
  cardStatus: `{status, select,
      requested {Requested}
      generated {Generated}
      inProduction {In production}
      inactive {Inactive}
      active {Active}
      closed {Closed}
      expired {Expired}
      incorrectProduction {Incorrect production}
      adminSuspended {Blocked by issuer}
      blocked {Blocked}
      reissued {Reissued}
      other {}}`,
  toUnblockContactUsOn: 'Ask your parent to unblock your card',
  block: 'Block',
  unblock: 'Unblock',
  statusOfCard: 'Card status',
  cardProductDescription:
    '{cardProduct, select, maestro {Maestro} mastercard {Mastercard} other {Unknown}} prepaid card',
  mandatory: 'Mandatory',
  allowed: 'Allowed',
  notAllowed: 'Not allowed',
  requestAccepted: 'Your request was accepted',
  requestSentModalTitle: 'Request sent',
  close: 'Close',
  loadingData: 'Loading data',
  loadingCards: 'Loading cards',
  ibanCopiedSuccess: 'IBAN copied',
  vsCopiedSuccess: 'Variable symbol copied',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  refresh: 'Refresh',
  saving: 'Saving',
  savingWithdraw: 'Saving withdrawal',
  newSavingNameTitle: 'What do you want to start saving money for?',
  newSaving: 'How much money do you want to save?',
  newSavingMaxAmount: 'Max. {amount,number} €',
  createSaving: 'Create savings',
  chooseSavingAvatar: 'Choose avatar',
  addToSavingTitle: 'How much money do you want to transfer to savings?',
  addToSavingButton: 'Transfer to savings',
  newSavingName: 'Name your savings',
  targetAmount: 'Enter the amount',
  tasks: 'Tasks',
  myTasks: 'My tasks',
  assignedTasks: 'Assigned tasks',
  newTask: 'New task',
  createNewTask: 'Add new task',
  enterTaskDescription: 'Enter task',
  resolveTask: 'Resolved',
  failTask: 'Failed',
  deleteTask: 'Delete task',
  taskHistory: 'Task history',
  completeHistory: 'Complete history',
  taskFilter: 'Filter',
  taskType: 'Task type',
  resolvedStatus: 'Resolved',
  failedStatus: 'Failed',
  child: 'Child',
  iAmChild: 'I am a child',
  duplicateTask: 'Duplicate',
  emptyName: 'Name field cannot be empty',
  filterFundsAdded: 'Top-up',
  filterCharges: 'Charges',
  blockCardAlertHeader: 'Block this card?',
  blockCardAlertDescription: 'You will have to ask your parent to unblock it',
  childCardBlockedMessage: 'Ask your parent to unblock your card',
  childCardNotBlockedMessage:
    'You can block your card in the event of loss, damage or theft. But remember that only your parent can unblock it.',
  countryName: `{countryCode, select,
      SK {Slovakia}
      CZ {Czechia}
      BE {Belgium}
      BG {Bulgaria}
      HR {Croatia}
      CY {Cyprus}
      DK {Denmark}
      EE {Estonia}
      FI {Finland}
      FR {France}
      GR {Greece}
      NL {Netherlands}
      IE {Ireland}
      LT {Lithuania}
      LV {Latvia}
      LU {Luxembourg}
      HU {Hungary}
      MT {Malta}
      DE {Germany}
      PL {Poland}
      PT {Portugal}
      AT {Austria}
      RO {Romania}
      SI {Slovenia}
      ES {Spain}
      SE {Sweden}
      IT {Italy}
      GB {Great Britain}
      UA {Ukraine}
      other {}
    }`,
  otherCountry: 'Other',
  // TODO: change when we recieve proper en texts
  createSavingLabel: 'Add your own goal and start saving money for anything',
  addSaving: 'Add your savings',
  addToSaving: 'Save',
  edit: 'Edit',
  savingHistory: 'Savings history',
  editSavingTitle: 'Edit Savings',
  editSavingName: 'Change name',
  editSavingAvatar: 'Change picture',
  editAvatarDescription:
    'Choose from our images or upload your own - it’s up to you :)',
  removeSaving: 'Cancel savings and transfer money to card',
  accountOverviewWelcome: 'Hi {name}, you have on your card',
  accountOverviewWelcomeEmptyName: 'Hi, money on your card',
  savingLabel: 'Savings',
  savedLabel: 'Your savings',
  together: 'Total',
  total: 'Total',
  overviewTabLabel: 'Overview',
  myChildren: 'My children',
  addChild: 'Add another child',
  cardDetail: 'Card detail',
  transactionHistory: 'Transaction history',
  childName: "Enter your child's name",
  childNameUsed: 'Name of your child using this card',
  topUpCard: 'Top up card',
  noTransactionsLabel: 'There are no transactions on your card yet',
  nicknameLabel: 'Set name or nickname',
  nicknameScreenTitle: 'How do you want us to call you?',
  nicknameSetLater:
    'You can also write your name or nickname later in the profile.',
  changeNickname: 'Change nickname',
  parent: 'Parent',
  iAmParent: 'I am a parent',
  deleteAndInviteAgain: 'Delete and invite a different number',
  skip: 'Skip',
  pickFromCameraRoll: 'Open gallery',
  launchCamera: 'Take a photo',
  avatarImagePickError: 'There was an error during an image processing.',
  missingPermissions: 'Missing settings',
  missingCameraPermissionsError:
    'For picking your avatar you will need to enable settings for {type, select, cameraRoll {camera roll} camera {camera}}.  {os, select, ios {Open settings?} android {}}',
  avatarImageFileSizeError: 'Image is too big. Try to pick a smaller one.',
  wrongPinCode: 'Wrong PIN code',
  changeAgeScreenTitle: 'What is your age?',
  ageLabel: 'Age',
  changeAgeInfo: 'Your age will help us to better set quiz difficulty.',
  changeAge: 'Change age',
  setNicknameAndAgeLinkLabel: 'Set nickname and your age',
  setNicknameLinkLabel: 'Set your name or nickname',
  setAgeLinkLabel: 'Set age',
  financialKnowledgeTotalQuizzesTextBefore: 'You have completed ',
  financialKnowledgeTotalQuizzesTextAfter:
    ' {count, plural, =1 {quiz} other {quizzes}}',
  financialKnowledgeTotalAnswersTextBefore: 'You had ',
  financialKnowledgeTotalAnswersTextAfter:
    ' {count, plural, =1 {correct answer} other {correct answers}}',
  financialKnowledgeUnlockedThemesTextBefore: 'You have unlocked ',
  financialKnowledgeUnlockedThemesTextAfter:
    ' {count, plural, =1 {custom theme} other {custom themes}}',
  takeTest: 'Test your knowledge',
  takeTestLevel: 'Run Level {level}',
  currentSmartiePointsTopText: 'Currently you have',
  currentSmartiePointsBottomText:
    '{count, select, 1 {SMARTIE point} other {SMARTIE points}}',
  yourFinancialKnowledgeScore: 'Your score in \nfinancial literacy',
  shareWithFriends: 'Share with friends',
  month: `{month, select,
    1 {January}
    2 {February}
    3 {March}
    4 {April}
    5 {May}
    6 {June}
    7 {July}
    8 {August}
    9 {September}
    10 {October}
    11 {November}
    12 {December}
  }`,
  selectQuizDifficultyTitle: 'Choose your difficulty',
  quizLevel: 'LEVEL {level}',
  quizDifficulty: '{level, select, 1 {Easy} 2 {Medium} 3 {Hard}}',
  quizRewardsTitle: 'What can I gain in LEVEL {level}?',
  quizRewardsInfo: `{level, select,
    2 {Each checkpoint in LEVEL 2 will unlock a unique pattern, which you can use in your theme. To unlock LEVEL 2, complete LEVEL 1 succesfully}
    3 {Each checkpoint in LEVEL 3 will unlock a unique drawing, which you can use in your theme. To unlock LEVEL 3, complete LEVEL 2 succesfully}}`,
  next: 'Next',
  quizRewardsInfoResults: `{level, select,
    2 {Each checkpoint in quiz will unlock a unique pattern, which you can use in your theme.}
    3 {Each checkpoint in quiz 3 will unlock a unique drawing, which you can use in your theme.}}`,
  quizTitle: '{level, select, 1 {Easy} 2 {Medium} 3 {Hard}} {index}/5',
  nextQuestion: 'Next question',
  goToResults: 'Results',
  answerCorrect: 'Correct. ',
  answerIncorrect: 'Wrong. ',
  pointsGainedTextBefore: "You've gained ",
  pointsGainedTextMiddle:
    '{points, select, 1 {1 SMARTIE point} 2 {2 SMARTIE points} other {3 SMARTIE points}}',
  pointsGainedTextAfter:
    '{correctAnswers, select, 1 { and unlocked a first checkpoint.} 3 { and unlocked a second checkpoint.} 5 { and unlocked a third checkpoint.} other { .}}',
  quizResultTitle:
    '{checkpoint, select, 0 {Nevermind} 1 {Good} 2 {Very Good} 3 {You have completed level {level}!}}',
  quizResultHeaderText: `{checkpoint, select,
      0 {{level, select, 1 {You haven't unlocked any color} 2 {You haven't unlocked any pattern} 3 {You haven't unlocked any drawing}}}
      1 {{level, select, 1 {You have unlocked 1 out of 3 colors} 2 {You have unlocked 1 out of 3 patterns} 3 {You have unlocked 1 out of 3 drawings}}}
      2 {{level, select, 1 {You have unlocked 2 out of 3 colors} 2 {You have unlocked 2 out of 3 patterns} 3 {You have unlocked 2 out of 3 drawings}}}
      3 {{level, select, 1 {You have unlocked 3 out of 3 colors} 2 {You have unlocked 3 out of 3 patterns} 3 {You have unlocked 3 out of 3 drawings}}}}`,
  quizResultBottomText: `{checkpoint, select, 0 {Nevermind, let's try again and perhaps next time you will do better. Correct answers unlock rewards and earn you SMARTIE points, which you can use to buy more extensions of the app.}
3 {In the next level you will have even better options of customizing your app.} other {Try answering all questions in the quiz correctly and unlock next difficulty with even better rewards.}}`,
  quizResultCorrectQuestionsAndReward: `{checkpoint, select,
      1 {You have answered correctly to {correctAnswers, plural, =1 {1 question} other {2 questions}}. Choose your reward.}
      2 {You have answered correctly to {correctAnswers} questions. You can choose from two {level, select, 1 {colors} 2 {patterns} 3 {drawings}}. Pick the one you want to have on your background.}
      3 {Choose your reward. You can choose from three {level, select, 1 {colors} 2 {patterns} 3 {drawings}}. Pick the one you want to have on your background.}
  }`,
  quizResultMotivationText: `{checkpoint, select,
      0 {Nevermind, let's try again and perhaps next time you will do better. Correct answers unlock rewards and earn you SMARTIE points, which you can use to buy more extensions of the app.}
      1 {Try answering all questions in the quiz correctly and unlock next difficulty with even better rewards.}
      2 {Try answering all questions in the quiz correctly and unlock next difficulty with even better rewards.}
      3 {In the next level you will have even better options of customizing your app.}
  }`,
  chooseTheme: 'Choose',
  themeChosen: 'Chosen',
  quizResultBottomLink: `{checkpoint, select,
      1 {Repeat quiz}
      2 {Repeat quiz}
      3 {Continue to level {level}}
  }`,
  quizResultRewardNotChosenAlertTitle: "You haven't chosen your reward",
  quizResultRewardNotChosenAlertMessage:
    'Do you really want to leave quiz without choosing and setting one of the themes you have won?',
  quizResultRewardNotChosenAlertLeaveButton: 'Leave',
  quizResultRewardNotChosenAlertStayButton: 'Stay',
  quizCompletedBottomText:
    'Repeat the quiz and gain SMARTIE points with every correct answer to buy new great extensions for your app.',
  repeatQuiz: 'Repeat quiz',
  share: 'Share with friends',
  themeStore: 'Store',
  themeStoreAvatar: 'Avatar',
  themeStoreFrame: 'Frame',
  themeStoreTheme: 'Theme',
  youHave: 'You have',
  smartiePointsShortcut: ' {points} points',
  purchaseFeature: 'Buy for {price} points',
  seasonalThemesInfo: 'Seasonal themes are only available for a limited time.',
  themeStoreAvatarTopText: `Buy one of our pictures and make your profile or saving's goal unique :)`,
  themeStoreFrameTopText: `Buy one of our frames and make your profile unique :)`,
  categoryDescriptionProfileAvatar: 'Profile avatar',
  categoryDescriptionSavingsAvatar: 'Savings avatar',
  categoryDescriptionFrame: 'Avatar frame',
  categoryDescriptionTheme: 'Theme',
  purchaseProfileAvatarInfo: `Avatar will automatically appear in your gallery after purchase. Go to "Edit profile picture" and you can set it there.`,
  purchaseSavingsAvatarInfo: `Avatar will automatically appear in your gallery after purchase. Go to "Edit saving target" and you can set it there.`,
  purchaseFrameInfo: `Frame will automatically appear in your gallery after purchase. Go to "Edit profile picture" and you can set it there.`,
  purchaseSeasonalThemeInfo: `Seasonal theme will automatically appear in your gallery, you can set it in your profile.`,
  featureAvailabilityInfo: 'You can buy this feature ',
  featureAvailabilityDate: 'until {date}.',
  notEnoughPointsTitle: `You don't have enough SMARTIE points`,
  notEnoughPointsText: `You don't have enough SMARTIE points to purchase the reward. Complete quiz and gain points for each correct answer.`,
  colorNotUnlockedTitle: `You haven't unlocked the theme color yet.`,
  colorNotUnlockedText: `You haven't unlocked the theme color yet. Complete quiz and unlock more colors for each completed Level 1.`,
  quiz: 'Quiz',
  leave: 'Leave',
  chooseAppTheme: 'Choose app theme',
  set: 'Set',
  currentlySet: 'Currently set',
  changeAvatarImageTabHeading: 'Change image',
  changeAvatarFrameTabHeading: 'Avatar frame',
  changeAvatarFrameTabText:
    'Choose a frame that fits you most. Others are available for "purchase" in our store :)',
  defaultAvatarFrameTileText: 'Smartie',
  featureStoreLinkText: 'Buy more frames',
  noPurchasedThemeInfo: 'You have no purchased themes.',
  noThemeYetInfoMessage: "There's nothing in here right now. Try again later.",
  noCardTapHere: "If you don't have a Smartie card yet, tap HERE",
  requestSmartieCard: 'Request a new SMARTIE card'
}

import React from 'react'
import MasterCardLogo from './MasterCardLogo'
import MaestroLogo from './MaestroLogo'
import AhojLogo from './AhojLogo'

const CardLogo = ({cardProduct, cardOrganization}) => {
  if (cardOrganization === 'ahoj') return <AhojLogo />
  else if (cardProduct === 'mastercard') return <MasterCardLogo />
  else if (cardProduct === 'maestro') return <MaestroLogo />
  else return null
}

export default CardLogo

import thunk from 'redux-thunk'
import {createStore, applyMiddleware} from 'redux'
import {createLogger} from 'redux-logger'
import {compact} from 'lodash'

import rootReducer from './rootReducer'
import getInitialState from './state'

const loggerMiddleware = createLogger({collapsed: true})
const middlewares = compact([
  thunk,
  process.env.NODE_ENV === 'development' && loggerMiddleware
])
const store = createStore(
  rootReducer,
  getInitialState(),
  applyMiddleware(...middlewares)
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

import React from 'react'
import styled from 'styled-components'

import Text from './Text'
import {formatMessage} from '../../i18n'

const Field = styled.div`
  display: block;
  margin-bottom: ${props => (props.withoutSpacing ? '8px' : '24px')};
  position: relative;
`

const Label = styled.div`
  padding: 0 0 0.5em 0.5em;
`

// NOTE: Type is defined because this component is in form that submits
// on button clicks.
const ToggleButtonItem = styled.button.attrs({type: 'button'})`
  background-color: ${props =>
    props.active ? props.theme.button.colors.secondary.normal : '#fff'}
  color: ${props =>
    props.active ? '#fff' : props.theme.button.colors.secondary.normal}
  border: 1px solid ${props => props.theme.button.colors.secondary.normal};
  height: 56px;
  flex: 1;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.25s;
  text-transform: uppercase;
  margin: 0;

  &:hover {
    background-color: ${props => props.theme.button.colors.secondary.hover};
    color: #fff;
  }

  &:first-child {
    border-radius: 2px 0 0 2px;
  }

  &:last-child {
    border-radius: 0 2px 2px 0;
  }
`

const StyledToggleButton = styled.div`
  display: flex;
  width: 100%;
`

const ToggleButton = ({
  items,
  onClick,
  activeName,
  label,
  required,
  withoutSpacing
}) => (
  <Field withoutSpacing={withoutSpacing}>
    {label && (
      <Label>
        <Text type="secondary">{label}</Text>
        {required && (
          <Text type="secondary">{` (${formatMessage('mandatory')})`}</Text>
        )}
      </Label>
    )}
    <StyledToggleButton>
      {items.map(({title, name, 'data-cy': datacy}, index) => (
        <ToggleButtonItem
          data-cy={datacy}
          key={index}
          onClick={() => {
            onClick(name)
          }}
          active={name === activeName}
        >
          {title}
        </ToggleButtonItem>
      ))}
    </StyledToggleButton>
  </Field>
)

export default ToggleButton

import React from 'react'
import {formatMessage} from '../../i18n'
import {GroupItem} from '../ui'

const ContactDetail = ({contactType, contactValue}) => (
  <GroupItem
    data-cy={contactType}
    label={formatMessage(contactType)}
    value={contactValue}
  />
)

export default ContactDetail

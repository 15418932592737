let storageData = {}
let _storageGetData
let _storageSetData

export const initStorage = (storageGetData, storageSetData) => {
  _storageGetData = storageGetData
  _storageSetData = storageSetData
}

const pullData = async () => {
  try {
    storageData = JSON.parse((await _storageGetData()) || '{}')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Pulling data from storage failed', e)
  }
}

const setItem = async (key, value) => {
  try {
    storageData[key] = value
    const newData = JSON.stringify(storageData)
    await _storageSetData(newData)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Saving data to storage failed')
  }
}

const removeItem = key => setItem(key, undefined)

const getItem = key => {
  return storageData ? storageData[key] : undefined
}

// Clear storage data, preserve locale
const clearStorage = async () => {
  try {
    storageData = {locale: storageData.locale}
    const newData = JSON.stringify(storageData)
    await _storageSetData(newData)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Clearing storage data failed')
  }
}

export default {
  pullData,
  setItem,
  removeItem,
  getItem,
  clearStorage
}

import {SET_VALUE} from '../commonActions'
import {apiRequest} from '../apiRequest'
import handleApiError from '../handleApiError'
import {formatMessage} from '../../i18n'
import {showConfirmationDialog} from '../../confirmationDialog'
const PATH = ['cards']

const startEditingField = (cardId, fieldName) => (dispatch, getState) => {
  const card = getState().cards[cardId]

  if (!card.settings) return
  if (card.settingsLoading) return

  const currentFieldValue = String(card.settings[fieldName] || 0)
  dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldName'], fieldName))
  dispatch(
    SET_VALUE([...PATH, cardId, 'settingsEditingFieldValue'], currentFieldValue)
  )
  dispatch(SET_VALUE([...PATH, cardId, 'settingsError'], null))
}

const editFieldValue = (cardId, fieldName, fieldValue) => (
  dispatch,
  getState
) => {
  const card = getState().cards[cardId]

  if (!card.settings) return
  if (card.settingsLoading) return

  if (card.settingsEditingFieldName !== fieldName) return
  dispatch(
    SET_VALUE([...PATH, cardId, 'settingsEditingFieldValue'], fieldValue)
  )
  dispatch(SET_VALUE([...PATH, cardId, 'settingsError'], null))
}

const stopEditingField = (cardId, fieldName) => (dispatch, getState) => {
  const card = getState().cards[cardId]

  if (!card.settings) return
  if (card.settingsLoading) return

  dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldName'], null))
  dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldValue'], null))
  dispatch(SET_VALUE([...PATH, cardId, 'settingsError'], null))
}

const setError = (cardId, fieldName, errorText, errorData) =>
  SET_VALUE([...PATH, cardId, 'settingsError'], {
    fieldName,
    errorText,
    errorData
  })

const submitEditableField = (cardId, fieldName) => async (
  dispatch,
  getState
) => {
  const card = getState().cards[cardId]

  if (!card.settings) return
  if (card.settingsLoading) return

  const newFieldValue = card.settingsEditingFieldValue

  // Frontend validation
  const intFieldValue = Number(newFieldValue)
  if (isNaN(intFieldValue) || newFieldValue === '') {
    dispatch(setError(cardId, fieldName, 'limitMustBeANumber'))
    return
  }
  if (!Number.isInteger(intFieldValue)) {
    dispatch(setError(cardId, fieldName, 'limitMustBeAWholeNumber'))
    return
  }
  const maxFieldValue = window.config.cardLimitsMaxValues[fieldName]
  if (intFieldValue > maxFieldValue) {
    dispatch(setError(cardId, fieldName, 'limitMustBeUpTo', {maxFieldValue}))
    return
  }
  if (intFieldValue < 0) {
    dispatch(setError(cardId, fieldName, 'limitMustBePositive'))
    return
  }
  // End of frontend validation
  if (card.settings[fieldName] === intFieldValue) {
    dispatch(stopEditingField(cardId, fieldName))
    return
  }

  if (!(await showConfirmationDialog(formatMessage('confirmDialogLimit'))))
    return

  dispatch(SET_VALUE([...PATH, cardId, 'settingsLoading'], fieldName))

  try {
    const accountId = card.accountId
    await apiRequest(`/cards/${cardId}/update`, {
      method: 'POST',
      body: {accountId, fields: {[fieldName]: intFieldValue}}
    })
    dispatch(SET_VALUE([...PATH, cardId, 'settings', fieldName], intFieldValue))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldName'], null))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldValue'], null))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsError'], null))
  } catch (e) {
    await dispatch(handleApiError(e))
  } finally {
    dispatch(SET_VALUE([...PATH, cardId, 'settingsLoading'], null))
  }
}

const submitToggleableField = (cardId, fieldName) => async (
  dispatch,
  getState
) => {
  const card = getState().cards[cardId]

  if (!card.settings) return
  if (card.settingsLoading) return

  const newFieldValue = !card.settings[fieldName]

  dispatch(SET_VALUE([...PATH, cardId, 'settingsLoading'], fieldName))

  try {
    const accountId = card.accountId
    await apiRequest(`/cards/${cardId}/update`, {
      method: 'POST',
      body: {accountId, fields: {[fieldName]: newFieldValue}}
    })
    // if the card is being blocked/unblocked, we need to change status to display CardTab properly
    if (fieldName === 'blocked') {
      // 'blocked' and 'active' are the only statuses which user can change
      dispatch(
        SET_VALUE(
          [...PATH, cardId, 'status'],
          newFieldValue ? 'blocked' : 'active'
        )
      )
    }
    dispatch(SET_VALUE([...PATH, cardId, 'settings', fieldName], newFieldValue))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldName'], null))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsEditingFieldValue'], null))
    dispatch(SET_VALUE([...PATH, cardId, 'settingsError'], null))
    // loading is reseted in finaly
  } catch (e) {
    await dispatch(handleApiError(e))
  } finally {
    dispatch(SET_VALUE([...PATH, cardId, 'settingsLoading'], null))
  }
}

export const actions = {
  // this actions are for number settings
  startEditingField,
  editFieldValue,
  stopEditingField,
  submitEditableField,
  // this action are for boolean settings
  submitToggleableField
}

import styled from 'styled-components'

import Text from './Text'

const SectionTitle = styled(Text).attrs({type: 'secondary', small: false})`
  padding: 0 8px 16px;
  margin-top: 36px;
  display: block;

  &:first-child {
    margin-top: 0;
  }
`

export default SectionTitle

import React from 'react'
import styled from 'styled-components'

import Link from './Link'
import {getCurrentLocale, languages} from '../../i18n'
import storage from '../../utils/storage'

const Container = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`

const LanguageLink = styled(Link)`
  color: ${({active}) => (active ? '#45DBCC' : '#004155')};
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0;
  align-self: stretch;
  position: relative;
  transition: color 0.25s;
  font-family: 'Sofia Pro', sans-serif;

  &:hover {
    color: #45dbcc;
  }
  :not(:last-child) {
    &::after {
      content: '/';
      margin: 0 10px;
      color: #004155;
    }
  }
`

const LanguageSwitch = () => {
  const currentLocale = getCurrentLocale()
  return (
    <Container>
      {languages.map(({shortCode, locale}) => (
        <LanguageLink
          key={shortCode}
          active={locale === currentLocale}
          onClick={async () => {
            await storage.setItem('locale', locale)
            window.location.reload()
          }}
        >
          {shortCode}
        </LanguageLink>
      ))}
    </Container>
  )
}

export default LanguageSwitch

let _getPushToken
let pushToken

export const initPushToken = getPushToken => {
  _getPushToken = getPushToken
}

export const getPushToken = async () => {
  if (pushToken === undefined) {
    try {
      pushToken = await _getPushToken()
    } catch (e) {
      /* */
    }
  }
  return pushToken
}

import React, {Fragment} from 'react'
import styled from 'styled-components'

import CardTab from './CardTab'
import cardsManagementContainer from '../../containers/cards/cardsManagementContainer'
import {Text, Group} from '../ui'
import {formatMessage} from '../../i18n'

const Layout = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 868px;
  min-width: 768px;
  width: 100%;
  margin: 100px auto 40px;
`

const Sidebar = styled.aside`
  background-color: white;
  width: 300px;
  flex-shrink: 0;
  margin-right: 80px;
  position: sticky;
  top: 100px;
`

const Content = styled.div`
  flex: 1;
  padding-top: ${props => (props.mutipleSections ? '36px' : '0')};
`

const CardsManagement = ({myCardsAndAccounts, children}) => (
  <Layout>
    <Sidebar>
      {myCardsAndAccounts.length > 0 && (
        <Fragment>
          {/*otherCardsAndAccounts.length > 0 && (
            <SectionTitle>{formatMessage('myCards')}</SectionTitle>
          )*/}
          <Group data-cy="my-cards">
            {myCardsAndAccounts.map(({card, account}) => (
              <CardTab
                key={card.id}
                card={card}
                holder={account.clientName}
                url={`/cards/${card.id}`}
                showLogo={false}
              />
            ))}
          </Group>
        </Fragment>
      )}
      {/*otherCardsAndAccounts.length > 0 && (
        <Fragment>
          {myCardsAndAccounts.length > 0 && (
            <SectionTitle>{formatMessage('otherCards')}</SectionTitle>
          )}
          <Group data-cy="other-cards">
            {otherCardsAndAccounts.map(({card, account}) => (
              <CardTab
                key={card.id}
                card={card}
                holder={account.clientName}
                url={`/cards/${card.id}`}
                showLogo={false}
              />
            ))}
          </Group>
        </Fragment>
      )*/}
      {myCardsAndAccounts.length === 0 && (
        <div data-cy="no-cards">
          <Text>{formatMessage('noCards')}</Text>
        </div>
      )}
    </Sidebar>
    <Content mutipleSections={myCardsAndAccounts.length > 0}>
      {children}
    </Content>
  </Layout>
)

export default cardsManagementContainer(CardsManagement)

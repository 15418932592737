import React from 'react'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'

import {formatMessage} from '../../i18n'
import {AccountCard, Text} from '../ui'
import {accountListSelector} from '../../selectors'

const Container = styled.div`
  flex: 1;
  height: 100%;
  background-color: ${props => props.theme.app.colors.background.dark};
`

const Wrapper = styled.div`
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  padding: 0 16px;
  margin: 0 auto 120px auto;
`

const Items = styled.div`
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`

const Header = styled(Text).attrs({type: 'title'})`
  max-width: ${props => props.theme.app.sizes.maxWidth};
  min-width: ${props => props.theme.app.sizes.minWidth};
  width: 100%;
  margin: 104px auto 56px auto;
  padding: 0 16px;
`

const Accounts = () => {
  const accounts = useSelector(state => accountListSelector(state))
  const history = useHistory()
  return (
    <Container>
      <Header>{formatMessage('yourAccounts')}</Header>
      <Wrapper>
        {accounts.length ? (
          <Items>
            {accounts.map(account => (
              <AccountCard
                isPreview
                key={account.id}
                account={account}
                onClick={() => {
                  history.push(`/accounts/${account.id}/transactions`)
                }}
              />
            ))}
          </Items>
        ) : (
          <div data-cy="no-accounts">
            <Text>{formatMessage('noAccounts')}</Text>
          </div>
        )}
      </Wrapper>
    </Container>
  )
}

export default Accounts

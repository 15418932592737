import {includes} from 'lodash'
import * as Sentry from '@sentry/browser'

import {ApiRequestError, NetworkError} from './apiRequest'
import {resetAppStateAfterLogout, resetAppAfterPinExpired} from './auth/logout'
import {SET_VALUE} from './commonActions'

const getHandlerForErrorCode = (errorCode, handlers) => {
  for (const [errorCodes, handler] of handlers) {
    if (includes(errorCodes, errorCode)) {
      return handler
    }
  }
  return undefined
}

// Example of calling handleApiError:
// handleApiError(
//   error,
//   [
//     ['ERROR_CODE_1', 'ERROR_CODE_2', 'ERROR_CODE_3', 'ERROR_CODE_4'],
//     (errorCode) => handleError1to4(code),
//   ],
//   [['ERROR_CODE_5'], (code) => handleError5(code)]
// )
const handleApiError = (error, ...handlers) => async (dispatch, getState) => {
  // create general error handler
  const generalErrorCode = 'GENERAL_ERROR'
  const defaultGeneralErrorHandler = _ =>
    dispatch(SET_VALUE(['visibleGeneralError'], 'UNKNOWN_ERROR'))
  const generalErrorHandler =
    getHandlerForErrorCode(generalErrorCode, handlers) ||
    defaultGeneralErrorHandler

  // create network error handler
  const networkErrorCode = 'NETWORK_ERROR'
  const defaultNetworkErrorHandler = _ =>
    dispatch(SET_VALUE(['visibleGeneralError'], 'NETWORK_ERROR'))
  const networkErrorHandler =
    getHandlerForErrorCode(networkErrorCode, handlers) ||
    defaultNetworkErrorHandler

  if (error instanceof NetworkError) {
    networkErrorHandler(error)
    return
  }
  if (error instanceof ApiRequestError) {
    // logout on 401
    if (error.status === 401 || error.errorCode === 'ERR_DEVICE_EXPIRED') {
      await dispatch(resetAppStateAfterLogout()) // TODO some alert "You session has expired"
      return
    }

    if (error.errorCode === 'ERR_TOO_MANY_PIN_ATTEMPTS') {
      dispatch(SET_VALUE(['visibleGeneralError'], 'ERR_TOO_MANY_PIN_ATTEMPTS'))
      await dispatch(resetAppStateAfterLogout())
      return
    }

    if (error.errorCode === 'ERR_ACCESS_EXPIRED') {
      await dispatch(resetAppAfterPinExpired())
      return
    }
    // show error if app version is not supported
    if (error.errorCode === 'ERR_UNSUPPORTED_APP_VERSION') {
      dispatch(SET_VALUE(['unsupportedAppVersionErrorVisible'], true))
      return
    }
    // for now, this error is only used with app-specific features
    if (error.errorCode === 'ERR_STALE_DATA') {
      dispatch(SET_VALUE(['visibleGeneralError'], 'ERR_STALE_DATA'))
      dispatch(SET_VALUE(['resetWholeApp'], true))
      return
    }

    // errors that can be handled
    const {errorCode} = error
    if (errorCode != null) {
      const handler = getHandlerForErrorCode(errorCode, handlers)
      if (handler) {
        handler(errorCode)
        return
      }
    }
  }

  // all error that couldn't be handled in previous step are some bad ones

  // capture through Sentry
  Sentry.withScope(scope => {
    if (error instanceof ApiRequestError && error.requestId) {
      scope.setFingerprint([error.requestId])
    }
    Sentry.captureException(error)
  })

  generalErrorHandler(generalErrorCode)
}

export default handleApiError

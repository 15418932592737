import {connect} from 'react-redux'
import {hoistStatics} from 'recompose'

import {loginStatusSelector} from '../selectors'

const container = connect(state => ({
  loginStatus: loginStatusSelector(state)
}))

export default hoistStatics(container)

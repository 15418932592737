import React from 'react'
import {compose, withState, lifecycle} from 'recompose'
import {uniqueId} from 'lodash'
import {useSelector} from 'react-redux'

import {formatMessage} from '../i18n'
import {Text, Modal, Paragraph, Button} from './ui'
import generalErrorContainer from '../containers/generalErrorContainer'
import {emailSelector} from '../selectors'

const MESSAGES = {
  NETWORK_ERROR: {
    titleKey: 'networkErrorTitle',
    descriptionKey: 'networkErrorDescription'
  },
  WARN_USERNAME_CHANGED: {
    titleKey: 'usernameChangedWarningTitle',
    descriptionKey: 'usernameChangedWarningDescription',
    hideButton: true
  }
}

const GeneralError = ({
  visible,
  dismissGeneralError,
  visibleGeneralError,
  modalKey
}) => {
  const email = useSelector(emailSelector)

  return (
    <Modal
      isOpen={visible}
      onRequestClose={dismissGeneralError}
      title={
        (MESSAGES[visibleGeneralError] &&
          MESSAGES[visibleGeneralError].titleKey &&
          formatMessage(MESSAGES[visibleGeneralError].titleKey)) ||
        formatMessage('unknownErrorTitle')
      }
      data-cy="general-error-modal"
      key={modalKey}
    >
      <Paragraph>
        <Text>
          {(MESSAGES[visibleGeneralError] &&
            MESSAGES[visibleGeneralError].descriptionKey &&
            formatMessage(MESSAGES[visibleGeneralError].descriptionKey, {
              email: email || ''
            })) ||
            formatMessage('unknownErrorDescription')}
        </Text>
      </Paragraph>
      {(!MESSAGES[visibleGeneralError] ||
        !MESSAGES[visibleGeneralError].hideButton) && (
        <Button block onClick={() => window.location.reload()}>
          {formatMessage('reloadPage')}
        </Button>
      )}
    </Modal>
  )
}

const enhance = compose(
  generalErrorContainer,
  withState('visible', 'setVisible', props => !!props.visibleGeneralError),
  withState('modalKey', 'setModalKey', uniqueId().toString()),
  lifecycle({
    componentDidUpdate(prevProps) {
      //HACK: we need to remount the modal every time it appears
      // so that it is on top of other modals
      // we can achieve this by changing its key
      // but we can't change its key when it's about to disappear because
      // the animation would be cut off
      if (
        !!prevProps.visibleGeneralError !== !!this.props.visibleGeneralError
      ) {
        const visible = !!this.props.visibleGeneralError
        this.props.setVisible(visible)
        if (visible) this.props.setModalKey(uniqueId().toString())
      }
    }
  })
)

export default enhance(GeneralError)

import React from 'react'
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {ChevronRight} from 'react-feather'

import {formatMessage} from '../../i18n'
import {GroupItem, Text, CardLogo} from '../ui'

const activeClassName = 'active'

const StyledLink = styled(NavLink)`
  display: block;
  text-decoration: none;
  border-right: 3px solid transparent;
  border-bottom: 1px solid #d8e1e6;
  padding: 0;
  border-radius: 0 4px 4px 0;

  &:hover {
    background-color: #fafafa;
  }

  &.${activeClassName} {
    border-right-color: #004155;
  }
`

const CardTab = props => {
  const isBlocked = ['blocked', 'adminSuspended'].includes(props.card.status)
  const responsive = props.url && props.card.openable
  const tab = (
    <GroupItem
      data-card-id={props.card.id}
      reverseContent
      value={
        <>
          {props.card.pan && (
            <Text bold>
              {formatMessage('cardPanMasked', {
                pan: props.card.pan.slice(props.card.pan.length - 4)
              })}
            </Text>
          )}
          <Text type="secondary" style={{marginLeft: props.card.pan ? 12 : 0}}>
            {props.card.cardExpiration}
          </Text>
        </>
      }
      labelColor={isBlocked && '#E15151'}
      middleValue={props.holder}
      label={
        !props.hideStatus && (
          <Text style={{color: isBlocked && '#E15151'}}>
            {formatMessage('cardStatus', {status: props.card.status})}
          </Text>
        )
      }
      hoverable={responsive}
      disabled={!props.card.openable}
      action={responsive && <ChevronRight color="#5D7083" />}
      beforeContent={
        props.showLogo && (
          <CardLogo
            cardProduct={props.card.cardProduct}
            cardOrganization={props.card.cardOrganization}
          />
        )
      }
      spaceBetween
    />
  )

  return responsive ? <StyledLink to={props.url}>{tab}</StyledLink> : tab
}

CardTab.defaultProps = {
  showLogo: true,
  hideStatus: false
}

export default CardTab

export const PING_COOLDOWN = 60000

export const NATIVE_APP_PIN_LENGTH = 4

export const MAX_CARD_COUNT = 3

export const recommendedCountryCodes = ['SK', 'CZ']

export const MAX_SAVING = 1000

export const QUESTIONS_PER_KNOWLEDGE_POINT = 5

export const SMARTIE_POINTS_FOR_QUIZ_LEVEL = {
  1: 1,
  2: 2,
  3: 3
}

export const QUESTIONS_PER_QUIZ = 5

export const MAX_QUIZ_LEVEL = 3

export const otherCountryCodes = [
  'BE',
  'BG',
  'HR',
  'CY',
  'DK',
  'EE',
  'FI',
  'FR',
  'GR',
  'NL',
  'IE',
  'LT',
  'LV',
  'LU',
  'HU',
  'MT',
  'DE',
  'PL',
  'PT',
  'AT',
  'RO',
  'SI',
  'ES',
  'SE',
  'IT',
  'GB',
  'UA'
]

export const CUSTOM_AVATAR_INDEX = 'CUSTOM_AVATAR_INDEX'

export const FEATURE_CATEGORIES = {
  PROFILE_AVATAR: 'PROFILE_AVATAR',
  SAVINGS_AVATAR: 'SAVINGS_AVATAR',
  AVATAR_BORDER: 'AVATAR_BORDER',
  THEME_COLOR: 'THEME_COLOR',
  THEME_PATTERN: 'THEME_PATTERN',
  THEME_BACKGROUND: 'THEME_BACKGROUND'
}

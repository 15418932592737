export default {
  // general
  dateValue:
    '{date, date} {showTime, select, false{} other{o {date, time, short}}}',
  // backend errors
  ERR_LOCKED_OUT:
    'Zaznamenali sme 3 nesprávne pokusy. Prihlasovanie bude obnovené o 5 minút.',
  ERR_INVALID_DATA:
    'Údaje, ktoré si zadal(a) sú nesprávne. Prosím skontroluj ich a skús to znova.',
  ERR_INVALID_VS:
    'Variabilný symbol neexistuje. Pri založení účtu môže spracovanie trvať aj deň.',
  ERR_INVALID_VS_FORMAT: 'Variabilný symbol je v nesprávnom tvare',
  ERR_INVALID_USERNAME: 'Používateľské meno je v nesprávnom tvare.',
  ERR_DUPLICATE_RECORD: 'Používateľ už existuje',
  ERR_INVALID_EMAIL:
    'Email nepatrí k účtu s daným variabilným symbolom a ani k nemu nebol pozvaný',
  ERR_INVALID_PHONE:
    'Mobil nepatrí k účtu s daným variabilným symbolom a ani k nemu nebol pozvaný',
  ERR_INVALID_PASSWORD: 'Nesprávne heslo',
  ERR_INVALID_PASSWORD_FORMAT:
    'Tvoje heslo musí obsahovať aspoň 8 znakov bez medzier, z toho aspoň 1 veľké písmeno, 1 malé písmeno a 1 číslicu.',
  ERR_EXPIRED: 'Aktivačný kód expiroval.',
  ERR_USER_ALREADY_ACTIVATED: 'Používateľ je už aktivovaný.',
  ERR_CANT_INVITE_MYSELF: 'Nemôžeš pozvať seba.',
  ERR_INVITE_ALREADY_EXIST: 'Pozvánka na zadaný kontakt už existuje',
  ERR_RATELIMIT_EXCEEDED: 'Prekročil(a) si limit pre SMS. Skús znovu neskôr.',
  ERR_CODE_ENTER_LIMIT_EXCEEDED:
    'Prekročil(a) si maximálny počet pokusov na zadanie kódu. Požiadaj o znovuposlanie kódu.',
  ERR_UNAUTHORIZED: 'Nie si autorizovaný(á).',
  // frontend errors
  ERR_INVALID_EMAIL_FORMAT: 'Zadaný email je v nesprávnom tvare',
  ERR_INVALID_PHONE_FORMAT: 'Mobilné tel. číslo má nesprávny formát',
  ERR_REQUIRED_IS_MISSING: 'Najprv vyplň všetky polia',
  ERR_PASSWORDS_DONT_MATCH: 'Zadané heslá sa nezhodujú',
  ERR_TEXT_IS_REQUIRED: 'Text je povinný',
  ERR_INVALID_IBAN_FORMAT: 'Zadaný IBAN je neplatný',
  ERR_INVALID_NATIONALITY_FORMAT:
    'Zadaná štátna príslušnosť je v nesprávnom tvare',
  ERR_INVALID_BIRTH_NUMBER_FORMAT: 'Zadané rodné číslo je v nesprávnom tvare',
  ERR_SAVING_OVER_MAX: 'Cieľ sporenia nemôže prevýšiť 1000 €',
  ERR_NAME_EMPTY: 'Meno nemôže byť prázdne',
  ERR_VALUE_NOT_NEGATIVE: 'Hodnota nemôže byť záporná',
  ERR_VALUE_NOT_A_NUMBER: 'Hodnota musí byť číslo',
  ERR_SAVING_AMOUNT_EMPTY: 'Cieľ sporenia nemôže byť prázdny',
  ERR_SAVING_AMOUNT_NOT_ZERO: 'Hodnota nemôže byť nulová',
  ERR_SAVING_OVERFLOW: 'Hodnota nemôže presiahnuť cieľovú hodnotu',
  ERR_SAVING_FUNDS_OVER_BALANCE: 'Hodnota prevyšuje zostatok na karte',
  ERR_CURRENCY_NUMBER_FORMAT: 'Číslo je v nesprávnom tvare',
  ERR_INVALID_PIN_CODE: 'Nesprávny PIN. Prosím skús znova.',
  ERR_TOO_MANY_PIN_ATTEMPTS:
    'Príliš veľa pokusov o zadanie PIN kódu. Prihlás sa znova.',
  SERVER_ERROR: 'Chyba serveru',
  // operation types
  OP_TYPE_ATM: 'Bankomat',
  OP_TYPE_POS: 'Bezhotovostná platba',
  OP_TYPE_CASHLESS: 'Bezhotovostná platba',
  OP_TYPE_VOICE: 'Hlasová autorizácia',
  OP_TYPE_ECOMMERCE: 'Ecommerce',
  OP_TYPE_DEFAULT: 'Iné',
  // pin lock
  pinLockTitleCreate: 'Vytvor si PIN',
  pinLockSubtitleCreate: 'Zvoľ si PIN na odomykanie apky',
  pinLockTitleValidationFailed: 'Zadaný PIN je ľahko uhádnuteľný',
  pinLockTitleConfirm: 'Zopakuj svoj PIN',
  pinLockTitleConfirmFailed: 'Zadané PINy sa nezhodujú',
  pinLockTitleEnter: 'Zadaj svoj PIN',
  pinLockTitleChange: 'Zadaj pôvodný PIN',
  scanYourFinger: 'Prilož prst ku skeneru.',
  scanFailedTryAgain: 'Skenovanie zlyhalo. Prosím skús znova.',
  scanFailedLockout: 'Príliš veľa pokusov. Použi PIN.',
  youWillBeLogout: 'Po stlačení OK budeš odhlásený(á).',
  allowBiometricsAlert:
    'Chceš povoliť prihlásenie pomocou {biometricsType, select, fingerprint {odtlačku prstu} face {skenu tváre}}?',
  biometryAttemptExpired:
    'Prihlásenie pomocou biometrie teraz nie je možné. Použi svoj PIN kód.',
  youCanChangeThisInProfile:
    'Toto nastavenie môžeš neskôr zmeniť vo svojom profile.',
  // other
  footer:
    '365.bank, a. s., so sídlom Dvořákovo nábrežie 4, 811 02 Bratislava | Všetky práva vyhradené',
  accounts: 'Účty',
  yourAccounts: 'Tvoje účty',
  noAccounts: 'K tvojmu profilu nie je naviazaný žiadny účet.',
  noCards: 'K tvojmu profilu nie je naviazaná žiadna karta.',
  cardNeedsActivation: 'Karta nebola aktivovaná',
  cardBeingProduced: 'Kartu vyrábame',
  EUR: '{amount, number, EUR}',
  amountFormat: '{amount, number}',
  topUpAccount: 'Dobiť účet',
  accountDetail: 'Detail účtu',
  transactions: 'Pohyby',
  recentTransactions: 'Posledné pohyby na účte',
  filterTransactions: 'Filtrované pohyby na účte',
  transactionsFilter: 'Filter transakcií',
  tasksFilter: 'Filter úloh',
  noTransactionsInSelectedFilter: 'Žiadne pohyby nevyhovujú zadanému filtru',
  loadingTransactions: 'Načítavam pohyby',
  errorLoadingTransactions: 'Chyba pri načítavaní pohybov',
  transactionDetail: 'Detail transakcie',
  transactionsType: 'Typ transakcií',
  transactionType: 'Typ transakcie',
  from: 'Od',
  to: 'Do',
  filter: 'Filter',
  changeFilter: 'Zmeniť filter',
  date: 'Dátum',
  showResults: 'Zobraziť výsledky',
  resetFilter: 'Resetovať filter',
  notAllTransactionsShownSpecifyFilter:
    'Nie sú zobrazené všetky transakcie. Špecifikuj filter.',
  reserved: 'Rezervovaná',
  realized: 'Zrealizovaná',
  seller: 'Obchodník',
  detail: 'Detail',
  originalAmount: 'Pôvodná suma',
  conversionRate: 'Kurz',
  contactInformation: 'Kontaktné údaje',
  changePassword: 'Zmeniť heslo',
  privacy: 'Osobné',
  changeAppPin: 'Zmena PIN kódu aplikácie',
  signInViaBiometrics:
    'Prihlásenie pomocou {biometricsType, select, fingerprint {odtlačku prstu} face {skenu tváre}}',
  aboutApp: 'O apke',
  rateApp: 'Ohodnoť nás v {os, select, ios {App Store} android {Google Play}}',
  version: 'Verzia',
  reportTransaction: 'Reklamovať transakciu',
  reportOfTransaction: 'Reklamácia transakcie',
  reportOfTransactionDesc:
    'Ak nesúhlasíš s transakciou, môžeš nám ju oznámiť prostredníctvom tohto formulára.',
  yourEmail: 'Tvoj email',
  optional: 'Nepovinné',
  errorDesc: 'Opis chyby',
  documents: 'Dokumenty',
  businessConditions: 'Obchodné podmienky',
  fees: 'Sadzobník poplatkov',
  dataProtection: 'Ochrana osobných údajov',
  termsOfUseAndPrivacyPolicy: 'Podmienky používania a ochrana súkromia',
  launchTutorial: 'Spustiť tutoriál',
  appLanguage: 'Jazyk aplikácie',
  changeAppLanguage: 'Zmeniť jazyk aplikácie',
  appSettings: 'Nastavenie apky',
  signOut: 'Odhlásiť sa',
  signOutMobile: 'Odhlásiť',
  deactivate: 'Deaktivovať',
  deactivateAlertHeader: 'Deaktivovať',
  deactivateAlertDescription:
    'Naozaj si praješ deaktivovať účet na tomto zariadení?',
  myProfile: 'Môj profil',
  profile: 'O tebe',
  accountManagement: 'Nastavenia účtu',
  cardsManagement: 'Detail karty',
  bankStatements: 'Výpisy',
  noBankStatements: 'Pre tento účet sa nenašli žiadne výpisy',
  yearLabel: 'Rok',
  selectYearLabel: 'Rok {year}',
  bankStatementLabel: `{month, select,
    0 {Január}
    1 {Február}
    2 {Marec}
    3 {Apríl}
    4 {Máj}
    5 {Jún}
    6 {Júl}
    7 {August}
    8 {September}
    9 {Október}
    10 {November}
    11 {December}
  }/{year}`,
  guestsManagement: 'Pozvánky',
  accountSettings: 'Nastavenia účtu',
  invitesWaiting: 'Odoslané pozvánky',
  noGuests: 'Do účtu si ešte nikoho nepozval(a).',
  inviteChild: 'Pozvať dieťa',
  sendInvite: 'Odoslať pozvánku',
  smartieWebLink: 'na našom webe',
  invitePhoneLabel: 'Telefónne číslo dieťaťa',
  inviteFormCardIssueNotice:
    'Skôr ako pridáš ďalšie dieťa do SMARTIE, je potrebné požiadať o vydanie jeho vlastnej karty ',
  inviteFormCardIssueNewCardNotice:
    'Novú kartu nezabudni aktivovať prvým dobitím. Následne ju nájdeš v časti Moje deti.',
  inviteFormDescriptionMobile:
    'Pozvánku do SMARTIE odošleme esemeskou tvojmu dieťaťu na toto telefónne číslo. Je potrebné, aby ju potvrdilo.',
  inviteFormNoticeTitle: 'Tvoje dieťa bude mať nasledujúce práva:',
  inviteFormNoticeFirst: 'zablokovanie karty',
  inviteFormNoticeSecond: 'prezeranie všetkých transakcií na svojom účte',
  inviteFormNoticeThird: 'prezeranie zadaných úloh',
  cardPanMasked: '•••• {pan}',
  cardLimitMaxValue: '{label} (Maximálna hodnota: {maxValue})',
  max: 'Max {maxValue} €',
  cardExpiration: 'PLATNÁ DO',
  addToMyCards: 'Pridať medzi obľúbené karty',
  setLimits: 'Nastavenie limitov',
  limits: 'Limity',
  atmWithdrawal: 'Výber z bankomatu',
  atmWithdrawalDayLimit: 'Mesačný limit pre výber z bankomatu',
  allowATMWithdrawal: 'Povoliť výber z bankomatu',
  dayLimit: 'Mesačný limit',
  cashlessPayments: 'Bezhotovostné platby',
  cashlessPaymentsDayLimit: 'Mesačný limit pre bezhotovostné platby',
  allowCashlessPayments: 'Povoliť bezhotovostné platby',
  contactlessPayments: 'Bezkontaktné platby',
  allowContactlessPayments: 'Povoliť bezkontaktné platby',
  internetPayments: 'Platby cez internet',
  internetPaymentDayLimit: 'Mesačný limit pre platby cez internet',
  allowInternetPayments: 'Povoliť platby cez internet',
  reprintPin: 'Opätovné vytlačenie PIN-u',
  reprintPinDescription:
    'Ak si zabudol/zabudla PIN, môžeš požiadať o jeho opätovné vytlačenie a my ti ho pošleme do 5 pracovných dní poštou',
  reprintPinModalTitle: 'Opätovné vytlačenie PIN-u',
  reprintPinModalInfo: 'PIN pošleme poštou do 5 pracovných dní.',
  reprintPinAddressLabel: 'PIN pošleme na adresu:',
  reprintPinSubmitButton: 'Znovu vytlačiť PIN',
  reissueCard: 'Prevydanie karty',
  reissueCardDescription: 'Vydaním novej karty sa pôvodná karta znefunkční.',
  reissueCardModalTitle: 'Vydanie novej karty',
  reissueCardModalAlert:
    'Po potvrdení požiadavky pôvodná karta už nebude fungovať.',
  issueNewCard: 'Vydať novú kartu',
  reissueCardAddressLabel: 'Kartu pošleme na adresu:',
  reissueCardReasonLabel: 'Dôvod vydania karty:',
  chooseReason: 'Vyber dôvod',
  choose: 'Vybrať',
  lossOrTheft: 'Strata alebo krádež',
  damage: 'Poškodenie',
  lostOrStolenCardInfo:
    'Zvoľ túto možnosť, ak si stratil(a) kartu, odcudzili ti ju alebo ju nevieš nájsť. Novú kartu a PIN ti pošleme do 5 pracovných dní poštou.',
  damagedCardInfo:
    'Zvoľ túto možnosť, ak je tvoja karta fyzicky poškodená, napr. nalomená. Novú kartu a PIN ti pošleme do 5 pracovných dní poštou.',
  requestMoney: 'Prevod peňazí na účet',
  requestMoneyDescription:
    'Tu môžeš požiadať o prevod peňazí na tvoj bankový účet.',
  requestMoneyModalTitle: 'Žiadosť o prevod peňazí',
  requestMoneyConfirmation: 'Potvrdenie žiadosti o prevod peňazí',
  submitRequestMoney: 'Odoslať',
  yourIban: 'Účet v tvare IBAN',
  placeholderIban: 'SK09 1287 1234 1245 9812 7654',
  requestMoneyModalDescription:
    'Poslal si viac € než bolo treba? Napíš, akú sumu chceš vrátiť, a my sa postaráme o vrátenie peňazí na Tvoj 365 účet. Peniaze budeš mať na účte do 3 pracovných dní.',
  accountInformation: 'Informácie o klientovi',
  addresses: 'Adresy',
  myCard: 'Moja karta',
  myCards: 'Moje karty',
  otherCards: 'Ostatné karty',
  invitationSentOn:
    'Pozvánka vytvorená {invitationDate, date} o {invitationDate, time, short}',
  name: 'Meno',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  permanentAddress: 'Adresa trvalého bydliska',
  correspondenceAddress: 'Korešpondenčná adresa',
  availableBalance: 'Disponibilný zostatok',
  iban: 'Zberný účet',
  variableSymbol: 'Variabilný symbol',
  variableSymbolWithAbbreviation: 'Variabilný symbol (VS)',
  variableSymbolAbbreviation: 'VS',
  topupAccountDescription:
    'Kartu môžeš svojmu dieťaťu dobiť bezhotovostným prevodom na účet. Nezabudni uviesť správny variabilný symbol.',
  topupAccountRegularDescription:
    'Ak mu plánuješ posielať vreckové pravidelne, môžeš si nastaviť trvalý príkaz zo svojho účtu.',
  topupAccountTitle: 'Dobitie karty',
  sendInvitationAgain: 'Poslať znova',
  saveChanges: 'Uložiť zmeny',
  contactInfoValidation: 'Overenie kontaktného údaju',
  verificationCode: 'Overovací kód',
  submit: 'Odoslať',
  delete: 'Vymazať',
  cancel: 'Zrušiť',
  copy: 'Skopírovať',
  signUp: 'Aktivovať SMARTIE',
  signUpAction: 'Aktivovať',
  signUpHeader: 'Aktivácia SMARTIE',
  signIn: 'Prihlásiť sa',
  signInLong: 'Už máš SMARTIE účet? Prihlás sa.',
  emailOrPhone: 'Email (rodič) alebo telefónne číslo (dieťa)',
  yourPhoneNumber: 'Tvoje telefónne číslo',
  minimalLength8Symbols: 'Minimálna dĺžka 8 znakov',
  password: 'Heslo',
  createPassword: 'Vytvor si heslo',
  forgotPassword: 'Znovu nastaviť heslo',
  forgotPinCode: 'ZABUDNUTÝ KÓD?',
  dontHaveAnAccountYet: 'Nemáš ešte aktivovanú SMARTIE?',
  dontHaveCardYet: 'Nemáš ešte kartu SMARTIE?',
  fillOnlineApplication: 'Stačí vyplniť online žiadosť',
  variableSymbolOfAccount: 'Variabilný symbol SMARTIE karty',
  tenDigitContractNumber: '10-miestne číslo',
  email: 'E-mail v 365-ke',
  phone: 'Mobil',
  mobilePhoneNumberOfChild: 'Telefónne číslo dieťaťa',
  placeholderContactValue:
    '{contactType, select, email {meno@mail.sk} phone {+421 903 123 456}}',
  enterPasswordForCheck: 'Zadaj heslo na kontrolu',
  repeatPassword: 'Zopakuj heslo',
  iAgreeToProcessingOfPersonalData: 'Súhlasím ',
  iAgreeToProcessingOfPersonalDataLinkText:
    'so spôsobom spracovania a ochrany osobných údajov v 365.bank.',
  resendCode: 'Znova poslať kód',
  codeResentSuccessfully: 'Preposlali sme Ti overovací kód.',
  codeResentSuccessfullyWithHelp:
    'Preposlali sme Ti overovací kód. V prípade, že Ti napriek tomu neprišiel, kontaktuj naše zákaznícke centrum.',
  contactCustomerService: 'Kontaktovať zákaznícke centrum',
  inviteResentSuccessfully: 'Pozvánka bola znovu odoslaná',
  signUpUsingDataFromYourContract: `Aktivuj si SMARTIE {activationType, select,
      email {aplikáciu pomocou Tvojho e-mailu v 365}
      phone {aplikáciu pomocou Tvojho telefónneho čísla}
      other {pomocou Tvojho e-mailu v 365 (rodič) alebo Tvojho telefónneho čísla (dieťa)}
    } a variabilného symbolu karty.`,
  whereCanIFindTheData: 'Kde nájdem tieto údaje?',
  registrationTitle: 'Aktivuj si aplikáciu SMARTIE',
  loginTitle: 'Prihlás sa do aplikácie SMARTIE',
  aboutAppTitle1: 'Vitaj v aplikácii SMARTIE',
  aboutAppText1:
    'Predplatené karty SMARTIE teraz môžeš spravovať pohodlne z tvojho smartfónu.',
  aboutAppTitle2: 'Tvoje karty pod kontrolou',
  aboutAppText2:
    'Tvoje financie sú v bezpečí vďaka okamžitej zmene limitov a možnosti zablokovať kartu.',
  aboutAppTitle3: 'Prehľad v pohyboch',
  aboutAppText3: 'Vďaka histórii pohybov vieš viac o tvojich výdavkoch.',
  aboutAppTitle11: 'Vitaj vo svete,',
  aboutAppTitle12: 'kde vreckové',
  aboutAppTitle13: 'už neštrngá',
  aboutAppText11: 'Poďme spolu zažiť\n',
  aboutAppText12: 'veľké veci!',
  aboutAppTitle21: 'Tvoj poklad je',
  aboutAppTitle22: 'vždy v bezpečí',
  aboutAppText21: 'PIN a kartu si ustráž,\n',
  aboutAppText22:
    'ale nič nieje stratené, ani keď ich\nstratíš. Kartu si hneď zablokuj, aby\nju nikto nemohol zneužiť',
  aboutAppTitle31: 'Odteraz môžeš',
  aboutAppTitle32: 'rátať s nami',
  aboutAppText31: 'Odkiaľ sa berú a kam miznú?\nMaj ',
  aboutAppText32: 'prehľad o svojich peniazoch\n',
  aboutAppText33: 'kedykoľvek priamo v mobile.',
  backToMainPage: 'Späť na úvodnú stránku',
  mainPageUrl: 'https://smartie.sk/',
  mobilePhoneNumber: 'Telefónne číslo',
  VSHintTitle: 'Kde nájdem údaje na aktivovanie SMARTIE?',
  VSHintDescription: 'Pre aktiváciu potrebuješ 2 základné údaje:',
  VSHintDescriptionBullet1: `{userType, select,
      parent {variabilný symbol z detskej karty, ktorý nájdeš na zadnej strane SMARTIE karty. Ak máš kariet viac, môžeš použiť variabilný symbol z ktorejkoľvek karty. Ak si o SMARTIE kartu ešte nepožiadal/a, zbehni na }
      child {variabilný symbol z detskej karty, ktorý nájdeš na zadnej strane SMARTIE karty alebo v SMS, ktorú si dostal/a.}
      other {variabilný symbol z detskej karty, ktorý nájdeš na zadnej strane SMARTIE karty. Ak si rodič a máš kariet viac, môžeš použiť variabilný symbol z ktorejkoľvek karty. Ak si o SMARTIE kartu ešte nepožiadal/a, zbehni na }
    }`,
  VSHintDescriptionBullet2: `{userType, select,
      parent {Tvoj e-mail, ktorý používaš v 365-ke.}
      child {Tvoje telefónne číslo.}
      other {Tvoj e-mail v 365 (rodič) alebo Tvoje telefónne číslo (dieťa).}
    }`,
  VSHintDescriptionBullet3: `A samozrejme si nezabudni nastaviť heslo.`,
  registrationVSLabel: 'Variabilný symbol (VS - 10-miestne číslo z karty)',
  account: 'Účet',
  card: 'Karta',
  cards: 'Karty',
  period: 'Obdobie',
  filetype: '{extension, select, pdf {PDF} zip {ZIP}}',
  limitMustBeANumber: 'Zadaný limit musí byť číslo',
  limitMustBeAWholeNumber: 'Zadaný limit musí byť celé číslo',
  limitMustBeUpTo: 'Zadaný limit nemôže byť väčší ako {maxFieldValue}',
  limitMustBePositive: 'Zadaný limit musí byť kladné číslo',
  mobileApp: 'SMARTIE v tvojom mobile',
  mobileAppPromoText: 'Nos aktuálny prehľad o kartách stále vo svojom vrecku.',
  contactUs: 'Kontaktuj nás',
  contactUsDescription:
    'Máš postrehy, otázky alebo nápady na zlepšenie? Sem s nimi, sme zvedaví :)',
  contactUsSuccess: 'Tvoja správa bola odoslaná',
  yourMessage: 'Tvoja správa pre nás',
  appStore: 'App Store',
  playStore: 'Play Store',
  editCorrAddressConfirmation: 'Potvrdiť zmenu korešpondenčnej adresy',
  editContactInfo: 'Upraviť kontaktné údaje',
  editContactInfoDescription:
    'Upraviť kontaktné údaje vieš len priamo v aplikácii 365.bank',
  unknownErrorTitle: 'Neznáma chyba',
  unknownErrorDescription:
    'Nastala neznáma chyba. Prosím skús to zopakovať neskôr.',
  networkErrorTitle: 'Sieťová chyba',
  networkErrorDescription:
    'Nastala sieťová chyba. Prosím skontroluj svoje internetové pripojenie',
  staleDataErrorTitle: 'Neaktuálne údaje',
  staleDataErrorDescription:
    'Nastala chyba pri upravovaní neauktálnych údajov.',
  usernameChangedWarningTitle: 'Prihlasovacie údaje boli aktualizované',
  usernameChangedWarningDescription:
    'Tvoj e-mail pre SMARTIE sme zmenili na {email}, odteraz sa ním prihlasuješ aj do apky. Ak sa Ti e-mail nepozdáva, daj nám o tom vedieť na hello@365.bank alebo 0850 365 365.',
  reloadPage: 'Znovu načítať stránku',
  credit: 'Kreditné operácie',
  debit: 'Debetné operácie',
  creditShort: 'Kreditné',
  debitShort: 'Debetné',
  forgotPasswordTitle: 'Znovu nastaviť heslo',
  forgotPasswordDescription:
    'Prosím vyplň kontaktný údaj, s ktorým si si aktivoval(a) SMARTIE, pre obnovu tvojho hesla.',
  contactUsedForRegistration: 'Kontaktný údaj',
  continue: 'Pokračovať',
  pleaseEnterVerificationCode:
    'Pokračuj zadaním overovacieho kódu, ktorý sme poslali na Tvoj {contactType, select, email {email} phone {telefón}}.',
  pleaseEnterRegistrationVerificationCode:
    '{contactType, select, email {Prosím, potvrď aktiváciu ťuknutím na link alebo použi overovací kód, oba sme Ti zaslali na email.} phone {Prosím, potvrď aktiváciu ťuknutím na link alebo zadaj overovací kód. Oba kódy nájdeš v SMS-ke, ktorú sme Ti už zaslali na Tvoje telefónne číslo.}}',
  enterVerificationCode: 'Zadaj overovací kód',
  verificationCodeFromContact:
    'Overovací kód z {contactType, select, email {email-u} phone {SMS}}',
  identitySuccessfullyConfirmed:
    'Úspešne sme overili tvoju identitu. Môžeš si vytvoriť nové heslo.',
  yourPassword: 'Tvoje heslo',
  signInWithNewPassword: 'Prihlásiť sa s novým heslom',
  changePasswordConfirmation: 'Potvrdenie zmeny hesla',
  oldPassword: 'Staré heslo',
  newPassword: 'Nové heslo',
  newPasswordRepeat: 'Zopakuj nové heslo',
  noMyCard:
    'Nemáš žiadnu aktívnu kartu. Novú kartu nezabudni aktivovať prvým dobitím. ',
  activateCardBeforeInviting:
    'Najprv aktivuj kartu prvým dobitím a potom pozvi svojho školáka ku karte a do sveta SMARTIE.',
  confirmDialogLimit: 'Potvrdiť zmenu limitu?',
  confirmDialogBlocked:
    'Naozaj chceš {value, select, true {zablokovat} false {odblokovať}} svoju kartu?',
  confirmDialogDeleteInvite: 'Zmazať pozvánku?',
  confirmDialogDeleteInviteDescription:
    'Spolu s pozvánkou budú zmazané aj vytvorené sporenia, získané vylepšenia apky, výsledky v kvíze a SMARTIE body.',
  unsupportedAppVersionTitle: 'Nepodporovaná verzia aplikácie',
  unsupportedAppVersionDescription:
    'Prosím aktualizuj si aplikáciu na novšiu verziu',
  amount: 'Suma',
  cardStatus: `{status, select,
      requested {Žiadaná}
      generated {Vyrobená}
      inProduction {Distribuovaná}
      inactive {Neaktívna}
      active {Aktívna}
      closed {Zrušená}
      expired {Expirovaná}
      incorrectProduction {Chybná výroba}
      adminSuspended {Blokovaná vydavateľom}
      blocked {Blokovaná}
      reissued {Prevydaná}
      other {}}`,
  toUnblockContactUsOn: 'O odblokovanie karty požiadaj rodiča',
  block: 'Zablokovať',
  unblock: 'Odblokovať',
  statusOfCard: 'Stav karty',
  cardProductDescription:
    '{cardProduct, select, maestro {Maestro} mastercard {Mastercard} other {Neznáma}} predplatená karta',
  mandatory: 'Povinné',
  allowed: 'Povolené',
  notAllowed: 'Nepovolené',
  requestAccepted: 'Tvoju žiadosť sme prijali',
  requestSentModalTitle: 'Žiadosť bola odoslaná',
  close: 'Zavrieť',
  loadingData: 'Načítavam údaje',
  loadingCards: 'Načítavam karty',
  ibanCopiedSuccess: 'IBAN skopírovaný',
  vsCopiedSuccess: 'Variabilný symbol skopírovaný',
  yes: 'Áno',
  no: 'Nie',
  confirm: 'Potvrdiť',
  refresh: 'Znovu načítať',
  saving: 'Sporenie',
  savingWithdraw: 'Výber zo sporenia',
  newSavingNameTitle: 'Na čo si chceš začať sporiť?',
  newSaving: 'Koľko si chceš našetriť?',
  newSavingMaxAmount: 'Maximálne {amount,number} €',
  createSaving: 'Vytvoriť sporenie',
  chooseSavingAvatar: 'Pridať vlastný obrázok',
  addToSavingTitle: 'Koľko peňazí si chceš presunúť na sporenie?',
  addToSavingButton: 'Presunúť na sporenie',
  newSavingName: 'Pomenuj si sporenie',
  targetAmount: 'Zadaj sumu',
  tasks: 'Úlohy',
  myTasks: 'Moje úlohy',
  assignedTasks: 'Zadané úlohy',
  newTask: 'Nová úloha',
  createNewTask: 'Pridať novú úlohu',
  enterTaskDescription: 'Zadaj úlohu',
  resolveTask: 'Dokončená',
  failTask: 'Nesplnená',
  deleteTask: 'Zmazať úlohu',
  taskHistory: 'História úloh',
  completeHistory: 'Celá história',
  taskFilter: 'Filtrovať',
  taskType: 'Typ úlohy',
  resolvedStatus: 'Splnená',
  failedStatus: 'Nesplnená',
  child: 'Dieťa',
  iAmChild: 'Som dieťa',
  duplicateTask: 'Duplikovať',
  emptyName: 'Meno nemôže byť prázdne',
  filterFundsAdded: 'Dobitie',
  filterCharges: 'Výdavky',
  blockCardAlertHeader: 'Zablokovať kartu?',
  blockCardAlertDescription:
    'O odblokovanie svojej karty budeš musieť požiadať rodiča.',
  childCardBlockedMessage: 'O odblokovanie svojej karty požiadaj rodiča.',
  childCardNotBlockedMessage:
    'Kartu si môžeš zablokovať, ak ju stratíš, poškodíš alebo ti ju ukradnú. Pamätaj však, že odblokovať Ti ju môže len rodič.',
  countryName: `{countryCode, select,
    SK {Slovensko}
    CZ {Česko}
    BE {Belgicko}
    BG {Bulharsko}
    HR {Chorvátsko}
    CY {Cyprus}
    DK {Dánsko}
    EE {Estónsko}
    FI {Fínsko}
    FR {Francúzsko}
    GR {Grécko}
    NL {Holandsko}
    IE {Írsko}
    LT {Litva}
    LV {Lotyšsko}
    LU {Luxembursko}
    HU {Maďarsko}
    MT {Malta}
    DE {Nemecko}
    PL {Poľsko}
    PT {Portugalsko}
    AT {Rakúsko}
    RO {Rumunsko}
    SI {Slovinsko}
    ES {Španielsko}
    SE {Švédsko}
    IT {Taliansko}
    GB {Veľká Británia}
    UA {Ukrajina}
    other {}
  }`,
  otherCountry: 'Iné',
  createSavingLabel: 'Pridaj si vlastný cieľ a začni sporiť na čokoľvek',
  addSaving: 'Pridaj si sporenie',
  addToSaving: 'Sporiť',
  edit: 'Upraviť',
  savingHistory: 'História sporenia',
  editSavingTitle: 'Upraviť sporenie',
  editSavingName: 'Zmeniť názov',
  editSavingAvatar: 'Zmeniť obrázok',
  editAvatarDescription:
    'Vyber si z našich obrázkov, alebo si nahraj svoj vlastný. Je to len na Tebe. :)',
  removeSaving: 'Zrušiť sporenie a presunúť peniaze na kartu',
  accountOverviewWelcome: 'Ahoj, {name}, na karte máš',
  accountOverviewWelcomeEmptyName: 'Ahoj, na karte máš',
  savingLabel: 'Sporenie',
  savedLabel: 'Máš nasporené',
  together: 'Máš dokopy',
  total: 'Celkovo',
  overviewTabLabel: 'Prehľad',
  myChildren: 'Moje deti',
  addChild: 'Pridať ďalšie dieťa',
  cardDetail: 'Detail karty',
  transactionHistory: 'História transakcií',
  childName: 'Zadaj meno dieťaťa',
  childNameUsed: 'Meno dieťaťa, ktoré kartu používa',
  topUpCard: 'Dobiť kartu',
  noTransactionsLabel: 'Zatiaľ nemáš žiadne pohyby na svojej karte',
  nicknameLabel: 'Nastav si meno či prezývku',
  nicknameScreenTitle: 'Ako chceš, aby sme Ťa volali?',
  nicknameSetLater: 'Meno či prezývku si môžeš nastaviť aj neskôr v profile.',
  changeNickname: 'Zmeniť prezývku',
  parent: 'Rodič',
  iAmParent: 'Som rodič',
  deleteAndInviteAgain: 'Zmazať a pozvať na iné telefónne číslo',
  skip: 'Preskočiť',
  pickFromCameraRoll: 'Vybrať vlastné foto',
  launchCamera: 'Odfoť',
  avatarImagePickError: 'Nastala chyba pri spracovaní obrázka',
  missingPermissions: 'Chýbajúce nastavenia',
  missingCameraPermissionsError:
    'Na výber vlastného obrázka je potrebné povoliť prístup {type, select, cameraRoll {do galérie obrázkov} camera {k fotoaparátu}}. Prejsť do nastavení?',
  avatarImageFileSizeError:
    'Obrázok je príliš veľký. Skús použiť nejaký menší.',
  wrongPinCode: 'Nesprávny PIN kód',
  changeAgeScreenTitle: 'Koľko máš rokov?',
  ageLabel: 'Vek',
  changeAgeInfo:
    'Tvoj vek nám lepšie pomôže nastaviť obťažnosť v kvízovej hre.',
  changeAge: 'Nastaviť',
  setNicknameAndAgeLinkLabel: 'Nastav si prezývku a svoj vek',
  setNicknameLinkLabel: 'Nastav si meno či prezývku',
  setAgeLinkLabel: 'Nastav svoj vek',
  financialKnowledgeTotalQuizzesTextBefore: 'Celkovo si zvládol ',
  financialKnowledgeTotalQuizzesTextAfter:
    ' {count, plural, =1 {kvíz} =2 {kvízy} =3 {kvízy} =4 {kvízy} other {kvízov}}',
  financialKnowledgeTotalAnswersTextBefore: 'Mal/a si ',
  financialKnowledgeTotalAnswersTextAfter:
    ' {count, plural, =1 {správnu odpoveď} =2 {správne odpovede} =3 {správne odpovede} =4 {správne odpovede} other {správnych odpovedí}}',
  financialKnowledgeUnlockedThemesTextBefore: 'Odomkol si ',
  financialKnowledgeUnlockedThemesTextAfter:
    ' {count, plural, =1 {vlastnú tému} =2 {vlastné témy} =3 {vlastné témy} =4 {vlastné témy} other {vlastných tém}}',
  takeTest: 'Otestuj svoje znalosti',
  takeTestLevel: 'Spusti Level {level}',
  currentSmartiePointsTopText: 'Momentálne máš',
  currentSmartiePointsBottomText:
    '{count, select, 1 {SMARTIE bod} 2 {SMARTIE body} other {SMARTIE bodov}}',
  yourFinancialKnowledgeScore: 'Skóre Tvojej \nfinančnej gramotnosti',
  shareWithFriends: 'Zdieľaj s kamošmi',
  month: `{month, select,
    1 {Január}
    2 {Február}
    3 {Marec}
    4 {Apríl}
    5 {Máj}
    6 {Jún}
    7 {Júl}
    8 {August}
    9 {September}
    10 {Október}
    11 {November}
    12 {December}
  }`,
  selectQuizDifficultyTitle: 'Vyber si obtiažnosť',
  quizLevel: 'LEVEL {level}',
  quizDifficulty: '{level, select, 1 {Ľahká} 2 {Stredná} 3 {Ťažká}}',
  quizRewardsTitle: 'Čo môžem získať v LEVEL {level}?',
  quizRewardsInfo: `{level, select,
    2 {Každý checkpoint v LEVEL 2 Ti odomkne jedinečný vzor, ktorý môžeš použiť vo svojej téme. Na odblokovanie najprv úspešne prejdi LEVEL 1.}
    3 {Každý checkpoint v LEVEL 3 Ti odomkne jedinečnú ilustráciu, ktorú môžeš použiť vo svojej téme. Na odblokovanie najprv úspešne prejdi LEVEL 2.}}`,
  next: 'Ďalej',
  quizRewardsInfoResults: `{level, select,
      2 {Každý checkpoint v kvíze ti odomkne jedinečný vzor, ktorý môžeš použiť vo svojej téme.}
      3 {Každý checkpoint v kvíze ti odomkne jedinečnú ilustráciu, ktorú môžeš použiť vo svojej téme.}}`,
  quizTitle: '{level, select, 1 {Ľahká} 2 {Stredná} 3 {Ťažká}} {index}/5',
  nextQuestion: 'Ďalšia otázka',
  goToResults: 'Vyhodnotenie',
  answerCorrect: 'Správne. ',
  answerIncorrect: 'Nesprávne. ',
  pointsGainedTextBefore: 'Získal/a si ',
  pointsGainedTextMiddle:
    '{points, select, 1 {1 SMARTIE bod} 2 {2 SMARTIE body} other {3 SMARTIE body}}',
  pointsGainedTextAfter:
    '{correctAnswers, select, 1 { a prvý checkpoint je odomknutý. Hurá!} 3 { a druhý checkpoint je odomknutý. Len tak ďalej!} 5 { a tretí checkpoint odomknutý.\nSkvelá práca!} other { .}}',
  quizResultTitle:
    '{checkpoint, select, 0 {Nič sa nedeje} 1 {Super} 2 {Veľmi dobré} 3 {Level {level} máš úspešne za sebou! Gratulujeme.}}',
  quizResultHeaderText: `{checkpoint, select,
      0 {{level, select, 1 {Mrzí nás to, nepodarilo sa Ti odomknúť žiadnu farbu.} 2 {Neodomkol si žiaden vzor} 3 {Neodomkol si žiadnu ilustráciu}}}
      1 {{level, select, 1 {Podarilo sa Ti odomknúť 1 z 3 farieb} 2 {Podarilo sa Ti odomknúť 1 z 3 vzorov} 3 {Podarilo sa Ti odomknúť 1 z 3 ilustrácií}}}
      2 {{level, select, 1 {Podarilo sa Ti odomknúť 2 z 3 farieb} 2 {Podarilo sa Ti odomknúť 2 z 3 vzorov} 3 {Podarilo sa Ti odomknúť 2 z 3 ilustrácií}}}
      3 {{level, select, 1 {Podarilo sa Ti odomknúť 3 z 3 farieb} 2 {Podarilo sa Ti odomknúť 3 z 3 vzorov} 3 {Podarilo sa Ti odomknúť 3 z 3 ilustrácií}}}}`,
  quizResultBottomText: `{checkpoint, select, 3 {V ďalšom leveli budeš mať ešte lepšie možnosti, ako zveľadiť svoju apku.}
    0 {Nevadí, zopakuj si kvíz a mozňo nabudúce sa Ti bude dariť viac. Správne odpovede Ti odomykajú odmeny a získavajú SMARTIE body, ktorými môžes nakupovať ďalšie rozšírenia do apky.}
    other {Skús odpovedať na všetky otázky v kvíze správne a odomkni ďalšiu obťažnosť s ešte lepšími odmenami.}}`,
  quizResultCorrectQuestionsAndReward: `{checkpoint, select,
      1 {Odpovedal/a si správne na {correctAnswers, plural, =1 {1 otázku} other {2 otázky}}. Vyber si svoju odmenu.}
      2 {Odpovedal/a si správne na {correctAnswers} otázky. Teraz si môžeš vybrať z dvoch {level, select, 1 {farieb} 2 {vzorov} 3 {ilustrácií}}. Zvoľ {level, select, 1 {tú, ktorú} 2 {ten, ktorý} 3 {tú, ktorú}} chceš mať na pozadí.}
      3 {Vyber si svoju odmenu. Máš na výber {level, select, 1 {z troch farieb} 2 {tri vzory} 3 {z troch pekných ilustrácií}}. Zvoľ si {level, select, 1 {tú, ktorú} 2 {ten, ktorý} 3 {tú, ktorú}} chceš mať na pozadí.}
  }`,
  quizResultMotivationText: `{checkpoint, select,
      0 {Nič sa nedeje, zopakuj si kvíz a možno sa Ti nabudúce bude dariť viac. Správne odpovede Ti odomykajú odmeny a získavajú SMARTIE body, ktorými môžeš potom získať ďalšie parádne prvky do Tvojej apky.}
      1 {Skús správne odpovedať na všetky otázky kvízu a odomkni ďalšiu obťažnosť s ešte parádnejšími odmenami.}
      2 {Skús správne odpovedať na všetky otázky kvízu a odomkni ďalšiu obťažnosť s ešte parádnejšími odmenami.}
      3 {V ďalšom leveli získaš ďalšie možnosti, ako urobiť svoju apku ešte krajšou!}
  }`,
  chooseTheme: 'Vybrať',
  themeChosen: 'Vybraná',
  quizResultBottomLink: `{checkpoint, select,
      1 {Skús to znova a zopakuj si kvíz.}
      2 {Skús to znova a zopakuj si kvíz.}
      3 {Pokračovať na level {level}}
  }`,
  quizResultRewardNotChosenAlertTitle: 'Nevybral/a si si svoju odmenu',
  quizResultRewardNotChosenAlertMessage:
    'Naozaj chceš opustiť kvíz bez toho, aby si si vybral/a a nastavil/a jednu z tém, ktoré si vyhral/a?',
  quizResultRewardNotChosenAlertLeaveButton: 'Opustiť',
  quizResultRewardNotChosenAlertStayButton: 'Zostať',
  quizCompletedBottomText:
    'Zopakuj si kvíz a s každou správnou odpoveďou získavaj ďalšie SMARTIE body, s ktorými zaplatíš nové parádne prvky do Tvojej apky.',
  repeatQuiz: 'Zopakuj si kvíz',
  share: 'Zdieľaj s kamošmi',
  themeStore: 'Obchod',
  themeStoreAvatar: 'Obrázok',
  themeStoreFrame: 'Rámček',
  themeStoreTheme: 'Téma',
  youHave: 'Máš',
  smartiePointsShortcut: ' {points} bodov',
  purchaseFeature: 'Kúp za {price} bodov',
  seasonalThemesInfo: 'Pozor, sezónne témy môžeš kúpiť len obmedzený čas.',
  themeStoreAvatarTopText:
    'Dokúp si za SMARTIE body niektorý z našich obrázkov a urob si Tvoj profil alebo sporiaci cieľ jedinečným. :)',
  themeStoreFrameTopText:
    'Dokúp si za SMARTIE body niektorý z našich rámčekov a urob si Tvoj profil originálnym :)',
  categoryDescriptionProfileAvatar: 'Obrázok profilu',
  categoryDescriptionSavingsAvatar: 'Obrázok sporenia',
  categoryDescriptionFrame: 'Rámček avatara',
  categoryDescriptionTheme: 'Téma',
  purchaseProfileAvatarInfo:
    'Obrázok Ti automaticky po zakúpení pribudne v Tvojej galérii, nastaviť si ho môžeš priamo vo svojom profile.',
  purchaseSavingsAvatarInfo:
    'Obrázok Ti automaticky po zakúpení pribudne v Tvojej galérii, nastaviť si ho môžeš priamo v detaile sporenia.',
  purchaseFrameInfo:
    'Rámček Ti automaticky po zakúpení pribudne v Tvojej galérii, nastaviť si ho môžeš priamo vo svojom profile.',
  purchaseSeasonalThemeInfo:
    'Sezónna téma Ti automaticky pribudne v Tvojej galérií, nastaviť si ju môžeš priamo vo svojom profile.',
  featureAvailabilityInfo: 'Túto tému môžeš zakúpiť ',
  featureAvailabilityDate: 'do {date}.',
  notEnoughPointsTitle: 'Nemáš dosť SMARTIE bodov. :(',
  notEnoughPointsText:
    'Na kúpu odmeny teraz nemáš dostatočný kredit. Sprav si kvíz a získaj SMARTIE body za každú správnu odpoveď. Držíme palce!',
  colorNotUnlockedTitle: `Táto farba zatiaľ nie je odomknutá.`,
  colorNotUnlockedText: `Sprav si kvíz a získaj novú farbu za každý prejdený Level 1.`,
  quiz: 'Kvíz',
  leave: 'Opustiť',
  chooseAppTheme: 'Vyber si tému svojej apky',
  set: 'Nastaviť',
  currentlySet: 'Máš nastavené',
  changeAvatarImageTabHeading: 'Zmeniť obrázok',
  changeAvatarFrameTabHeading: 'Rámček avatara',
  changeAvatarFrameTabText:
    'Vyber si rámček, ktorý sa Ti najviac páči. Ďalšie prvky si vieš „dokúpiť" v našom obchode. :)',
  defaultAvatarFrameTileText: 'Smartie',
  featureStoreLinkText: 'Kúp si dalšie rámčeky',
  noPurchasedThemeInfo: 'Aktuálne nemáš kúpenú žiadnu tému.',
  noThemeYetInfoMessage: 'Teraz tu nič nie je. Skús sa sem pozrieť neskôr :)',
  noCardTapHere: 'Ak ešte nemáš kartu Smartie, ťukni SEM',
  requestSmartieCard: 'Požiadať o kartu SMARTIE'
}

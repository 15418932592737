import {QUESTIONS_PER_KNOWLEDGE_POINT} from '../constants'

export const getNumber = value => {
  const formattedValue = value.replace(',', '.')
  if (isNaN(Number(formattedValue)))
    return {isValid: false, isEmpty: false, number: null}
  return {
    isValid: true,
    isEmpty: formattedValue.trim() === '',
    number: Number(formattedValue)
  }
}

export const isWeb = () => window.platform === 'web'

export const calculateKnowledgeScore = answerCount =>
  Math.floor(answerCount / QUESTIONS_PER_KNOWLEDGE_POINT)

import React from 'react'
import styled, {css} from 'styled-components'

const StyledText = styled.span`
  font-family: 'Sofia Pro', sans-serif;
  color: ${({
    color,
    theme: {
      text: {colors}
    }
  }) => colors[color] || colors.default};
  font-size: ${props => (props.small ? '14px' : '16px')};
  margin-top: 0;
  text-align: ${props => (props.center ? 'center' : 'left')};
  font-weight: ${props =>
    props.bold ? '800' : props.medium ? '500' : 'normal'};
`

const StyledTextSecondary = StyledText.extend`
  color: #808287;

  ${props =>
    props.uppercase &&
    css`
      font-size: 12px;
      text-transform: uppercase;
    `};
`

StyledTextSecondary.defaultProps = {
  small: true
}

const StyledTextLarge = StyledText.extend`
  font-size: 1.25rem;
  font-weight: 500;
`

const StyledTextTitle = StyledText.extend`
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  font-weight: normal;
  margin-bottom: 48px;
`.withComponent('h1')

const StyledTextHeading = StyledTextLarge.extend`
  margin: 0;
`.withComponent('h2')

const StyledTextSubheading = StyledText.extend`
  font-weight: 500;
  margin-bottom: 0.5em;
`.withComponent('h3')

const StyledTextLead = StyledText.extend`
  margin-bottom: 1.5em;
`.withComponent('p')

const Text = ({type, ...restProps}) => {
  if (type === 'secondary') {
    return <StyledTextSecondary {...restProps} />
  } else if (type === 'heading') {
    return <StyledTextHeading {...restProps} />
  } else if (type === 'subheading') {
    return <StyledTextSubheading {...restProps} />
  } else if (type === 'large') {
    return <StyledTextLarge {...restProps} />
  } else if (type === 'title') {
    return <StyledTextTitle {...restProps} />
  } else if (type === 'lead') {
    return <StyledTextLead {...restProps} />
  }

  return <StyledText {...restProps} />
}

export default Text

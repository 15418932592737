import {connect} from 'react-redux'
import {compose, hoistStatics} from 'recompose'

import {SET_VALUE} from '../actions/commonActions'

const dismissGeneralError = () => SET_VALUE(['visibleGeneralError'], null)

const container = compose(
  connect(state => ({visibleGeneralError: state.visibleGeneralError}), {
    dismissGeneralError
  })
)

export default hoistStatics(container)

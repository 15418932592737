import {SHA3} from 'sha3'
import {isWeb} from './utils'
import storage from './storage'
import {getEnv} from './env'

export const hashAuthData = (
  authData,
  fixedPart = getEnv().PASSWORD_SALT || 'SMARTIE_PASSWORD_SALT'
) => {
  const hash = new SHA3(512)
  hash.update(authData)
  hash.update(fixedPart) // Adding fixed information about this user
  return hash.digest('hex')
}

export const saveCredentials = async (deviceToken, username) => {
  // Note: on web the device_token is stored in httpOnly cookie.
  // The access_token for web will gained during the next http request
  // using /access-token endpoint
  if (!isWeb()) {
    await storage.setItem('deviceToken', deviceToken)
  }
  await storage.setItem('username', username)
}

import {connect} from 'react-redux'
import {hoistStatics} from 'recompose'

import {userSelector} from '../selectors'

const container = connect(state => ({
  user: userSelector(state)
}))

export default hoistStatics(container)

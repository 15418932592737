import {compose, hoistStatics} from 'recompose'
import {connect} from 'react-redux'
import {clientSelector, usernameSelector} from '../../selectors'
import {logout} from '../../actions/auth/logout'

const container = compose(
  connect(
    state => ({
      client: clientSelector(state),
      username: usernameSelector(state)
    }),
    {logout}
  )
)

export default hoistStatics(container)
